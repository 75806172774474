import styled from '@emotion/styled'
import { onlyTable } from './util';
import {BorderBox} from "../BorderBox";
import {media} from "../../frame";

export const MenuPanel = styled.div`
    display: grid;
    grid-template-rows: 1fr max-content;
    width: 290px;
    height: 100%;

    margin: 0 auto;
    padding: 0 20px;
    padding-bottom: 20px;

    & .MuiList-root {
        height: 100%;
        overflow-y: auto;
    }

    & .MuiListItemIcon-root {
        min-width: 35px !important;
    }

    & .MuiListItemText-root {
        margin-bottom: 0; 
    }

    & .MuiListItem-root {
        height: 55px;
        padding-left: 20px;
    }

    ${media.laptop} {
        width: 240px;
    } 

    ${onlyTable} {
        width: 100px;

        & .MuiListItem-gutters {
            padding: 8px 14px;
        }

        & .MuiListItemIcon-root {
            min-width: 18px !important;
            width: 18px;
        }

        & .MuiListItemText-root {
            display: none;
        }

        & .MuiListItem-root {
            justify-content: center;
        }
    }

    ${media.phone} {
        width: 100%;

        & .MuiDrawer-paper {
            width: 100%;
        }
    }
`;

export const MenuGridLogoAndListAndBottomLinks = styled.div`
    height: 100%;
    display: grid;
    overflow-y: auto;

    grid-template-rows: max-content 1fr max-content;
`;

export const MenuList = styled(BorderBox)`
    height: 100%;
    overflow-y: auto;
    padding-right: 5px;

    ${onlyTable} {
        padding-right: 0;
    }
`;
MenuList.defaultProps = { vertic: true };

export const BottomAvatar = styled.div`
    display: none;
    width: 100%;

    ${media.phone} {
        display: block;
    }
`;

export const BottomLinks = styled.div`
    height: 100px;

    padding-bottom: 10px;

    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;

    display: none;

    ${media.tablet} {
        height: 100px;

        padding-bottom: 10px;

        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        align-items: center;
    }

    ${media.phone} {
        width: 100%;
        height: max-content;
        padding: 20px;

        flex-flow: row nowrap;
        justify-content: space-between;
        
        border-top: 1px solid rgba(155, 155, 155, 0.4);
    }
`;
