import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const theme = createTheme({
    typography: {
        fontFamily: ["Helvetica, Arial"].join(',')
    },
    palette: {
        primary: {
          main: "#6184AD",
        },
        secondary: {
          main: "#f50057",
        },
    },

    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
