import { useCrudItem } from '../../toolympus/api/useSimpleCrud';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { LectureFieldProps } from './SettingsPage';

export interface WASettings {
  is_applications_enabled: boolean | string;
  is_certificates_available: boolean;

  lecture_room_platform?: string;
  external_lecture_room_common_url?: string;
  external_lecture_room_private_url?: string;
}

export const LectureRoomPlatformOptions = {
  external: "external",
  jitsi: "jitsi",
};

export const useSettingsEdit = () => {
    const settings = useCrudItem<WASettings>("/api/settings", {
        defaultValue: { is_applications_enabled: false, is_certificates_available: false },
    });

    const lectureRoom = useCrudItem<LectureFieldProps | LectureFieldProps & { token: string }>("/api/lectureroom", {
        defaultValue: { token_secret: "", available: false, domain: "", room: "" }
    });

    return {
        lectureRoom,
        settings,
        isLoading: settings.isLoading || lectureRoom.isLoading,
        isShowSaveButton: lectureRoom.hasChanges || settings.hasChanges
    }
}

export const useSettings = () => {
  const settings = useLoadedData<WASettings>("/api/settings", { is_applications_enabled: false, is_certificates_available: false });

  return {
    ...settings,
    lectureRoomOptions: LectureRoomPlatformOptions,
  }
}
