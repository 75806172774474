import React, { ReactNode } from 'react';
import { RegistrationsListPage } from '../../../toolympus/components/Contests/Registrations';
import { PseudoLink2 } from '../../../toolympus/components/primitives';
import { useParticipants } from '../../Participants/usePaticipants';
import { ApplicationReviewType, ApplicationTableIcon, downloadDocument } from '../Common/FormComponents';
import { ApplicationReview } from './ApplicationReview';
import { Application, ApplicationsList } from './useApplicationsList';

type ApplicationsForm = {
    data: ApplicationsList, 
    type: ApplicationReviewType, 
    applicaitonPanel?: ReactNode
}

export const ApplicationsListForm = ({ data, applicaitonPanel, type }: ApplicationsForm) => {
    const users = useParticipants("advisory_commitee");
    return <>
    <RegistrationsListPage
        data={data}
        formPaperProps={{ elevation: 0, style: { padding: 0 } }}
        headerActionsProps={{ alignItems: "end", firstItemNoMargin: true }}
        fields={{
            defaultFields: [
                "email",
                "lastname",
                "firstname",
                "cv_document_id",
                "ml_document_id",
                "approval_rac",
                "approval_final",
                "approval_committee",
            ],
            schema: data.schema,
            storageKey: "_winteracademy_applications_fields",
            extraSettings: {
                created_datetime: { utcToLocal: true },
            }
        }}
        fieldElement={(field) => {
            switch (field) {
                case "lastname":
                case "firstname":
                    return (row,schema,orig) => <PseudoLink2 onClick={() => data.review.open(row._id)}>{orig}</PseudoLink2>
                case "approval_rac":
                    return ((row: Application) => <ApplicationTableIcon applicationTable={{ slug: row.approval_rac?.approval_status || "" }} />);
                case "approval_final":
                    return ((row: Application) => <ApplicationTableIcon applicationTable={{ slug: row.approval_final }} />);
                case "approval_committee":
                    return ((row: Application) => <ApplicationTableIcon applicationTable={{ slug: "approval_committee", approval_committee: row.approval_committee, id: row._id, users, }} />);
                case "ml_document_id":
                    return ((row: Application) => <ApplicationTableIcon applicationTable={{ slug: "document_type", document_id: row.ml_document_id, downloadDocument }} />)
                case "cv_document_id":
                    return ((row: Application) => <ApplicationTableIcon applicationTable={{ slug: "document_type", document_id: row.cv_document_id, downloadDocument }} />)
            }
        }}
        extraHeaderActions={applicaitonPanel}
        title=""
        review={data.review} />

        {data.review.isOpen && <ApplicationReview data={data} type = {type} />}
    </>
}