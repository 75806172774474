import { useEffect } from "react";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { useListSelection } from "../../toolympus/hooks/useListSelection";
import { ProfileData } from "../MyProfile/types"
import { apiFetch } from "../../toolympus/api/core";
import { useMassAction } from "../../toolympus/components/Actions";

interface DataIn {
  tutors: ProfileData[];
  test_email?: string;
  template?: string;
}

export const useInviteParticipants = () => {
  const data = useEditItem2<DataIn>({ dontResetOnSave: true });
  const tutorsSelection = useListSelection<ProfileData>(p => p._id, {
    items: data.item?.tutors,
  });

  useEffect(() => {
    tutorsSelection.selectAll(!!data.item?.tutors?.length);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.item?.tutors]);

  const testItem = useEditItem2<DataIn>({
    save: (item) => {
      return apiFetch<{}>(`/api/manage/participant/${item.tutors[0]._id}/invite?template=${item.template}&test-to=${item.test_email}`, "post")
        .then(x => item);
    },
  });

  const testX = {
    canStart: !!tutorsSelection.selectedItems.length && !!data.item?.template,
    start: () => testItem.setItem({ ...(data.item || {}), tutors: tutorsSelection.selectedItems.slice(0,1) }),
  };

  const invitesSendAction = useMassAction<ProfileData>();
  
  const startSendInvites = () => {
    if(tutorsSelection.selectedItems.length && data.item?.template) {
      const template = data.item?.template;
      invitesSendAction.prepareAction({
        items: tutorsSelection.selectedItems,
        label: "Send",
        action: (item) => {
          return apiFetch<{}>(`/api/manage/participant/${item._id}/invite?template=${template}`, "post")
            .then(x => item);
        }
      })
    }
  }

  useEffect(() => {
    if(!data.item) {
      testItem.cancel();
      invitesSendAction.cancel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.item]);

  return {
    ...data,
    start: (tutors: ProfileData[]) => {
      data.startEditing({ tutors })
    },
    selection: tutorsSelection,
    test: {
      ...testItem,
      ...testX,
    },
    send: {
      ...invitesSendAction,
      start: startSendInvites,
      canStart: !!tutorsSelection.selectedItems.length && !!data.item?.template,
    }
  }
}


export type InviteTutorsData = ReturnType<typeof useInviteParticipants>;
