import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useLoadedData } from '../../../hooks/useLoadedData';
import { apiFetch } from '../../../api/core';

type SettingsT = Record<string, any>;

interface ICMSSettingsContext {
  ensureLoaded: () => void;
  reload: () => void;
  settings: SettingsT;
  updateSettings: (c: Partial<SettingsT>) => void;
}

const CMSSettingsContext = React.createContext<ICMSSettingsContext>({
  ensureLoaded: () => {},
  reload: () => {},
  settings: {},
  updateSettings: () => {},
});

export const useCMSSettingsContext = () => useContext(CMSSettingsContext);


interface ProviderProps {
  apiPath: string;
}

const useCMSSettingsContextData = (props: ProviderProps) => {
  const [doLoad, setDoLoad] = useState<boolean>(false);
  const data = useLoadedData<SettingsT>(`${props.apiPath}/manage/settings`, {}, doLoad && !!props.apiPath);

  const ctx: ICMSSettingsContext = useMemo(() => {
    return {
      ensureLoaded: () => setDoLoad(true),
      reload: () => data.reload(),
      settings: data.data,
      updateSettings: (c) => apiFetch<SettingsT>(`${props.apiPath}/manage/settings`, "put", c)
        .then(x => data.setData(x)),
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data]);

  return ctx;
}

export const CMSSettingsContextProvider = (props: PropsWithChildren<ProviderProps>) => {
  const data = useCMSSettingsContextData(props);
  return (
    <CMSSettingsContext.Provider value={data}>
      {props.children}
    </CMSSettingsContext.Provider>
  );
}
