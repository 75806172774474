import React from "react";
import { useUser } from '../../../toolympus/userContext/UserContext';
import { useApplicationsListCommittee } from '../Form/useApplicationsList';
import { ApplicationsListForm } from '../Form/ApplicationsListForm';
import { Button } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "../../../toolympus/components/primitives";

export const CommitteeList = () => {
    const data = useApplicationsListCommittee();
    const user = useUser();
    
    const applicaitonPanel = <>
      {data.hasPreliminaryApprovals &&
        <Tooltip text="Make sure to finalise your preliminary review to make the results visible to other committee members and the organiser. You can finalise one-by-one or all at one go.">
          <InfoOutlined color="secondary" />
        </Tooltip>}
      {data.finalizeAllApprovals?.canRun && <Button size="small" color="primary" variant="contained" onClick={() => data.finalizeAllApprovals && data.finalizeAllApprovals.run()}>Finalise preliminary approvals</Button> }
    </>;
    
    return <>
        { user.user?.roles.includes("committee") && <ApplicationsListForm data={data} type={"committee"} applicaitonPanel={applicaitonPanel} /> }
    </>
}