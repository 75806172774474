import { LoadedData, useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Person } from "./types";
import { useState } from "react";

type ParticipantsView = "all" | "organizers" | "tutors" | "advisory_commitee" | "students";

export interface Participants extends LoadedData<Person[]> {
    filter: ParticipantsView; 
    setFilter: (v: ParticipantsView) => void;
}


export const useParticipants = (filterDefault: ParticipantsView = "all") => {
  const [filter, setFilter] = useState<ParticipantsView>(filterDefault);
  const data = useLoadedData<Person[]>(`/api/participant?view=${filter}`, []);
  

  return {
    ...data,
    filter,
    setFilter,
  }
}

export type ParticipantsData = ReturnType<typeof useParticipants>;
