import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { BaseSelection } from 'slate';

export interface CurrentElement {
  path?: BaseSelection;
}

export interface FocusChangeRequest {
  path?: BaseSelection;
  focus?: boolean;
  highlight?: boolean;
  elementId?: string;
}

export interface IPowerEditorControlContext {
  focusChangeRequest: FocusChangeRequest | null;
  acknowledgeFocusChange: () => void;
  
  currentElement: CurrentElement | null,
  setCurrentElement: (e: CurrentElement) => void;
  requestFocusChange: (r: FocusChangeRequest) => void;
}

export const PowerEditorControlContext = React.createContext<IPowerEditorControlContext>({
  focusChangeRequest: null,
  acknowledgeFocusChange: () => {},
  currentElement: null,
  setCurrentElement: () => {},
  requestFocusChange: () => {},
});

export const usePowerEditorControlContext = () => useContext(PowerEditorControlContext);


export const PowerEditorExternalControlProviderBase = (props: PropsWithChildren<{}>) => {
  const [focusReq, setFocusReq] = useState<FocusChangeRequest | null>(null);
  const [currentElement, setCurrentElement] = useState<FocusChangeRequest | null>(null);

  const ctx: IPowerEditorControlContext = useMemo(() => {
    return {
      focusChangeRequest: focusReq,
      acknowledgeFocusChange: () => setFocusReq(null),
      currentElement,
      setCurrentElement,
      requestFocusChange: (r) => {
        setFocusReq(r);
      }
    }
  }, [focusReq, currentElement]);

  return (
    <PowerEditorControlContext.Provider value={ctx}>
      {props.children}
    </PowerEditorControlContext.Provider>
  );
}
