import { usePage } from "../components/Pages"

export interface Partner {
    title: string;
    url: string;
    img_url: string;
    width: number;
}

export interface PartnerWithGroup extends Partner {
    group_title: string;
}

export interface PartnerGroup {
    title: string;
    partners: Partner[];
}

export interface PartnersData {
    groups: PartnerGroup[];
    flat: PartnerWithGroup[];
}

export const usePagePartners = (pagesApiPath: string, pageSlug: string) => {
    const { page } = usePage(pagesApiPath, pageSlug);

    const components = page?.components || [];
    const groups: PartnerGroup[] = components.map(c => {
        const lines = c.content_text.split("\n");
        return {
            title: (lines[0] || "").trim(),
            partners: lines.slice(1).map(l => {
                const [title, url, img_url, width] = l.split(";").map(s => s.trim());
                return {
                    title,
                    url,
                    img_url,
                    width: width ? parseInt(width) : 1,
                }
            })
        }
    });

    return {
        groups,
        flat: groups.reduce((r,g) => [ ...r, ...g.partners.map(p => ({ ...p, group_title: g.title }))], [] as PartnerWithGroup[]),
    }
}
