import moment from "moment";
import { Course, Schedule, Tutor } from "./types";

export const emptyCourse = (): Course => ({
    description: "",
    _id: "",
    group_chat_id: "",
    tutors: [],
    title: "",
    kind: "",
    tutor_id: "",
    tutors_ids: [],
    shorttitle: ""
});

export const emptyTutor = (): Tutor => ({
    _id: "",
    email: "",
    consent_share_profile: false,
    is_tutor: false,
    lastname: "",
    consent_email_show: false,
    title: "",
    firstname: "",
    bio: "",
    interests: "",
    position: "",
    is_organizer: false,
    linkedin_url: "",
    company: "",
    consent_email_org: false
});

export const emptySchedule = (course_id: string): Schedule => ({
    end_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    title: "",
    course_id,
    start_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
}) as Schedule;
