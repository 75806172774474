import { useHistory } from "react-router-dom";
import { emptyTutor } from "../CourseManagement/emptyObjects";
import { Tutor } from "../CourseManagement/types";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";

export const useTutorEdit = (id: string) => {
    const history = useHistory();

    const data = useCrudItem<Tutor>(`/api/admin/tutor/${id}`, {
      defaultValue: emptyTutor(),
      returnPath: "/tutors",
    });

    const isNew = false;

    return {
      ...data,
        remove: () => {
          return data.remove()
            .then(x => { history.push('/tutors'); return x; });
        },
        isNew,
    }
}
