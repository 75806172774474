import {
    AddresseeList,
    Addressee,
    AddresseeName,
    AvatarImg,
    ChatContainer,
    Header,
    LinkBack,
    MessagesList,
    PageContainer,
    MessageText,
    Secondary,
    ChatTitle,
    ChatAvatar,
    DayContainer,
    DaySeparate,
    ChatList,
    MsgChatContainer,
    ChatAddresseeAvatar,
    ChatAddresseeName,
    MsgData,
    MsgInputContainer, MsgInput, ActionIcon, SendButton, BottomChatContainer, MsgTextarea, FileStatus, RoleIcon, ParticipantsCountWrapper, PinButton, PinnedMessageContainer, UnpinButton, MessageTimeWrapper,
    ReplyButton,
    ReplyingTo,
    MessageReplyTo
} from "./MessagesPage.style";
import React, { useEffect, useRef, useState } from "react";
import {Dialog, DialogActions, DialogTitle, Link, Tab, Tabs, TextField, Tooltip} from "@mui/material";
import {Search} from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AttachFileSrc from './attach.png';
import SendSrc from './Send.svg';
import ChatLogoSrc from './chatlogo.svg';
import OrganizerSrc from './Organizer.svg';
import PinSrc from './Pin.svg';
import ReplySrc from './reply.svg';
import TutorSrc from './Tutor.svg';
import TutorCourseSrc from './Tutor course.svg';
import { ChatData, ChatItem, Filter, Message } from "../types";
import { LoadingIndicator } from "../../../toolympus/components/primitives/LoadingIndicator";
import moment from "moment";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { useParams } from "react-router-dom";
import { apiUploadFile, downloadFile, FetchTypes } from "../../../toolympus/api/core";
import { useSnackbar } from "notistack";
import { Button } from "../../common/Button";
import { NewChatPopap } from "./NewChatPopap";
import { AddParticipantPopap } from "./AddParticipantPopap";
import { participantName } from "../../Participants/presentation/display";

const sliceStr = (num: number, str: string) =>
    str.length > num? str.slice(0, num - 3) + '...' : str;

const format = (time: moment.MomentInput) => 
    moment(time).format('hh:mm A');


export interface ChatPropsData {
    data: ChatData | null;
    loading: boolean;
    send: (test: string) => void;
    add: (_id: string) => void;
    remove: (_id: string) => void;
    pin: (messageId: string | null) => void;
    reply: {
      replyingToMessage: Message | null | undefined;
      startReplying: (m: Message) => void;
      cancelReplying: () => void;
    };
    addLoading: boolean;
    leave: () => Promise<void>;
}

interface ChatComponentProps {
    chat: ChatPropsData;
    reload: () => void;
}

const calendarOptions = {
    lastDay : '[Yesterday]',
    sameDay : '[Today]',
    nextDay : '[Tomorrow]',
    lastWeek : '[last] dddd',
    nextWeek : 'dddd',
    sameElse : 'L'
}

const dayFormat = (time: moment.MomentInput) => 
    moment(time).calendar(null, calendarOptions) + ', ' + moment(time).format('dddd Do YYYY');


// message with a file link format:
// [file:Winter_Academy_2023.pdf](/api/userdocuments/01841827-f73c-4f8d-9a94-b6243c1a3d80) Here's a file for you
const textWithLink = (text: string, textHundler?: (t: string) => string) =>{
    const [fileLinkText,filename,url] = text.match(/\[file:([^\]]+)]\(([^)]+)\)/) || [];

    const onlytext = fileLinkText ? text.replace(fileLinkText, "").replace("[)", " ") : text;

    return <>
        {filename && url && <Link style={{ cursor: "pointer" }} onClick={() => downloadFile(url, filename)}>{filename} </Link>}
        {textHundler? textHundler(onlytext) : onlytext}
    </>
}

const messagePart = (message?: string) => message && message.length > 140 ? `${message.substring(0,141)}...` : (message || "");

const MessageInputId = "message-input";

const ChatComponent = ({ chat, reload }: ChatComponentProps) => {
    const {loading, data, send, add, remove, pin, reply, addLoading, leave} = chat;

    const [text, setText] = useState("");
    const user = useUser();
    const chatListRef = useRef<HTMLElement>(null);
    const {enqueueSnackbar} = useSnackbar();
    const [loadingFile, setLoadingFile] = useState(false);
    const [file, setFile] = useState<{_id: string, filename: string} | null>(null);
    const [openAddPopup, setOpenAddPopup] = useState(false);
    const [openLeavePopup, setOpenLeavePopup] = useState(false);

    const sendMessage = () => {
        const message = (file? `[file:${file.filename}](/api/userdocuments/${file._id})` : '') + text;

        if (message.trim().length === 0) {
            enqueueSnackbar("Please type message or attach file", {variant: 'warning', autoHideDuration: 3000});
            return;
        }

        send(message);
        setText("");
        setFile(null);
    }

    useEffect(() => {
        const eventHandler = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === 'Enter') {
                sendMessage();
            }
        } 

        document.addEventListener('keydown', eventHandler);

        return () => document.removeEventListener('keydown', eventHandler);
    })

    if (loading && !data) {
        return  <LoadingIndicator />
    }

    if (!data) {
        return <ChatTitle>Please select a chat</ChatTitle>
    }

    const scrollDown = () => {
        const { current } = chatListRef;
        
        if (current) {
            current.scrollTo(0, current.scrollHeight);
        }
    }

    const participant = (_id: string) => {
        return data.participants.filter(data => data._id === _id)[0];
    }

    const interlocutor = data.participants.filter(({_id}) => _id !== (user.user as any)._id)[0];

    const isAvatarPresent = data.kind === 'private' && interlocutor;
    const AvatarSrc = isAvatarPresent ? '/api/avatar/' + interlocutor._id : ChatLogoSrc;

    const uploadFile = (file: File) => {
        setLoadingFile(true);
        apiUploadFile('/api/userdocuments', FetchTypes.POST, 'file', file)
            .then((e: {data: {_id: string, filename: string}}) => setFile(e.data))
            .catch(() => enqueueSnackbar("Failed to load file", { variant: 'error', autoHideDuration: 4000 }))
            .finally(() => setLoadingFile(false));
    }

    const AttachFile = ({mobile}: {mobile?: boolean}) => {
        return <ActionIcon 
                    mobile={mobile}
                    onClick={() => {
                        const input = document.createElement('input') as HTMLInputElement;

                        input.setAttribute('type', 'file');
                        input.onchange = () => uploadFile((input.files as FileList)[0]);
                        input.click();
                    }} 
                >
            <img src={AttachFileSrc} alt="attach"/>
        </ActionIcon>
    }

    const pinnedMessage = data.pinned_message_id && (data.messages || []).find(m => m._id === data.pinned_message_id);
    const pinnedMessageAuthor = pinnedMessage && participant(pinnedMessage.author_id);


    return <>
      <div>
        <ChatTitle>
            <ChatAvatar><img src={AvatarSrc} style={{ filter: isAvatarPresent ?  undefined : 'none' }} alt="chat avatar" /></ChatAvatar>
            <span>{data.title} {loading && <LoadingIndicator sizeVariant="s" style={{ marginLeft: "6px" }} />}</span>
            <Secondary>
                {data.kind !== "private" && <ParticipantsCountWrapper onClick={() => setOpenAddPopup(true)}>
                  {data.participants.length} Participants{data.kind === 'group' && ", "}{data.kind === 'group' && <Link>Add</Link>}
                </ParticipantsCountWrapper>}
                {openAddPopup && 
                    <AddParticipantPopap
                        title={data.kind !== "group" ? "Participants" : undefined}
                        canChange={data.kind === "group"}
                        data={{
                            current: data.participants, 
                            loading: addLoading,
                            onSelect: id => add(id),
                            onRemove: id => remove(id),
                            onClose: () => setOpenAddPopup(false),
                        }} 
                    />
                }
               {data.kind === 'group' && <div>
                    <Link onClick={() => setOpenLeavePopup(true)} color="error">Leave</Link>
                    {openLeavePopup && <Dialog fullWidth maxWidth="xs" onClose={() => setOpenLeavePopup(false)} open={openLeavePopup}>
                        <DialogTitle>Leave chat</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => {setOpenLeavePopup(false); leave().then(reload);}} color="secondary">Yes, leave</Button>
                            <Button onClick={() => setOpenLeavePopup(false)}>Cancel</Button>
                        </DialogActions>
                    </Dialog>}
                </div>}
            </Secondary>
            
        </ChatTitle>
        {pinnedMessage && (
          <PinnedMessageContainer lastCurrentAtDate={false}>
              <ChatAddresseeAvatar><img src={'/api/avatar/' + pinnedMessage.author_id} alt="avatar" /></ChatAddresseeAvatar>
              <MsgData>
                  <ChatAddresseeName>
                      {pinnedMessageAuthor && 
                          <span>
                              {participantName(pinnedMessageAuthor)}
                              {pinnedMessageAuthor.is_tutor 
                                  && 
                                  (data.chat_owner_id === pinnedMessageAuthor._id
                                    ? <RoleIcon title="Tutor of the course" src={TutorCourseSrc} alt="tutor course" /> 
                                    : <RoleIcon title="Tutor" src={TutorSrc} alt="tutor" />)
                              }
                              {pinnedMessageAuthor.is_organizer && <RoleIcon title="Organiser" src={OrganizerSrc} alt="org" />}
                          </span>}
                      <MessageTimeWrapper>
                        <Tooltip title="Unpin message">
                          <UnpinButton onClick={() => pin(null)}>
                            <img src={PinSrc} alt="" />
                          </UnpinButton>
                        </Tooltip>
                        <Secondary>
                          {format(pinnedMessage.time)}
                        </Secondary>
                      </MessageTimeWrapper>
                  </ChatAddresseeName>
                  <MessageText>
                      {textWithLink(pinnedMessage.text)}
                  </MessageText>
              </MsgData>
          </PinnedMessageContainer>
        )}
        </div>

        <ChatList onLoad={scrollDown} ref={chatListRef as any}>
            {data.messages.map((message, i, array) => {
                const { _id, author_id, time, text, reply_to_id } = message;
                const replyToMessage = reply_to_id ? data.messages.find(m => m._id === reply_to_id) : null;
                const prev = array[i-1];
                const next = array[i+1];

                const author = participant(author_id);
                const replyToAuthor = replyToMessage ? participant(replyToMessage.author_id) : null;

                return <React.Fragment key={_id}>
                    {(!prev || moment(prev.time).day() < moment(time).day()) && 
                        <DayContainer>
                            <DaySeparate />
                            <Secondary>{dayFormat(time)}</Secondary>
                            <DaySeparate />
                        </DayContainer>
                    }
                    <MsgChatContainer lastCurrentAtDate={!next || moment(next.time).day() > moment(time).day()} data-message-id={_id}>
                        <ChatAddresseeAvatar><img src={'/api/avatar/' + author_id} alt="avatar" /></ChatAddresseeAvatar>
                        <MsgData>
                            <ChatAddresseeName>
                                {author && 
                                    <span>
                                        {participantName(author)}
                                        {author.is_tutor 
                                            && 
                                            (data.chat_owner_id === author_id? 
                                                <RoleIcon title="Tutor of the course" src={TutorCourseSrc} alt="tutor course" /> 
                                                : 
                                                <RoleIcon title="Tutor" src={TutorSrc} alt="tutor" />)
                                        }
                                        {author.is_organizer && <RoleIcon title="Organiser" src={OrganizerSrc} alt="org" />}
                                    </span>}
                                <MessageTimeWrapper>
                                  <Tooltip title="Reply">
                                    <ReplyButton onClick={() => {
                                        reply.startReplying(message);
                                        const input = document.getElementById(MessageInputId);
                                        if(input) {
                                          setTimeout(() => input.focus(), 150);
                                        }
                                      }}>
                                      <img src={ReplySrc} alt="reply" />
                                    </ReplyButton>
                                  </Tooltip>
                                  <Tooltip title="Pin message">
                                    <PinButton onClick={() => pin(_id)}>
                                      <img src={PinSrc} alt="pin" />
                                    </PinButton>
                                  </Tooltip>
                                  <Secondary>{format(time)}</Secondary>
                                </MessageTimeWrapper>
                            </ChatAddresseeName>

                            {!!replyToMessage &&
                              <MessageReplyTo onClick={() => {
                                const original = document.querySelector(`[data-message-id="${replyToMessage._id}"]`);
                                if(original && chatListRef.current) {
                                  chatListRef.current.scrollTo({ top: (original as any).offsetTop, behavior: "smooth" })
                                }
                                console.log("ORG", original);
                              }}>
                                <img src={ReplySrc} alt="reply" className="reply-icon" />
                                {replyToAuthor ? `${participantName(replyToAuthor)}: ` : ""}{messagePart(replyToMessage.text)}
                              </MessageReplyTo>}

                            <MessageText>
                              {textWithLink(text)}
                            </MessageText>
                        </MsgData>
                    </MsgChatContainer>
                </React.Fragment>
            })}
        </ChatList>


        <BottomChatContainer>
            <AttachFile mobile />
            <MsgInputContainer onSubmit={e => {
                e.preventDefault();
                sendMessage();
            }}>
                <MsgInput value={text} autoFocus onChange={e => setText(e.target.value)} placeholder='Type a message here' />
                <MsgTextarea value={text} id={MessageInputId} autoFocus onChange={e => setText(e.target.value)} placeholder='Type a message here and press Ctrl+Enter to send...' />

                <AttachFile />

                <SendButton type="submit"><img src={SendSrc} alt="send" /></SendButton>
            </MsgInputContainer>
        </BottomChatContainer>
        {!!reply.replyingToMessage &&
          <ReplyingTo onClick={() => reply.cancelReplying()}>
            <img src={ReplySrc} alt="reply" className="reply-icon" /> <span className="original">{messagePart(reply.replyingToMessage.text)}</span>
          </ReplyingTo>
        }
        {(loadingFile || file) &&
          <FileStatus>
            {loadingFile && <><LoadingIndicator /> Uploading the file...</>}
            {!loadingFile && file && 
                <>
                    <span>File attached</span>
                    <Link onClick={() => downloadFile('/api/userdocuments/' + file._id, file.filename)}>
                        {file.filename}
                    </Link>
                </>}
          </FileStatus>}
    </>
}

interface Props {
    data: {
        filter: Filter;
        setFilter: (f: Filter) => void;

        loading: boolean;
        data: ChatItem[];

        idselectedChat: string | null;
        setIdSelectedChat: (s: string | null) => void;

        chat: ChatPropsData;

        create: (title: string) => void;
        reload: () => void;
    }
}

export const MessagesPage = ({data: {filter, setFilter, data, loading, chat, idselectedChat, setIdSelectedChat, create, reload}}: Props) => {
    const { id } = useParams<{id?: string}>();
    const [search, setSearch] = useState('');
    const [isSelected, setisSelected] = useState(!!id);
    const [newChatOpen, setNewChatOpen] = useState(false)

    if (loading) {
        return <LoadingIndicator />
    }

    return <PageContainer openChat={isSelected} >
        <Header openChat={isSelected}>
            <Tabs
                classes={{indicator: "indicator"}}
                value={filter}
                onChange={(_, filter) => setFilter(filter)}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab label='all chats' value='all'/>
                <Tab label='course chats' value='course'/>
                <Tab label='private chats' value='private'/>
                <Tab label='group chats' value='group'/>
            </Tabs>
            <Button onClick={() => setNewChatOpen(true)} color="primary">Create chat</Button>
            {newChatOpen && 
                <NewChatPopap 
                    onClose={() => setNewChatOpen(false)} 
                    onSave={title => {
                        setNewChatOpen(false);
                        create(title);
                    }} 
                />
            }
        </Header>
        <MessagesList>
            <div className="input">
                <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    label='Search'
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    InputProps={{
                        endAdornment: <Search color="disabled" />,
                    }}
                />
            </div>
            <AddresseeList>
                {data
                    .filter(({title}) => title.toLowerCase().includes(search.toLowerCase()))
                    .filter(({_id}) => !!_id)
                    .map(({_id, title, last_message: msg, avatar_id, has_unread}) => 
                        <Addressee key={_id} active={_id === idselectedChat || has_unread} onClick={() => {setIdSelectedChat(_id); setisSelected(true);}}>
                            <AvatarImg src={avatar_id? '/api/avatar/' + avatar_id : ChatLogoSrc} style={{ filter: avatar_id ? "grayscale(1)" : "none"}} />
                            {msg? 
                                <MsgData>
                                    <AddresseeName>
                                        {sliceStr(20, title)}
                                        <Secondary>{format(msg.time)}</Secondary>
                                    </AddresseeName>
                                    <MessageText>
                                        {textWithLink(msg.text, sliceStr.bind(null, 40))}
                                    </MessageText>
                                </MsgData>
                                :
                                <MsgData>
                                    <AddresseeName>
                                        {sliceStr(20, title)}
                                    </AddresseeName>
                                </MsgData>
                            }
                        </Addressee>
                )}
            </AddresseeList>
        </MessagesList>
        <ChatContainer>
            <LinkBack onClick={() => {setIdSelectedChat(null); setisSelected(false);}}><KeyboardBackspaceIcon fontSize="small"/> Back to chats</LinkBack>
            <ChatComponent reload={reload} chat={chat} />
        </ChatContainer>
    </PageContainer>
}


