import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import React, { useState, ReactNode } from 'react';
import styled from '@emotion/styled'
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { Button } from '../../common/Button';
import { participantName } from '../../Participants/presentation/display';
import { useParticipants } from '../../Participants/usePaticipants';
import { Participant } from '../types';
import { AddresseeName, Secondary } from './MessagesPage.style';
import { useUser } from '../../../toolympus/userContext/UserContext';

interface Props {
    data: {
        current: Participant[];
        onSelect: (id: string) => void;
        onRemove: (id: string) => void;
        onClose: () => void;
        loading: boolean;
    }

    canChange?: boolean;
    title?: ReactNode;
}

export const List = styled.div`
    height: 600px;
    max-height: 100%;
    overflow-y: auto;
`;

export const ListItem = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr max-content;
    gap: 20px;

    align-items: center;
    padding: 10px 10px 10px 0;
`;

export const Avatar = styled.img`
    height: 60px;
    width: 60px;
    object-fit: cover;
    object-position: center top;
    border-radius: 2px;
    filter: grayscale(1);
`;

export const AddParticipantPopap = (props: Props) => {
    const { onClose, onSelect, onRemove, current, loading } = props.data;
    const {data} = useParticipants();
    const [search, setSearch] = useState("");

    const { user } = useUser();

    return <Dialog fullWidth maxWidth="sm" onClose={onClose} open={true}>
        <DialogTitle>{props.title || "Please select a participant"} {loading && <LoadingIndicator sizeVariant="s" style={{ marginRight: "0.5rem" }} />}</DialogTitle>
        <DialogContent>
            {props.canChange && <TextField
                fullWidth
                size='small'
                variant='outlined'
                label='Search'
                value={search}
                onChange={e => setSearch(e.target.value)}
                InputProps={{
                    endAdornment: <Search color="disabled" />,
                }}
            />}
            <List>
                {data
                    .filter(p => props.canChange ? true : current.find((item) => item._id === p._id))
                    .filter(p => participantName(p).toLowerCase().includes(search.toLowerCase()))
                    .map(p => 
                        <ListItem key={p._id}>
                            <Avatar src={'/api/avatar/' + p._id} />
                            <AddresseeName>{participantName(p)}</AddresseeName>
                            {props.canChange && 
                              (current.find((item) => item._id === p._id)
                                ? (user?.roles || []).includes("organizer")
                                    ? <Button color="secondary" onClick={() => onRemove(p._id)} noFullWidth>Remove</Button>
                                    : <Secondary>Already added</Secondary>
                                : <Button color="primary" onClick={() => onSelect(p._id)} noFullWidth>Add</Button>)
                            }
                        </ListItem>
                )}
            </List>
        </DialogContent>
    </Dialog>
}
