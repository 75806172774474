import { Button, TextField } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog, FormGrid, ItemDialogState } from '../../../primitives';
import { ButtonBlockProperties } from './types';

interface Props extends ItemDialogState<ButtonBlockProperties> {
  updateButtonSettings: (updated: ButtonBlockProperties) => void;
}

export const EditButtonDialog = (props: Props) => {
  const { isOpen, item, update, close, updateButtonSettings } = props;

  const closeX = () => {
    if(item) {
        updateButtonSettings(item);
    }
    close();
  }

  return (
    <Dialog
      disablePortal
      isOpen={isOpen}
      close={closeX}
      dialogTitle={<FormattedMessage id="powerdoc.plugins.button.dialog_title" />}
      actions={<>
        <Button onClick={closeX}><FormattedMessage id="common.close" /></Button>
      </>}
      noFullscreen>
        
      <FormGrid
        columns="1fr"
        contentEditable={false}>

        <TextField
          value={item?.label || ""}
          onChange={e => item && update({ label: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.button.fields.label" />}
          />

        <TextField
          value={item?.action || ""}
          onChange={e => item && update({ action: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.button.fields.action" />}
          />
        
        <TextField
          value={item?.url || ""}
          onChange={e => item && update({ url: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.button.fields.url" />}
          />

        <TextField
          value={item?.html_display_mode || ""}
          onChange={e => item && update({ html_display_mode: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.button.fields.html_display_mode" />}
          />
        
        <TextField
          value={item?.width || ""}
          onChange={e => item && update({ width: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.images.fields.width" />}
          />

        <TextField
          value={item?.height || ""}
          onChange={e => item && update({ height: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.images.fields.height" />}
          />

        <TextField
          value={item?._class || ""}
          onChange={e => item && update({ _class: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.images.fields._class" />}
          />

        <TextField
          value={item?.background_color || ""}
          onChange={e => item && update({ background_color: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.button.fields.background_color" />}
          />

        <TextField
          value={item?.text_color || ""}
          onChange={e => item && update({ text_color: e.target.value })}
          label={<FormattedMessage id="powerdoc.plugins.button.fields.text_color" />}
          />
          
      </FormGrid>
    </Dialog>
  );
}
