import React from 'react';
import { NewTutorData } from './useNewTutor';
import { Dialog, FormGrid } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { TutorSchema } from './TutorSchema';
import { FieldType, mergeSchema } from '../../../toolympus/hooks/useSchema';
import { SearchOutlined } from '@mui/icons-material';

interface Props {
  data: NewTutorData;
}

export const NewTutorDialog = ({ data }: Props) => {
  const history = useHistory();

  const schema = mergeSchema(
    TutorSchema,
    {
      _id: { label: "User", type: FieldType.select, values: data.users.map(u => ({ value: u._id, label: u.email }))}
    }
  )

  return (
    <Dialog
      isOpen={data.isEditing}
      close={data.cancel}
      dialogTitle="New tutor"
      noFullscreen
      actions={<>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            data.save().then(t => history.push(`/tutor/${t._id}`))
          }}
          >
          <FormattedMessage id="common.create" />
        </Button>
      </>}>
      <FormGrid columns="1fr 1fr">
        <FormControlsForFields
          data={data.item}
          onChange={(o,c) => data.update(c)}
          errors={data.errors}
          schema={schema}
          fields={[
            data.isUseExistingUser
              ? ["_id"]
              : ["email", {
                  controlProps: {
                    endAdornment: <IconButton size="small" color="primary" onClick={() => data.setIsUseExistingUser(true)}><SearchOutlined /></IconButton>
                  }}],
            ["title"],
            ["firstname"],
            ["lastname"],
            ["company"],
            ["position"],
          ]}
          />
      </FormGrid>
    </Dialog>
  );
}
