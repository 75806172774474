import styled from '@emotion/styled';
import { Button, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from "react";
import { LoadingIndicator, useSaveable } from '../../toolympus/components/primitives';
import { FormGrid, FormTitle, SectionTitle } from '../../toolympus/components/primitives/Forms';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { FieldType, Schema } from '../../toolympus/hooks/useSchema';
import { media } from '../frame';
import { LectureRoomPlatformOptions, useSettingsEdit } from './useSettingsEdit';

export type LectureFieldProps = {
    token?: string,
    token_secret: string,
    available: boolean,
    domain: string,
    room: string
}

const Page = styled.div`
   margin-top: 2vw;
   width: 70%;

    ${media.phone} {
        width: 100%;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black;
`;


const SaveButton = styled(Button)`
    width: 140px;
    height: 36px;

    ${media.phone} {
        width: 30%;
    }
`;

const SettingsSchema: Schema = {
  is_applications_enabled: { label: "Applications are enabled", type: FieldType.bool },
  is_certificates_available: { label: "Certificates available", type: FieldType.bool },
  lecture_room_platform: {
    label: "Lecture room platform",
    values: [
      { value: LectureRoomPlatformOptions.jitsi, label: "Jitsi" },
      { value: LectureRoomPlatformOptions.external, label: "External" }
    ],
    type: FieldType.select,
  },
  external_lecture_room_common_url: { label: "External lecture room link" },
  external_lecture_room_private_url: { label: "External lecture room link (private)", hint: "available to tutors and organisers only" },
};

const JitsiConfigSchema: Schema = {
    token_secret: { label: "Jitsi token", type: FieldType.text },
    available: { label: "Lecture room available", type: FieldType.bool },
    room: { label: "Lecture room name", type: FieldType.text },
    domain: { label: "Jitsi domain", type: FieldType.text },
}

export const SettingsPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        isLoading,
        lectureRoom,
        settings,
        isShowSaveButton,
    } = useSettingsEdit();

    const save = () => {
      lectureRoom.save();
      settings.save();
      enqueueSnackbar("Saved", { variant: 'success', autoHideDuration: 3000 });
    }

    useSaveable({
      save,
      hasChanges: settings.hasChanges || lectureRoom.hasChanges,
    });

    return <Wrapper>
        <Title>
            <FormTitle>
                Settings 
            </FormTitle>
            {isLoading && <LoadingIndicator sizeVariant="m" />}

            {isShowSaveButton &&
              <SaveButton variant="contained" onClick={() => save()} style={{ backgroundColor: "#6184AD", color: "white" }}>
                Save
              </SaveButton>}
        </Title>

        <Page>
          <FormControlsForFields
            data={settings.data}
            fields={[
              ["is_applications_enabled"],
              ["is_certificates_available"],
            ]}
            schema={SettingsSchema}
            onChange={(_, changes) => settings.update(changes)}
            />


            <FormGrid columns="1fr">
              <Divider />
              <SectionTitle>Lecture room</SectionTitle>

              <FormControlsForFields
                data={settings.data}
                fields={[
                  ["lecture_room_platform"],
                  settings.data.lecture_room_platform === LectureRoomPlatformOptions.external ? ["external_lecture_room_common_url"] : null,
                  settings.data.lecture_room_platform === LectureRoomPlatformOptions.external ? ["external_lecture_room_private_url"] : null,
                ]}
                schema={SettingsSchema}
                onChange={(_, changes) => settings.update(changes)}
                />


              {!isLoading && settings.data.lecture_room_platform === LectureRoomPlatformOptions.jitsi &&
                <FormGrid columns='2fr 2fr' noMargin alignItems="center">
                  <FormControlsForFields
                      data={lectureRoom.data}
                      schema={JitsiConfigSchema}
                      fields={[
                          ["token_secret"],
                          ["available"],
                          ["domain"],
                          ["room"]
                      ]}
                      onChange={(_, changes) => {
                          lectureRoom.update(changes);
                      }} />
                </FormGrid>}
            </FormGrid>
            
        </Page>
    </Wrapper>
}