import React, { useMemo } from 'react';
import { FormGrid } from '../../primitives';
import { FieldDefinition, FormControlsForFields } from '../../schemed';
import { RecordEditData } from './useRecordEdit';
import { LinkedRecordSelectorMultiple } from './LinkedRecordSelector';
import { AttributeConfig } from '../useRecordTypeConfig';
import { TagSelector } from './TagSelector';

interface Props {
    data: RecordEditData;
}

const RightColumnFieldTypes = ["mediafile", "json", "textlong", "text-long", "markdown"];
const FancyFieldTypes = ["tag", "tag_multiple", "record_link", "record_link_multiple"];

export const RecordAttributesForm = (props: Props) => {
    const { data, updateAttributes } = props.data;
    const { data: config, attributesSchema, } = props.data.config;

    const { fat, normal, fancyConfigs } = useMemo(
        () => (config.attributes || []).reduce<{ normal: FieldDefinition[], fat: FieldDefinition[], fancyConfigs: AttributeConfig[] }>(
            (r,a) => {
                if(RightColumnFieldTypes.includes(a.fieldtype || "")) {
                    r.fat.push([a.code]);
                } else if(FancyFieldTypes.includes(a.fieldtype || "")) {
                    r.fancyConfigs.push(a);
                } else {
                    r.normal.push([a.code]);
                }
                return r;
            }, { normal: [], fat: [], fancyConfigs: [] }),
            [config.attributes]
        );

        const normals = normal.length ? 1 : 0;
        const fancies = fancyConfigs.length ? 1 : 0;
        const fats = fat.length ? 1 : 0;

        const variants = normals + fancies + fats;
    
        return (
          <FormGrid columns={variants === 2 ? (normals && normal.length > 1 ? "2fr 1fr" : "1fr 1fr") : `repeat(${variants}, 1fr)`} forceEvenColumns>
            {!!normals &&
              <FormGrid columns={variants === 1 ? "1fr 1fr 1fr" : variants === 2 && normal.length > 1 ? "1fr 1fr" : "1fr"} noMargin forceEvenColumns>
                <FormControlsForFields
                    data={data.attributes || {}}
                    onChange={(o,c) => updateAttributes(c)}
                    schema={attributesSchema}
                    fields={normal}
                    />
              </FormGrid>}

            {!!fancies &&
              <FormGrid columns={variants === 1 ? "1fr 1fr 1fr" : "1fr"} noMargin forceEvenColumns>
                {fancyConfigs.map(f => {
                  switch(f.fieldtype) {
                    case "record_link":
                    case "record_link_multiple":
                      return (
                        <LinkedRecordSelectorMultiple
                          key={f.code}
                          config={f}
                          data={data.attributes || {}}
                          onChange={(o,c) => updateAttributes(c)}
                          />
                      );
                    case "tag":
                    case "tag_multiple":
                      return (
                        <TagSelector
                          key={f.code}
                          config={f}
                          data={data.attributes || {}}
                          onChange={(o,c) => updateAttributes(c)}
                          />
                      )
                    default:
                      return null;
                  }
                })}
              </FormGrid>}

            {!!fats &&
              <FormGrid columns={variants === 1 ? "1fr 1fr 1fr" : "1fr"} noMargin>
                <FormControlsForFields
                    data={data.attributes || {}}
                    onChange={(o,c) => updateAttributes(c)}
                    schema={attributesSchema}
                    fields={fat}
                    />
              </FormGrid>}
            
        </FormGrid>)
}
