import React, { useMemo } from 'react';
import { useCMSConfiguration } from '../Configuration/CMSConfigurationContext';
import { useLoadedData } from '../../../hooks/useLoadedData';
import { CMSRecord } from '../types';
import { createSelectSchema, FieldType } from '../../../hooks/useSchema';
import { FormControl } from '../../schemed';
import { AttributeConfig } from '../useRecordTypeConfig';
import { useCMSRecordContext } from './CMSRecordContext';

interface Props {
  data: any;
  onChange: (origina: any, changes: any) => void;
  config: AttributeConfig;
  multiple?: boolean;
}

const useLinkedRecordSelectorSchema = (recordType: string) => {
  const { apiPath } = useCMSConfiguration();
  const { lang } = useCMSRecordContext();

  const items = useLoadedData<CMSRecord[]>(`${apiPath}/manage/${recordType}?${lang ? "lang=" + lang : ""}`, [], !!recordType);

  const [schemaSingle, schemaMulti] = useMemo(() => {
    const baseSchema = createSelectSchema(items.data.map(i => ({ value: i._id, label: i.title })));

    return [
      baseSchema,
      { ...baseSchema, type: FieldType.multiselect }
    ];
  }, [items.data]);

  return {
    schemaSingle,
    schemaMulti,
  }
}

export const LinkedRecordSelectorMultiple = (props: Props) => {
  const { schemaMulti, schemaSingle } = useLinkedRecordSelectorSchema(props.config.record_type || "");
  return (
    <FormControl
      schema={props.config.fieldtype === "record_link_multiple" ? schemaMulti : schemaSingle}
      field={props.config.code}
      row={props.data}
      onChange={props.onChange}
      extraProps={{ label: props.config.label, autoComplete: true }}
      />
  );
}
