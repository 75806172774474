import React from "react";
import styled from '@emotion/styled';
import { LoadingIndicator } from "../../../toolympus/components/primitives/LoadingIndicator";
import { Button } from "../../common/Button";
import { ProfileData } from "../types";
import { ColumnsContainer, FormContainer, Block } from "./MyProfilePage.style";
import { useFormAttached } from "../../common/useForm";
import { useAvatar } from "../../common/Avatar/useAvatar";
import { Avatar } from "../../common/Avatar/Avatar";
import {FilledInput, InputLabel, Typography} from "@mui/material";
import {FormControl} from "../../common/TextField";
import { useSettingsBare } from "../../../toolympus/api/useSettings";
import { WASettings } from "../../settings/useSettingsEdit";
import { ReceiptLongOutlined } from "@mui/icons-material";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { RolesKeys } from "../../UserManagement/UserManagementContainer";
import { PseudoLink2 } from "../../../toolympus/components/primitives";
import { FileEdit } from "../../../toolympus/components/files/FileEdit";
import { useDownloadFile } from "../../../toolympus/hooks/useDownloadFile";
import { useActionWithConfirmation } from "../../../toolympus/api/useAction";
import { apiFetch } from "../../../toolympus/api/core";
import { useLabelsFromCms } from "../../common/useLabelsFromCms";
import { CrudItemData } from "../../../toolympus/api/useSimpleCrud";

interface Props {
    data: CrudItemData<ProfileData>;
    userId: string;
    email: string;
}

export const messageWithButtonParts = (message: string): { text: string, type?: string }[] => {
  const buttonStarts = message.indexOf("[");
  const buttonEnds = message.indexOf("]");
  if(buttonStarts < 0 || buttonEnds < 0) {
    return [{ text: message }];
  }

  return [
    { text: message.substring(0, buttonStarts), },
    { text: message.substring(buttonStarts+1, buttonEnds), type: "button" },
    { text: message.substring(buttonEnds+1) },
  ];
}

const ConsentFormUploadSection = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  line-height: 1.1667;

  & p {
    line-height: 1.1667;
  }

  width: 100%;

  ${props => props.theme.breakpoints.down("sm")} {
    flex-flow: column;
    align-items: flex-start;
  }

  & .file-editor .placeholder {
    background: #F0F3F7;
    border-color: #F0F3F7;
    text-align: center;
    font-size: 0.9rem;
    color: ${props => props.theme.palette.text.secondary};

    &.drag-target {
      border-color: ${props => props.theme.palette.primary.main};
    }
  }
`;


export const MyProfilePage = (props: Props) => {
    const { userId, email} = props;
    const { data: profile, reload, isLoading, save } = props.data;
    const {input, checkbox, autocomplete } = useFormAttached(profile, props.data.update);
    const avatar = useAvatar(userId);

    const settings = useSettingsBare<WASettings>("/api/settings", { is_applications_enabled: false, is_certificates_available: false });

    const { user } = useUser();
    const isConsentFormRequired = !!profile && (user?.roles || []).length === 1 && user?.roles[0] === RolesKeys.participant;
    const isConsentFormProvided = !!profile && !!profile.consent_form_id;

    
    const { labels, isLoading: isLabelsLoading } = useLabelsFromCms("labels_profile");

    const formLabels = labels.form || {};
    const certificateLabels = labels.certificate || {};
    const consentFormLabels = labels.consent_form || {};

    const consentFormTemplate = useDownloadFile("/api/consent-form/my", `Winter Academy Consent Form ${profile.firstname} ${profile.lastname}.pdf`);
    const certificate = useDownloadFile(`/api/certificate/my`, `Winter Academy ${new Date().getFullYear()} certificate ${profile.firstname} ${profile.lastname}.pdf`);
    const resetConsentForm = useActionWithConfirmation(
      () => apiFetch<{}>("/api/consent-form/reset", "post").then(x => { reload(); return x; }),
      {
        title: consentFormLabels.reset_title || "Reset consent form",
        confirmationHint: consentFormLabels.reset_confirmation || "You will need to upload an updated consent form.",
      });


    if (isLoading || isLabelsLoading) {
        return <LoadingIndicator />
    }


    return (
      <ColumnsContainer>
        <Avatar avatar={avatar} labels={labels.photo} />

        <FormContainer>
            {isConsentFormRequired && !isConsentFormProvided && <ConsentFormUploadSection>
              <Block desktop="7" laptop="12" phone="12">
                <Typography>
                  {messageWithButtonParts(consentFormLabels.template || "").map((m) => (
                    m.type === "button"
                      ? <React.Fragment key={m.text}>
                        {consentFormTemplate.isLoading && <LoadingIndicator sizeVariant="s" />}<PseudoLink2 border onClick={() => consentFormTemplate.download()}>{m.text}</PseudoLink2>
                      </React.Fragment>
                      : <span key={m.text}>{m.text}</span>
                  ))}
                </Typography>
              </Block>

              <Block desktop="5" laptop="12">
                <FileEdit
                  fileId={profile?.consent_form_id}
                  updateFileId={() => {}}
                  config={{
                    apiPath: "/api/consent-form",
                    onUpload: () => reload(),
                  }}
                  placeholder={consentFormLabels.drop_area}
                  />
              </Block>
            </ConsentFormUploadSection>}


            {settings.is_certificates_available && !!profile?.attendance_status && <>
              <Block desktop="7" laptop="12" phone="12">
                <Typography>{certificateLabels.message}</Typography>
              </Block>

              <Block desktop="5" laptop="12">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => certificate.download()}
                  startIcon={certificate.isLoading ? <LoadingIndicator sizeVariant="s" color="inherit" /> : <ReceiptLongOutlined />}>
                  {certificateLabels.download}
                </Button>
              </Block>
            </>}
            <Block desktop="2" laptop="4" phone="12">
                {autocomplete(
                  "title",
                  formLabels.title_options ? formLabels.title_options.split("\n").map(s => s.trim()).filter(s => !!s) : ["Mr", "Ms", "Dr", "Prof"],
                  { label: formLabels.title })}
            </Block>
            <Block desktop="5" laptop="8" phone="12">
                {input('firstname', { label: formLabels.firstname })}
            </Block>
            <Block desktop="5" laptop="12">
                {input('lastname', { label: formLabels.lastname })}
            </Block>
            <Block desktop="7" laptop="12">
                {input('position', { label: formLabels.position })}
            </Block>
            <Block desktop="5" laptop="12">
                {input('company', {label: formLabels.company })}
            </Block>
            <Block desktop="12">
                {input('linkedin_url', { label: formLabels.linkedin_url })}
            </Block>
            <Block desktop="12">
                {input('bio', {label: formLabels.bio, rows: 3})}
            </Block>
            <Block desktop="12">
                {input('interests', {label: formLabels.interests })}
                {checkbox('consent_share_profile', {label: formLabels.consent_share_profile })}
            </Block>
            
            <Block desktop="12">
                <FormControl variant="filled">
                    <InputLabel>{formLabels.email || "E-mail"}</InputLabel>
                    <FilledInput
                        autoComplete="off"
                        value={email}
                        disabled={true}
                    />
                </FormControl>
                {checkbox('consent_email_org', {label: formLabels.consent_email_org })}
                
                {!!profile?.consent_form_id &&
                  <Typography variant="caption" sx={{ marginLeft: "32px", display: "block", lineHeight: 1.1667 }}>
                    {messageWithButtonParts(consentFormLabels.provided || "").map((m) => (
                      m.type === "button"
                        ? <PseudoLink2 key={m.text} onClick={() => resetConsentForm.run()}>{m.text}</PseudoLink2>
                        : <span key={m.text}>{m.text}</span>
                    ))}
                  </Typography>}
            </Block>
            
            <Block desktop="4" laptop="7" phone="12">
                <Button onClick={() => save()} disabled={!profile.consent_email_org} variant="contained" color="primary">{formLabels.save}</Button>
            </Block>
        </FormContainer>
      </ColumnsContainer>);
}
