import { useFetch } from "../../api/useFetch";
import { Schedule } from "./../CourseManagement/types";
import { toLocal } from "../common/timezone";

export const useCourses = () => {
    const {data, loading} = useFetch<Schedule[]>([], {url: `/schedule`});

    return {
        list: data
            .map(({start_datetime, end_datetime, ...data}) => {
                return {
                    start_datetime: toLocal(start_datetime),
                    end_datetime: toLocal(end_datetime),
                    ...data
                };
            })
            .filter(({course}) => !!course), loading
    };
}
