import React from 'react';
import { useUser } from '../../toolympus/userContext/UserContext';
import { Hidden } from '../common/Hidden';
import { Avatar, HeaderBox, HeaderLinks, Title } from "./Header.style";
import { IconsLinks } from './IconsLinks';

interface Props {
    title: string;
    logout: () => void;
}

export const Header = ({title, logout}: Props) => {
    const { user } = useUser();


    return <HeaderBox>
        <Title>{title}</Title>
        <HeaderLinks>
            <Hidden display="flex" className="links" hide={['phone']}>
                <Avatar user={user}/>
            </Hidden>
            <Hidden display="flex" className="links" hide={['tablet', 'phone']}>
                <IconsLinks logout={logout} />
            </Hidden>
        </HeaderLinks> 
    </HeaderBox>
}