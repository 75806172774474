import { useMemo } from "react";
import { CMSRecord } from "../../toolympus/components/CMS"
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { CustomFieldsBlockElementType, extractCustomBlockFields } from "../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { CustomElement } from "../../slate";

export const useLabelsFromCms = (slug: string) => {
  const data = useLoadedData<CMSRecord>(`/api/cms/public/public_docs/${slug}`, { _id: "", } as any);

  const labels = useMemo(() => {
    const blocks: CustomElement[] = (data.data.content as any)?.blocks || [];
    const messagesBlocks = blocks.filter(b => b.type === CustomFieldsBlockElementType)
      .map(b => extractCustomBlockFields(b))
      .reduce<Record<string, Record<string, string>>>((r,b) => {
        if(b.key) {
          r[b.key] = b;
        }
        return r;
      }, {});
    return messagesBlocks;
  }, [data.data]);

  return {
    isLoading: data.isLoading,
    labels,
  }
}
