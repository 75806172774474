import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { RenderElementProps } from 'slate-react';

export const BlockSelectionCss = (props: { theme: Theme, isSelected?: boolean }) => css`
    margin-top: 5px;
    box-shadow: 0 0 5px -2px ${props.isSelected ? props.theme.palette.primary.main : "transparent"};
`;



export interface BlockColorProps {
  backgroundColor?: string;
  textColor?: string;
}

export const blockColorCss = (props: BlockColorProps) => css`
  background: ${props.backgroundColor || "transparent"};
  color: ${props.textColor || "inherit"};
`;

export const BlockTitleItem = styled.div<BlockColorProps>`
  width: 100%;
  ${props => blockColorCss(props)}
  padding: 0.5rem 1rem 0.5rem;
  font-size: 0.75rem;
`;


export const BlockWrapper = styled.div<{ isSelected?: boolean, viewMode?: boolean } & BlockColorProps>`
    padding: 0.5rem 1rem;
    border-top: 2px solid ${props => props.viewMode ? "transparent" : "#eeeeee"};
    border-bottom: 2px solid ${props => props.viewMode ? "transparent" : "#eeeeee"};
    ${props => BlockSelectionCss(props)}
    ${props => blockColorCss(props)}

    & .panel-block-element, & .tiles-block-element {
      border: 2px solid ${props => props.viewMode ? "transparent" : "#eeeeee"};
    }

    & ${BlockTitleItem} {
      width: calc(100% + 2rem);
      margin: -0.5rem -1rem 0;
    }
`;



export const Buttons = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
`;



export const BlockTitle = (props: { element: RenderElementProps["element"], noColoring?: boolean }) => {
  const { block_title, block_type, display_mode,  block_title_background, block_title_color } = props.element as any as Record<string, string>;

  if(!block_title) {
    return null;
  }

  const additionals = [block_type, display_mode].filter(x => !!x).join(", ");

  return (
    <BlockTitleItem
      backgroundColor={props.noColoring ? undefined : block_title_background}
      textColor={props.noColoring ? undefined : block_title_color}>
      {block_title} {additionals ? `(${additionals})` : ""}
    </BlockTitleItem>
  );
}
