import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { LoadingIndicator } from "../../../toolympus/components/primitives/LoadingIndicator";
import { Button } from "../../common/Button";
import { Schedule } from "../../CourseManagement/types";
import { downloadCalendar } from "./AddToCalendar";
import { CoursesContainer, ToolsContainer } from "./CoursesPage.style";
import { Day } from "./Day";
import { useTimetable } from "./useTimetableNew";
import { Moment } from 'moment';
import { shortDayLabel } from "./dates";
import { AllSchedulesNew } from "./AllSchedulesNew";

interface Props {
  data: {
    list: Schedule[];
    loading: boolean;
  };
}

export const CoursesPage = ({ data: { list, loading } }: Props) => {
  const [selectedDay, setSelectedDay] = useState<0 | Moment>(0);
  const timetable = useTimetable(list);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <CoursesContainer>
      <Tabs
        classes={{ indicator: "indicator" }}
        variant="scrollable"
        scrollButtons="auto"
        value={selectedDay}
        onChange={(_, day) => setSelectedDay(day)}
        TabIndicatorProps={{ children: <span /> }}
      >
          <Tab label="see all" value={0} key="all" />
          {timetable.allDays.map((day,i) => <Tab label={shortDayLabel(day)} value={day} key={shortDayLabel(day)+i} />)}
      </Tabs>

      {selectedDay === 0 ? (
        <>
          <ToolsContainer>
            <i>All times are shown in your browser's local time</i>
            <Button
              onClick={downloadCalendar(list)}
              variant="outlined"
              color="primary"
            >
              + Add All Courses to Calendar
            </Button>
          </ToolsContainer>
          <AllSchedulesNew timetable={timetable} />
        </>
      ) : (
        <Day timetable={timetable} day={selectedDay} />
      )}
    </CoursesContainer>
  );
};
