import React from 'react';
import { LectureRoomPage } from './LectureRoomPage';
import { useLecture } from './useLecture';
import { useZoom } from './useZoom';

export const LectureRoomContainer = () => {
    
    return <LectureRoomPage 
        subject={useLecture()} 
        jitsi={useZoom()}
    />;
}