import styled from '@emotion/styled';
import { Button, ButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RenderElementProps, useSelected } from 'slate-react';
import { ActionRow, OccupyFreeSpace } from '../../../primitives';
import { BlockTitle, BlockTitleItem, BlockWrapper } from '../../elements/Common';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';
import { PowerEditorBase } from '../../slate/PowerEditorBase';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { useColumns } from './useColumns';

export const ColumnsBlockElementType = "columns-block";

const Wrapper = styled(BlockWrapper)<{ nColumns: number }>`
    padding: 0.5rem 0;

    & ${BlockTitleItem} {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    & > div:last-child {
        width: 100%;
    }

    & > div:last-child > div:last-child {
        display: grid;
        grid-template-columns: repeat(${props => props.nColumns}, 1fr);
        
        & > * {
            padding: 0 1rem;

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            &:not(:last-child) {
                border-right: 3px solid ${props => props.viewMode ? "transparent" : "#eeeeee"};
            }
        }
    }
`;

export const ColumnsBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    // const tiles = useTiles(element);

    const isSelected = useSelected();

    const { viewMode } = usePowerEditorContext();
    
    const settings = usePowerEditorSettings();
    const EditorComponent = settings.EditorComponent || PowerEditorBase;

    const columns = useColumns(element);

    return (
        <Wrapper
          {...attributes}
          isSelected={isSelected}
          nColumns={columns.columns_content.length}
          viewMode={viewMode}
          backgroundColor={(props.element as any)?.block_panel_background}
          textColor={(props.element as any)?.block_panel_color}>
            {children}

            <div style={{ width: "100%" }} contentEditable={false} key={columns.columns_content.length}>
                {!viewMode && <BlockTitle element={props.element} />}
                {!viewMode && <ActionRow lastItemMarginRight='0' itemMarginTop='0'>
                    <OccupyFreeSpace />
                    <Typography variant="caption">
                        <FormattedMessage id="powerdoc.plugins.columns.n_columns" />
                    </Typography>
                    <ButtonGroup size="small">
                        {[2,3,4].map(n => (
                            <Button
                                key={n}
                                size="small"
                                variant={columns.columns_content.length === n ? "contained" : "outlined"}
                                color={columns.columns_content.length === n ? "primary" : "inherit"}
                                onClick={() => columns.updateColumnsNumber(n)}>
                                {n}
                            </Button>
                        ))}
                    </ButtonGroup>
                </ActionRow>}
                <div>
                    {columns.columns_content.map((c, cIdx) => (
                        <EditorComponent
                            key={cIdx}
                            content={c}
                            update={v => columns.updateColumnContent(cIdx, v)}
                            viewMode={viewMode}
                            />
                    ))}
                </div>
            </div>
        </Wrapper>
    );
}
