import React, { ComponentType, ReactNode } from 'react';
import styled from '@emotion/styled';
import { EditArrayData, useEditArray } from '../../hooks/useEditArray';
import { Buttons, FormGrid, FormGridProps, MoreActionsMenuButton } from '.';
import { Button, IconButton } from '@mui/material';
import { Add, ArrowDownward, ArrowUpward, DeleteOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useThrottledState } from './useThrottledState';

export const ListWrapper = styled.div`
  padding: 0.5rem 1rem;
`;

export interface ItemWrapperExtraProps extends FormGridProps {
  dontScaleActions?: boolean;
  isDragTarget?: boolean;
  isDragged?: boolean;
}

export interface ItemWrapperProps extends FormGridProps, ItemWrapperExtraProps {
}

export const ItemWrapper = styled(FormGrid)<ItemWrapperExtraProps>`
  padding: 0.5rem 0;

  &:nth-child(2n) {
    background: #cccccc18;
  }

  opacity: ${props => props.isDragged ? 0.5 : 1};

  & > .item-actions {
    ${props => props.dontScaleActions
      ? ""
      : `transform: scale(0.7);
      transform-origin: 100% 0;`}

    & > * {
      display: block;
    }
  }

  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    background: ${props => props.theme.palette.primary.main};
    opacity: ${props => props.isDragTarget ? 0.5 : 0};
  }
`;
ItemWrapper.defaultProps = { columns: "1fr max-content", forceEvenColumns: true, noMargin: true };



export const ItemWrapperBasic = styled.div<ItemWrapperExtraProps>`
  padding: 0.5rem 20px 0.5rem 0.5rem;

  &:nth-child(2n) {
    background: #cccccc18;
  }

  opacity: ${props => props.isDragged ? 0.5 : 1};
  position: relative;


  & > .item-actions {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    background: ${props => props.theme.palette.primary.main};
    opacity: ${props => props.isDragTarget ? 0.5 : 0};
  }
`;


export interface ItemEditorProps<T> {
  item: T;
  update: (changes: Partial<T>) => void;
  remove?: () => void;
  isCollapsed?: boolean;
  setIsCollapsed?: (action: T, v: boolean) => void;
}

interface Props<T> {
  items?: T[];
  update: (v: T[]) => void;
  prepareItemChanges?: (c: Partial<T>, original: T) => Partial<T>;
  createDflt: () => T;
  idField: keyof T;
  itemEditor: ComponentType<ItemEditorProps<T>>;
  itemExtraActions?: ComponentType<ItemEditorProps<T> & { arrayData: EditArrayData<T> }>;
  itemWrapper?: ComponentType<ItemWrapperProps>;
  itemWrapperProps?: FormGridProps;
  wrapperComponent?: ComponentType;

  addLabel?: ReactNode;
  noAddButton?: boolean;
  extraActions?: ReactNode;
  dontScaleActions?: boolean;

  noItemMoveButtons?: boolean;
  noItemRemoveButton?: boolean;
  extraActionsAsMenu?: boolean;

  getIsCollapsed?: (item: T) => boolean | undefined;
  setIsCollapsed?: (item: T, v: boolean) => void;
}

export const ListEditor = <T,>(props: Props<T>) => {
  const { update, prepareItemChanges, createDflt, idField, itemEditor: ItemEditor } = props;
  const ItemWrapperX = props.itemWrapper || ItemWrapper;
  const ItemExtraActions = props.itemExtraActions;
  const items = props.items || [];

  const WrapperComponent = props.wrapperComponent || ListWrapper;

  const editor = useEditArray({
    items,
    dflt: {} as T,
    createDflt,
    update,
    prepareItemChanges,
  })

  const [dragging,setDragging] = useThrottledState<T | null>(null);
  const [dragTarget,setDragTarget] = useThrottledState<T | null>(null);


  return (
    <WrapperComponent>
      {items.map((item,idx) => (
        <ItemWrapperX
          key={item[idField] as unknown as string}
          dontScaleActions={props.dontScaleActions}
          draggable
          isDragged={item === dragging}
          isDragTarget={item === dragTarget}
          onDragStart={() => setDragging(item)}
          onDragEnter={e => { e.preventDefault(); setDragTarget(item); }}
          onDragOver={e => { e.preventDefault(); setDragTarget(item); }}
          onDragLeave={e => { e.preventDefault(); setDragTarget(null); }}
          onDrop={() => {
            if(dragging && item !== dragging) {
              const idxFrom = editor.items.indexOf(dragging);
              if(idxFrom >= 0) {
                editor.moveTo(idxFrom, idx);
              }
            }
            setDragging(null);
            setDragTarget(null);
          }}
          {...(props.itemWrapperProps || {})}>
          <ItemEditor item={item} update={c => editor.update(idx, c)} remove={() => editor.remove(idx)} setIsCollapsed={props.setIsCollapsed} isCollapsed={props.getIsCollapsed && props.getIsCollapsed(item)} />

          <div className="item-actions">
            {props.extraActionsAsMenu
              ? (<>
                  <MoreActionsMenuButton
                    actions={[
                      items.length > 0 && !props.noItemMoveButtons ? ["up", <ArrowUpward color="action" />, () => editor.moveUp(idx)] : null,
                      !props.noItemRemoveButton ? ["remove", <DeleteOutlined color="action" />, () => editor.remove(idx)] : null,
                      items.length > 0 && !props.noItemMoveButtons ? ["down", <ArrowDownward color="action" />, () => editor.moveDown(idx)] : null,
                    ]}
                    strippedButton
                    size="small"
                    />
                  {ItemExtraActions && <ItemExtraActions item={item} update={c => editor.update(idx,c)} remove={() => editor.remove(idx)} arrayData={editor} setIsCollapsed={props.setIsCollapsed} isCollapsed={props.getIsCollapsed && props.getIsCollapsed(item)} />}
                </>)
              : <>
                {items.length > 0 && !props.noItemMoveButtons && <IconButton size="small" onClick={() => editor.moveUp(idx)}><ArrowUpward /></IconButton>}
                {ItemExtraActions && <ItemExtraActions item={item} update={c => editor.update(idx,c)} remove={() => editor.remove(idx)} arrayData={editor} setIsCollapsed={props.setIsCollapsed} isCollapsed={props.getIsCollapsed && props.getIsCollapsed(item)} />}
                {!props.noItemRemoveButton && <IconButton size="small" onClick={() => editor.remove(idx)}><DeleteOutlined /></IconButton>}
                {items.length > 0 && !props.noItemMoveButtons && <IconButton size="small" onClick={() => editor.moveDown(idx)}><ArrowDownward /></IconButton>}
              </>}
          </div>
        </ItemWrapperX>
      ))}

      {(!props.noAddButton || !!props.extraActions) &&
        <Buttons style={{ marginTop: "0.5rem" }}>
          {!props.noAddButton &&
            <Button
              size="small"
              color="primary"
              startIcon={<Add />}
              onClick={() => editor.add()}>
                {props.addLabel || <FormattedMessage id="common.add" />}
            </Button>}
          {props.extraActions}
        </Buttons>}
    </WrapperComponent>
  );
}
