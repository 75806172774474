import {
  Button,
  IconButton,
  TableContainer,
  TextField,
} from "@mui/material";
import { Add, DeleteOutlined } from "@mui/icons-material";
import React from "react";
import { LoadingIndicator } from "../../../toolympus/components/primitives/LoadingIndicator";
import { TableForFields, WrapperFlex, } from "../../../toolympus/components/schemed";
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";
import { Schedule } from "../types";
import { LoadingStatus, LongBlock } from "./CourseEditPage.style";
import moment from "moment";
import { ActionRow, FormGrid } from "../../../toolympus/components/primitives";
import { DateEdit, TimeEdit } from "../../../toolympus/components/schemed/TimeEdit";
import { DateTimeInternalFormat } from "../../../toolympus/api/datetimeUtil";
import { SchedulesEditData } from "../useSchedules";

const schema: Schema = {
  title: {
    type: FieldType.text,
    label: "Title",
    valueDict: null,
  },
  start_datetime: {
    type: FieldType.datetime,
    label: "Start",
    valueDict: null,
  },
  end_datetime: {
    type: FieldType.datetime,
    label: "End",
    valueDict: null,
  },
};


const DoWDisplay = ({ date }: { date: string | undefined }) => date ? <>{moment(date).format("ddd")}</> : null;;

const ScheduleDatePicker = (props: { item: Schedule, update: (changes: Partial<Schedule>) => void }) => {
  const updateDate = (v: string | null) => {
    if(v && props.item.start_datetime) {
      const startTime = moment(props.item.start_datetime).toObject();
      const endTime = moment(props.item.end_datetime).toObject();
      const date = moment(v).toObject();
      
      const start_datetime = moment();
      const end_datetime = moment();
      start_datetime.set({ ...date, hours: startTime.hours, minutes: startTime.minutes, seconds: startTime.seconds });
      end_datetime.set({ ...date, hours: endTime.hours, minutes: endTime.minutes, seconds: endTime.seconds });
      props.update({ start_datetime: start_datetime.format(DateTimeInternalFormat), end_datetime: end_datetime.format(DateTimeInternalFormat) });
    }
  }

  return (
    <WrapperFlex className="form-control">
      <DateEdit
        onChange={(o,c) => { updateDate(c.start_datetime)}}
        row={props.item}
        field="start_datetime"
        schema={{}}
        extraProps={{ label: " "}}
        />
    </WrapperFlex>)
}

const ScheduleTimePicker = (props: { item: Schedule, field: keyof Schedule, update: (changes: Partial<Schedule>) => void }) => {
  const { item, field, update } = props;

  const updateTime = (v: string | null) => {
    if(v && item[field]) {
      const date = moment(item[field] as string | undefined);
      const time = moment(v).toObject();
      
      date.set({ hours: time.hours, minutes: time.minutes, seconds: time.seconds });
      update({ [field]: date.format(DateTimeInternalFormat),  });
    }
  }

  return (
    <WrapperFlex className="form-control">
      <TimeEdit
        onChange={(o,c) => { updateTime(c[field])}}
        row={props.item}
        field={field}
        schema={{}}
        extraProps={{ label: " "}}
        />
    </WrapperFlex>)
}


interface SchedulesProps {
  data: SchedulesEditData;
}

export const SchedulesTable = (props: SchedulesProps) => {
  const { add, remove, loading, data, setData } = props.data

  const onChange = (updated: Schedule) => {
    setData(
      data.map((item) => {
        if (item._id !== updated._id) {
          return item;
        }

        return updated;
      })
    );
  };

  return (
    <LongBlock>
      <TableContainer>

        <ActionRow firstItemNoMargin itemMarginTop="0">
          <Button size="small" color="primary" onClick={() => add()} startIcon={<Add />}>add a lecture</Button>

          <LoadingStatus>{loading && <LoadingIndicator />}</LoadingStatus>
        </ActionRow>

        <TableForFields
          schema={schema}
          fields={[
            ["title", { editable: true }],
            ["date", { width: 150, label: "Date", editable: true }],
            ["dow", { width: 80, label: " " }],
            ["start_time", { width: 110, editable: true, label: "Start" }],
            ["end_time", { width: 110, editable: true, label: "End" }],
            ["remove", { width: 80, label: " " }],
          ]}
          data={data}
          onChange={(old, changes) => {
            onChange({ ...old, ...changes });
          }}
          fieldElement={(field) => {
            switch(field) {
              case "title":
                return (r,s,orig) => (
                  <FormGrid columns="1fr" gap="dense" noMargin>
                    {orig}
                    <TextField
                      value={r.external_link || ""}
                      onChange={e => onChange({ ...r, external_link: e.target.value })}
                      placeholder="External link"
                      />
                  </FormGrid>)
              case "remove":
                return ({ _id }) => <IconButton size="small" onClick={() => remove(_id)}><DeleteOutlined /></IconButton>;
              case "dow":
                return (item) => <DoWDisplay date={item.start_datetime} />;
              case "date":
                return item => <ScheduleDatePicker item={item} update={changes => onChange({ ...item, ...changes } as any)}  />
              case "start_time":
                return item => <ScheduleTimePicker field="start_datetime" item={item} update={changes => onChange({ ...item, ...changes } as any)}  />
              case "end_time":
                return item => <ScheduleTimePicker field="end_datetime" item={item} update={changes => onChange({ ...item, ...changes } as any)}  />
              default:
                return null;
            }
          }
          }
        />
      </TableContainer>
    </LongBlock>
  );
};
