import styled from '@emotion/styled'

const primaryColor = (p: any) => p.theme.palette.primary.main;

export const FilterHeadStyle = styled.div`
    display: flex;
    justify-content: space-between;

    & .MuiTabs-root {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;

        text-align: center;
        text-transform: uppercase;

        color: ${primaryColor};

        margin-bottom: 50px;
    }

    & .indicator {
        display: flex;
        justify-content: center;
        height: 3px;
        background-color: ${primaryColor};
    }
`;
