import React from 'react';
import { UserManagementForm } from '../../toolympus/components/userManagement/UserManagementForm';

export const RolesKeys = {
  organizer: "organizer",
  participant: "participant",
}

export const Roles = [
    { name: 'Администратор', key: 'admin' },
    { name: 'Организатор', key: 'organizer' },
    { name: 'Комитет', key: 'committee' },
    { name: 'Преподаватель', key: 'tutor' },
    { name: 'Участник', key: 'participant' },
]

const UserManagementContainer = () => {
    return <UserManagementForm userRoles={Roles} allowInvites={true} />
}

export default UserManagementContainer;