import styled from '@emotion/styled'
import { Button as MuiButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const primaryColor = (p: any) => p.theme.palette.primary.main;

export const Button = styled(MuiButton)<{ noFullWidth?: boolean, target?: string, rel?: string }>`
    width: ${props => props.noFullWidth? 'max-content' : '100%'};

    text-transform: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    

    &.MuiButton-contained.Mui-disabled {
        background-color: ${({theme: {palette: {primary: {light}}}}) => light};
        color: #fff;
        opacity: .7;
        text-transform: none;
    }
`;

export const ButtonLink = styled(RouterLink)`
    width: 100%;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    border: 1px solid ${primaryColor};

    cursor: pointer;

    & img { 
        height: 15px;
        width: auto;
    }
`;

export const Link = styled(RouterLink)`
    cursor: pointer;
    text-decoration: none;
    color: ${primaryColor};
`;
