import styled from '@emotion/styled';
import { Button } from '@mui/material';
import React from 'react';
import { SectionTitle } from '../../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../../toolympus/components/schemed';
import { FieldType } from '../../../../toolympus/hooks/useSchema';
import { ApprovalButtonsIcons } from '../FormComponents';
import { ApprovalReviewStage } from './ApprovalReviewCommittee';

const Title = styled(SectionTitle) <{ direction: string }>`
    text-align: ${props => props.direction};
`;

const SaveButton = styled(Button)`
    display: block;
`;

const SaveButtonWrapper = styled.div`
  margin-top: 10px;
  min-height: 50px;
`;

export const ApprovalReviewRACStage = (props: ApprovalReviewStage) => {
    const { updateApproval, approval, isShowSaveButton, saveApproval } = props.review;
    return <div>
        <Title direction={"left"}>
            RAC Approval
        </Title>

        <ApprovalButtonsIcons approvalHandler={updateApproval} approvalStatus={approval.status} direction={"back"} disable={props.type === "committee"} />

        <FormControlsForFields
            data={approval}
            schema={{
                comment: {
                    type: FieldType.textlong,
                    label: "Comments"
                }
            }}
            fields={[
                ["comment", { disabled: props.type === "committee", autoRows: true }],
            ]}
            onChange={(original, changes) => { updateApproval(changes) }} />


        <SaveButtonWrapper>
            {
                props.type !== "committee" && isShowSaveButton && <SaveButton variant="contained" onClick={() => {
                    saveApproval({
                        status: approval.status,
                        id: `${props.id}`,
                        reload: props.reload,
                        isApprovalFinal: false
                    });
                }}>
                    Save
                </SaveButton>
            }
        </SaveButtonWrapper>
    </div>
}