import styled from '@emotion/styled'
import {Link} from "react-router-dom";
import {media} from "../../frame";

const secondaryText = (p: any) => p.theme.palette.text.secondary;
const primaryColor = (p: any) => p.theme.palette.primary.main;

export const LinkBack = styled(Link)`
    grid-column: 1/3;
    
    ${media.phone} {
        grid-column: 1/2;
    }

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;

    display: flex;
    align-items: center;
    gap: 6px;

    color: ${secondaryText};

    & .MuiSvgIcon-root {
        color: ${secondaryText};
    }
`;

export const Info = styled.div`
    
`;

export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 15px;

    border-bottom: 1px solid rgba(155, 155, 155, 0.4);
`;

export const SubTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130.3%;

    letter-spacing: -0.24px;

    color: #9B9B9B;
    
    padding: 10px 0;
    margin-top: 20px;
`;

export const Name = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
`;

export const Position = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
`;

export const Actions = styled.div`
    display: grid;
    grid-auto-columns: 35px 35px;
    grid-auto-flow: column;
    gap: 15px;
    
    width: 85px;
`;

export const ButtonLinkAnchor = styled.a`
    width: 35px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    border: 1px solid rgba(97, 132, 173, 0.5);
    transition: 250ms;

    &:hover {
        border-color: ${primaryColor}
    }

    & img { 
        height: 15px;
        width: auto;
    }

    ${media.phone} {
        border: none;
        height: 20px;
    }
`;
