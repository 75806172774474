import { Tutor } from "../../TutorsEdit/types";

interface Participant {
    lastname: string;
    firstname: string;
    title: string;
}

export const participantName = (p?: Partial<Participant> | null) => `${p?.title ? p?.title + " " : ""}${p?.firstname || ""} ${p?.lastname || ""}`;
export const tutorPosition = (p?: Partial<Tutor> | null) => [p?.position, p?.company].filter(x => !!x).join(", ");
