import React from 'react';
import {AboutCoursePage} from './presentation/AboutCoursePage';
import {useMyCourse} from './useAboutCourse';
import {useSchedules} from "../CourseManagement/useSchedules";

export const MyCourseContainer = () => {
    const data = useMyCourse();

    return <AboutCoursePage data={data} schedules={useSchedules(data.data._id)} myCourse />;
}
