import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { StudentsManagementListData } from './useStudentsManagementList';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { Check } from '@mui/icons-material';
import { Tooltip } from '../../../toolympus/components/primitives';

interface Props {
  data: StudentsManagementListData;
}


const CornerCell = styled(TableCell)`
  z-index: 300;
  background: #ffffff;
`;

const TimeCell = styled(TableCell)`
  white-space: pre-line;
  text-align: center;
  background: #ffffff;
  z-index: 300;

  &:nth-child(2n) {
    background: #6184AD18;
  }
`;

const NameCell = styled(TableCell)`
  white-space: nowrap;
  position: sticky;
  left: 0;
  background: linear-gradient(to right, #ffffff 0%, #ffffff 80%, #ffffff00 100%);
  z-index: 200;
`;

const CheckCell = styled(TableCell)`
  & .empty {
    opacity: 0;
  }
  &:hover {
    & .empty {
      opacity: 0.75;
    } 
  }

  &:nth-child(2n) {
    background: #6184AD18;
  }
`;

const TableContainerS = styled(TableContainer)`
  max-height: calc(100% - 44px);

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: ${props => props.theme.palette.grey[200]};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.palette.primary.light};
  }
`;

export const StudentsAttendanceTable = (props: Props) => {
  const students = props.data.students.data;

  const slots = useMemo(() => {
    return props.data.attendanceSlots.data
      .map(s => ({
        slot: s.start_datetime,
        label: moment(s.start_datetime).format("DD.MM ddd HH:mm").replaceAll(" ", "\n"),
      }))
  }, [props.data.attendanceSlots.data]);

  const trackByStudentBySlot = useMemo(() => {
    const byStudentBySlot = {} as Record<string, Record<string, boolean>>;
    props.data.attendanceTrack.data.forEach(x => {
      if(!byStudentBySlot[x.student_id]) {
        byStudentBySlot[x.student_id] = {};
      }
      byStudentBySlot[x.student_id][x.slot_id] = true;
    })
    return byStudentBySlot;
  }, [props.data.attendanceTrack.data]);

  return (
    <TableContainerS>
      <Table stickyHeader>
        <TableHead>
          <CornerCell></CornerCell>
          {slots.map(s => (
            <TimeCell key={s.slot}>
              {s.label}
            </TimeCell>
          ))}
        </TableHead>

        <TableBody>
          {students.map(student => (
            <TableRow key={student._id}>
              <NameCell>
                {student.firstname} {student.lastname}
              </NameCell>

              {slots.map(s => (
                <CheckCell key={s.slot}>
                  <Tooltip text={`${s.label} - ${student.firstname} ${student.lastname} - ${(trackByStudentBySlot[student._id] || {})[s.slot] ? "attended" : "not attended"}`}>
                    {(trackByStudentBySlot[student._id] || {})[s.slot]
                      ? <IconButton size="small" color="success" onClick={() => props.data.setAttendanceTrack(student._id, s.slot, false)}><Check /></IconButton>
                      : <IconButton className="empty" size="small" color="primary" onClick={() => props.data.setAttendanceTrack(student._id, s.slot, true)}><Check /></IconButton>}
                  </Tooltip>
                </CheckCell>
              ))}
            </TableRow>
          ))}

        </TableBody>
      </Table>
    </TableContainerS>
  );
}
