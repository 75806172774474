import { useEffect, useState, useCallback } from "react";
import { apiFetch, apiUploadFile, FetchTypes } from "../toolympus/api/core";

interface RequestData {
    body?: any,
    url?: string;
    method?: FetchTypes;
}

interface HookRequsetData extends RequestData {
    depsOfEffect?: any[]
}

export const useFetch = <T>(initValue: T, {url, method, body, depsOfEffect = []}: HookRequsetData, run: boolean = true) => {
    const [data, setData] = useState<T>(initValue);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [promiseSwitch] = useState<{pr: Promise<any> | null}>({pr: null});

    const request = useCallback(({body: otBody, url: otUrl, method: otMethod}: RequestData = {}) => {
        setLoading(true);
        return apiFetch<T>(
                '/api' + (otUrl || url), 
                otMethod || method || FetchTypes.GET, 
                otBody || body
            )    
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [url, method, body]);

    useEffect(() => {
        if (run) {
            request();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [run, request, ...depsOfEffect]);

    const file = (url: string, file: File) => {
        setLoading(true);
        return apiUploadFile('/api' + url, method || FetchTypes.PUT, 'file', file)
            .finally(() => setLoading(false));
    }

    const  withTimer = (promiseFn: () => Promise<any>, duration: number): Promise<any> => {
        const promise = new Promise<any>((resolve, reject) => {
            setTimeout(() => {
                setLoading(true);
        
                if (promise === promiseSwitch.pr as Promise<any>) {
                    promiseFn()
                        .then(res => {
                            if (promise === promiseSwitch.pr as Promise<any>) {
                                resolve(res);
                                setLoading(false);
                            }
                        });
                } else {
                    setLoading(false);
                }
            }, duration);
        });

        promiseSwitch.pr = promise;

        return promise;
    }

    const durationRequest = (data: RequestData = {}, duration: number) => {
        return withTimer(() => request(data), duration);
    };

    return {
        data,
        loading,
        error,
        setData,
        request,
        reload: () => request(),
        file,
        durationRequest
    }
}