import React from 'react';
import { PartnersCarousel, PartnersCarouselProps, usePagePartners } from '.';

interface Props extends Omit<PartnersCarouselProps, 'data'> {
    pagesApiPath: string;
    pageSlug: string;
}

export const PartnersCarouselContainer = (props: Props) => {
    const data = usePagePartners(props.pagesApiPath, props.pageSlug);
    return <PartnersCarousel {...props} data={data} />;
}
