import React, { useCallback, useState } from "react";
import { useDialogState, useItemDialogState } from "../../../primitives";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { Editor, Element, Transforms } from "slate";
import { PhotoOutlined } from "@mui/icons-material";
import { useMediaLibContext } from "../../../medialib";
import { EditBlockImageCommand, InlineImageProperties } from "./types";
import { MediaLibPickerDialog } from "../../../medialib/MediaLibPickerDialog";
import { EditImageDialog } from "./EditImageDialog";
import { createWithVoidInjector } from "../common";
import { BlockImageElement, BlockImageElementType as BlockImageElementTypeC } from "./BlockImageElement";

const BlockImageElementType = BlockImageElementTypeC;


export const insertBlockImage = (editor: Editor, item: InlineImageProperties) => {
  const image = {
      ...item,
      type: BlockImageElementType,
      children: [{ text: "" }],
  }
  
  Transforms.insertNodes(editor, image)
}

export const updateBlockImage = (editor: Editor, item: InlineImageProperties) => {
  const itemCopy: InlineImageProperties = {
      url: item.url,
      _class: item._class,
      width: item.width,
      height: item.height,
  }
  

  Transforms.setNodes(
      editor,
      itemCopy,
      {
          match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === BlockImageElementType,
          voids: true
      },
  )
}

export const withBlockImages = createWithVoidInjector(BlockImageElementType);

interface EditingContext {
    editor: Editor;
}

export const useBlockImagesPlugin = (): EditorPlugin => {
    const [editingContext, setEditingContext] = useState<EditingContext | null>(null);
    const insertImageDialogState = useItemDialogState<InlineImageProperties>();
    const replaceImageDialogState = useDialogState();
    const editImageDialogState = useItemDialogState<Element & InlineImageProperties>();
    const mediaLib = useMediaLibContext();


    const startEdit = useCallback(
        (editor: Editor, e?: Element) => {
            if(e?.type !== BlockImageElementType) {
                setEditingContext({ editor });
                insertImageDialogState.open({ });
            } else {
                setEditingContext({ editor });
                editImageDialogState.open(e);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const dialog = (<>
        {!!editImageDialogState.isOpen && <EditImageDialog
            {...editImageDialogState}
            openImagePicker={() => replaceImageDialogState.open()}
            updateImageSettings={settings => {
                if(editingContext?.editor) {
                  updateBlockImage(editingContext.editor, settings)
                }
            }}
            />}
        {replaceImageDialogState.isOpen &&
          <MediaLibPickerDialog
            {...replaceImageDialogState}
            selectFile={mf => editingContext?.editor && editImageDialogState.isOpen &&
                editImageDialogState.update({ url: mediaLib.getFilepath(mf) })}
            closeOnSelect
            selectedUrls={[]}
            />}
        {insertImageDialogState.isOpen &&
          <MediaLibPickerDialog
            {...insertImageDialogState}
            selectFile={mf => editingContext?.editor && insertBlockImage(editingContext?.editor, { ...insertImageDialogState.item, url: mediaLib.getFilepath(mf) })}
            closeOnSelect
            selectedUrls={[]}
            />}
    </>);

    return {
        key: "block-images",
        inject: e => withBlockImages(e),
        customBlocks: { [BlockImageElementType]: BlockImageElement },
        dialogs: dialog,
        commands: [{
            isAvailable: () => !!mediaLib.isAvailable,
            name: "insert-block-image",
            invoke: editor => startEdit(editor),
            menu: { section: "insert-item", label: "Block Image", label_id: "powerdoc.plugins.block_images.title", icon: <PhotoOutlined /> },
        }, {
            name: EditBlockImageCommand,
            isInternal: true,
            invoke: (editor, element) => startEdit(editor, element),
        }],
    };
}
