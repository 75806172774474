import styled from '@emotion/styled'

export const CourseForm = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    max-width: 1100px;
    margin: 1rem auto 0;
`;

export const LongBlock = styled.div`
    grid-column: 1/4;
`;


export const LoadingStatus = styled.div`
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    color: ${({theme}) => theme.palette.primary.main};
`;
