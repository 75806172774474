import React from "react";
import { useParams } from "react-router-dom";
import { CourseEditPage } from "./presentation/CourseEditPage";
import { useCourse, useNewCourse } from "./useCourse";
import { useSchedules } from "./useSchedules";
import { AcademyDictionariesProvider } from "./AcademyDictionariesProvider";

export const CourseContainer = () => {
    const { id } = useParams<{id: string}>();

    return (
      <AcademyDictionariesProvider>
        {id === 'new'? <NewCourseContainer /> : <ExistCourseContainer id={id} />}
      </AcademyDictionariesProvider>
    )
}

const NewCourseContainer = () => {
    return <CourseEditPage data={useNewCourse()} />
}

const ExistCourseContainer = ({id}: {id: string}) => {
    return <CourseEditPage data={useCourse(id)} scheduleData={useSchedules(id)} />;
}