import styled from '@emotion/styled';
import React from 'react';
import { SectionTitle } from '../../../../toolympus/components/primitives';
import { ApprovalButtonsIcons } from '../FormComponents';
import { ApprovalReviewStage } from './ApprovalReviewCommittee';

const Title = styled(SectionTitle) <{ direction: string }>`
    text-align: ${props => props.direction};
`;

export const ApprovalReviewResult = (props: ApprovalReviewStage) => {
    const { approval } = props.review;

    const saveApproval = ({ status }: { status: string }) => {
        props.setApproval && props.setApproval({
            status,
            id: `${props.id}`,
            reload: props.reload,
            isApprovalFinal: true
        });
    }

    return <div>
        <Title direction={"right"}>
            Result
        </Title>

        <ApprovalButtonsIcons approvalHandler={saveApproval} approvalStatus={approval.finalStatus} direction={"forward"} disable={props.type === "committee"} />
    </div>
}