import React from "react"
import { Button } from "@mui/material"
import { LoadingIndicator } from "../../../toolympus/components/primitives/LoadingIndicator"
import { SchedulesTable } from "./SchedulesTable"
import { ActionRow, FormGrid, OccupyFreeSpace } from "../../../toolympus/components/primitives"
import { SchedulesEditData } from "../useSchedules"
import { FormControlsForFields } from "../../../toolympus/components/schemed"
import { CourseEditData, CourseSchema } from "../useCourse"

interface Props {
    data: CourseEditData,
    scheduleData?: SchedulesEditData;
}


export const CourseEditPage = (props: Props) => {
  const { data: course, isLoading, save, update, remove } = props.data;
  const schedules = props.scheduleData;


    const onSave = () => {
        save().then(() => {
            if (schedules) {
                for (const item of schedules.data) {
                    schedules.save(item);
                }
            }
        });
    }

    if(!course) {
      return null;
    }

    const saveBtn = 
        <Button 
            onClick={onSave} 
            variant="contained" 
            color="primary"
            disabled={!course.title || !course.kind || isLoading}
        >
            Save
        </Button>

    return <>
      <ActionRow itemMarginTop="0" lastItemMarginRight="0">
        {isLoading && <LoadingIndicator />}

        <OccupyFreeSpace />
        
        {!!course._id && 
            <Button
                variant="text"
                color="secondary"
                onClick={remove}
            >
                Delete
            </Button>
        }
        {saveBtn}
      </ActionRow>
    
      <FormGrid>
        <FormControlsForFields
          data={course}
          onChange={(o,c) => update(c)}
          schema={CourseSchema}
          fields={[
            ["title"],
            ["shorttitle"],
            ["kind"],
          ]}
          />
      </FormGrid>

      <FormGrid noMargin columns="1fr">
        <FormControlsForFields
          data={course}
          onChange={(o,c) => update(c)}
          schema={CourseSchema}
          fields={[
            ["tutors_ids"],
            ["description", { autoRows: true }],
          ]}
          />
      </FormGrid>
      
      <div style={{ marginTop: "2rem" }} />

      {schedules && <SchedulesTable data={schedules} />}
      
      
      <ActionRow itemMarginTop="1rem" lastItemMarginRight="0" style={{ marginBottom: "0.25rem" }}>
        <OccupyFreeSpace />
        {saveBtn}
      </ActionRow>
    </>
}