import styled from '@emotion/styled'
import {media} from "../frame";

type Mods = (keyof typeof media)[];
const mods = Object.keys(media) as Mods;

interface PropsHidden {
    hide: Mods;
    display?: string
}

interface PropsShow {
    show: Mods;
    display?: string
}

export const Hidden = styled.div<PropsHidden>`
    ${({hide, display}) => mods
        .map(mod => media[mod] + `{ display: ${hide.includes(mod)? 'none' : display || 'block'}}`)}
`;

export const Show = styled.div<PropsShow>`
    ${({show, display}) => mods
        .map(mod => media[mod] + `{ display: ${!show.includes(mod)? 'none' : display || 'block'}}`)}
`;
