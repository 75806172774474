import { IconButton } from "@mui/material"
import { Add } from "@mui/icons-material"
import React from "react"
import { useHistory } from "react-router-dom"
import { TableForFields } from "../../../toolympus/components/schemed"
import styled from '@emotion/styled';
import { ActionRow, LoadingIndicator, OccupyFreeSpace, SearchField } from "../../../toolympus/components/primitives"
import { CourseSchema } from "../useCourse"
import { AcademyDictionariesProvider } from "../AcademyDictionariesProvider"
import { CourseListData } from "../useCourseList"

interface Props {
    data: CourseListData;
}

const TableWrap = styled.div`
    & .MuiTableRow-root:hover {
        cursor: pointer;
    }
`;

export const CoursesListPage = (props: Props) => {
    const { data, filter, isLoading } = props.data;
    const history = useHistory();

    return (
      <AcademyDictionariesProvider>
        <TableWrap>
          <ActionRow itemMarginTop="0" firstItemNoMargin>
            <IconButton color='primary' onClick={() => history.push('/course/new')}><Add /></IconButton>
            {isLoading && <LoadingIndicator sizeVariant="s" />}

            <OccupyFreeSpace />
            <SearchField
              filter={filter.filter}
              setFilter={filter.setFilter}
              noButton
              autoFocus
              small
              />
          </ActionRow>
            <TableForFields 
                schema={CourseSchema}
                fields={[
                  ["title"],
                  ["shorttitle"],
                  ["tutors_ids"],
                ]}
                data={data}
                onRowClick={({_id}) => history.push('/course/' + _id)}
            />
      </TableWrap>
    </AcademyDictionariesProvider>);
}
