import { TextField as MuiTextField, FormControl as MuiFormControl } from "@mui/material";
import styled from '@emotion/styled'

export const TextField = styled(MuiTextField)`
    width: 100%;

    & .MuiInputBase-root {
        background-color: #F0F3F7;
    }
`;
TextField.defaultProps = { variant: "filled" };

export const FormControl = styled(MuiFormControl)`
    width: 100%;

    & .MuiInputBase-root {
        background-color: #F0F3F7 !important;
    }
`;

export const CheckboxLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;

    color: ${({theme: {palette: {text: {primary}}}}) => primary};
`;
