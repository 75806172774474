import { ReactNode, useState } from "react"

export interface MassActionData<TIn> {
  prepareAction: (actionInfo: ActionInfo<TIn>) => void;
  run: () => void;
  cancel: () => void;
  actionInfo: ActionInfo<TIn> | null;
  
  isRunning: boolean;
  isFinished: boolean;
  status: Record<number,ActionStatus>;
}

interface ActionInfo<TIn> {
  label: ReactNode;
  description?: ReactNode;
  action: (item: TIn) => Promise<any>;
  items: TIn[];
}

export interface ActionStatus {
  status: "success" | "error" | "running";
  message_id?: string;
  message?: string;
}

export const useMassAction = <TIn>(): MassActionData<TIn> => {
  const [actionInfo, setActionInfo] = useState<ActionInfo<TIn> | null>(null);
  const [status, setStatus] = useState<Record<number,ActionStatus>>({});
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const prepareAction = (actionInfo: ActionInfo<TIn>) => {
    setActionInfo(actionInfo);
    setIsFinished(false);
    setIsRunning(false);
  }

  const cancel = () => {
    setActionInfo(null);
    setIsFinished(false);
    setStatus({});
  };

  const executeForItem = async (action: ActionInfo<TIn>["action"], idx: number, item: TIn) => {
    setStatus(s => ({ ...s, [idx]: { status: "running" } }));
    try {
      await action(item);
      setStatus(s => ({ ...s, [idx]: { status: "success" } }));
    } catch(e) {
      const errorCode = (e as any)?.error_code || (e as any)?.response?.data?.error_code;
      setStatus(s => ({ ...s, [idx]: { status: "error", message_id: errorCode, message: (e as any)?.message } }));
    }
  }

  const run = async () => {
    setStatus({});
    setIsRunning(true);
    if(actionInfo?.action && actionInfo?.items && actionInfo?.items.length) {
      for (let i = 0; i < actionInfo.items.length; i++) {
        const item = actionInfo.items[i];
        await executeForItem(actionInfo.action, i, item);
      }
    }
    setIsRunning(false);
    setIsFinished(true);
  }

  return {
    prepareAction,
    run,
    cancel,
    actionInfo,
    
    isRunning,
    isFinished,
    status,
  }
}
