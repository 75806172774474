import {useFetch} from "../../api/useFetch";
import {Course, FileData} from "./types";
import {emptyCourse} from "../CourseManagement/emptyObjects";
import {FetchTypes} from "../../toolympus/api/core";

export const useAboutCourse = (id: string) => {
    const courseFetch = useFetch<Course>(emptyCourse(), {url: '/course/' + id});
    const filesFetch = useFetch<FileData[]>([], {url: '/course/' + id + '/file'});

    return {
        ...courseFetch,
        files: {
            ...filesFetch
        }
    };
}

export const useMyCourse = () => {
    const {data, loading, reload} = useFetch<Course>(emptyCourse(), {url: '/tutor/course'});
    const filesFetch = useFetch<FileData[]>([], {url: '/course/' + data._id + '/file', depsOfEffect: [data._id]}, !!data._id);

    const actionsFetch = useFetch(null, {}, false);

    return {
        data,
        loading: loading,
        files: {
            ...filesFetch
        },
        api: {
            save: ({description}: Course) => {
                actionsFetch
                    .request({body: {description}, method: FetchTypes.PUT, url: '/tutor/course'})
                    .then(reload)
            },
            upload: (file: File) => {
                const body = new FormData();
                body.append('file', file);

                actionsFetch
                    .request({body, url: '/course/' + data._id + '/file', method: FetchTypes.POST})
                    .then(filesFetch.reload);
            },
            delete: (id: string) => {
                actionsFetch
                    .request({url: '/course/' + data._id + '/file/' + id, method: FetchTypes.DELETE})
                    .then(filesFetch.reload);
            }
        }
    }
}