import React from 'react';
import { useParams } from 'react-router-dom';
import { TutorEditPage } from './presentation/TutorEditPage';
import { User } from './types';
import { useTutorEdit } from './useTutorEdit';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';

export const TutorEditContainer = () => {
    const { id } = useParams<{id: string}>();
    const { data: users  } = useLoadedData<User[]>("/api/user", []);
    const data = useTutorEdit(id);

    return <TutorEditPage data={data} users={users} id={id} />
}

