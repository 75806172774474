import { useEffect, useState } from 'react';
import { apiFetch, FetchTypes } from '../../toolympus/api/core';
import { Schema } from '../../toolympus/hooks/useSchema';

export const useSchemaUISettings = (apiPath: string) => {
    const [data, setData] = useState<Schema>({} as Schema);
    const [isLoading, setLoading] = useState(false);
    const [isSchemaNotAvailable, setSchemaNotAvailable] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiFetch<Schema>(apiPath, FetchTypes.GET).then(r => {
            setData(r);
            setLoading(false);
        }).catch(err => {
            setLoading(false);            
            setData({});
            setSchemaNotAvailable(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return { schema: data, isLoading, isSchemaNotAvailable };
}
