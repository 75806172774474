import styled from '@emotion/styled'

export const PageContainer = styled.div`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    
    flex-flow: row wrap;
`;

export const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    flex-flow: row wrap;
    height: max-content;

    width: 100%;
`;

export const Block = styled.div<{ col: number | string }>`
    width: ${(props) => (+props.col) * 100 / 5}%;
    padding: 10px;
`;

export const SelectUser = styled.div`
    grid-column: 1/3;
`;
