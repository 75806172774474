import { Link } from 'react-router-dom';
import styled from '@emotion/styled'
import {media} from "../../frame";
import { scrollStyle } from '../../../toolympus/components/primitives';

const primaryColor = (p: any) => p.theme.palette.primary.main;

export const CoursesContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    max-height: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;

    /* need this for schedule scroll buttons */
    position: relative;

    ${props => scrollStyle(props.theme, { size: 5 })}

    & .MuiTabs-root {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;

        text-align: center;
        text-transform: uppercase;

        color: ${primaryColor};

        margin-bottom: 50px;

        & .MuiTab-root:not(.Mui-selected) {
          color: ${props => props.theme.palette.primary.main};
          opacity: 0.75;
        }
    }

    & .indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;
        height: 3px;
        bottom: 3px;

        & > span {
            max-width: 70px;
            width: 100%;
            background-color: ${primaryColor};
        }
    }
    & .MuiButtonBase-root {
        min-width: 72px;
    }
    
    & .MuiTabs-flexContainer {
        justify-content: space-between;
    }
`;

export const ToolsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .MuiButtonBase-root {
        width: max-content;
        line-height: 1.25;

        ${media.phone} {
            margin-left: 12px;
        }
    }
`;

export const Title = styled.div`
    padding: 15px 22px;

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;

    color: #202020;
    background-color:rgba(224, 224, 224, 0.6);;
    border-radius: 4px;
`;

export const Row = styled.div`
    display: grid;
    grid-column-gap: 25px;
    align-items: flex-start;

    width: 100%;
    padding: 15px 15px 15px 25px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #000000;

    border-radius: 4px;

    ${media.laptop} {
        grid-column-gap: 15px;
    }

    ${media.tablet} {
        grid-column-gap: 10px;
    }

    ${media.phone} {
        grid-column-gap: 00px;
    }
`;

export const ScheduleRow = styled(Row)`
    grid-template-columns: 125px 1fr 35px max-content;
    
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    
    ${media.tablet} {
        grid-template-columns: 125px 1fr 35px max-content;
    }

    ${media.phone} {
        grid-template-columns: max-content 1fr max-content;
        align-items: flex-end;

        & .main-title, .short-title {
            grid-column: 1/4;
        }

        & .info-btn-link {
            grid-column: 2/3;
            justify-self: end;
        }
    }
`;

export const Info = styled.div`
  & .external-link {
    margin-top: 0.5rem;
  }
`;

export const TitleSchedule = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.palette.text.primary};
`;

export const Tutor = styled.div`
    font-style: italic;
    color: ${props => props.theme.palette.text.primary};
`;

export const ShortTitle = styled.div`
    margin-top: 30px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;

    color: ${primaryColor};

    ${media.phone} {
        margin-top: 10px;
    }
`;

export const BreakRow = styled(Row)`
    grid-template-columns: 125px 1fr;

    background:rgba(217, 231, 247, 0.38);
`;

export const Time = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.6);

    ${media.phone} {
        grid-column: 1/4;
        padding: 10px 0;
    }
`;

export const ButtonLink = styled(Link)`
    width: 35px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    border: 1px solid rgba(97, 132, 173, 0.5);
    transition: 250ms;

    &:hover {
        border-color: ${primaryColor}
    }

    & img { 
        height: 15px;
        width: auto;
    }

    ${media.phone} {
        border: none;
        height: 20px;
    }
`;

export const IconLinkAction = styled.div`
    width: 35px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    border: 1px solid ${primaryColor};

    & img { 
        height: 15px;
        width: auto;
    }

    ${media.phone} {
        border: none;
        height: 20px;
    }
`;


export const DaySchedule = styled.div`
    margin-bottom: 20px;
`;

export const NoSchedules = styled.div`
    text-align: center;
    padding: 10px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: rgba(0, 0, 0, 0.6);
`;
