import React from 'react';
import { RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import styled from '@emotion/styled';
import { blockColorCss, BlockColorProps } from '../../elements/Common';
import { EditButtonDialog } from './EditButtonDialog';
import { useEditItem2 } from '../../../../api/useNewItem';
import { ButtonBlockProperties } from './types';
import { Editor, Transforms, Element } from 'slate';

export const ButtonBlockElementType = "button_block";

interface Props extends RenderElementProps {
}

const Wrapper = styled.span<{ noSizeLimit?: boolean, isSelected?: boolean } & BlockColorProps>`
    display: block;
    border-radius: 4px;
    ${props => blockColorCss(props)}
    user-select: none;
    width: auto;
    max-width: fit-content;
    padding: 0.25rem 1rem;
    box-shadow: 0 0 5px -2px ${props => props.isSelected ? props.theme.palette.primary.main : "#00000080"};
    cursor: pointer;
`;

export const ButtonBlockElement = ({ element, attributes, children }: Props) => {
    const { viewMode } = usePowerEditorContext();
    const editor = useSlateStatic();
    const isSelected = useSelected();
    const editItem = useEditItem2<ButtonBlockProperties>({
      save: (item) => {
        const itemCopy: ButtonBlockProperties = {
          label: item.label,
          url: item.url,
          action: item.action,
          _class: item._class,
          html_display_mode: item.html_display_mode,
          width: item.width,
          height: item.height,
          background_color: item.background_color,
          text_color: item.text_color
        };        
      
        Transforms.setNodes(
          editor,
          itemCopy,
          {
              match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === ButtonBlockElementType,
              voids: true
          },
        )
        return Promise.resolve(element);
      }
    })

    const { width, height, label } = element as any;
    
    return (
        <Wrapper
            noSizeLimit={!!width || !!height}
            isSelected={isSelected}
            backgroundColor={(element as ButtonBlockProperties).background_color}
            textColor={(element as ButtonBlockProperties).text_color}
            {...attributes}
            onClick={viewMode ? undefined : e => editItem.startEditing({ ...element })}>
            {children}
            {label || "..."}

            {editItem.isEditing && editItem.item &&
              <EditButtonDialog
                isOpen={editItem.isEditing}
                close={() => editItem.cancel()}
                open={() => {}}
                item={editItem.item as ButtonBlockProperties}
                update={c => editItem.update(c)}
                updateButtonSettings={updated => editItem.save(updated)}
                />}
        </Wrapper>);
}
