import React, {useState} from 'react';
import styled from '@emotion/styled'
import {TextField} from '@mui/material';
import {SignupMode, useLoginForm, useResetPassword, useRestorePassword, useSignup} from '../../toolympus/hooks/auth';
import {
    BodyBg, ExpandContainer,
    FormTitle,
    LoginContainer,
    LogoImg,
    MainBox,
    MainForm, OpenLoginButton, Secondary,
    SubTitle,
    Title
} from "./LoginForm.style";

import {Button, Link} from "../common/Button";
import { useSnackbar } from 'notistack';
import { PartnersCarousel } from './PartnersCarousel';
import { useHistory } from 'react-router-dom';
import { usePage } from '../../toolympus/components/Pages';
import { useReturnUrl } from '../../toolympus/hooks/auth/useReturnUrl';

export const SignupForm = () => {
    const { enqueueSnackbar } = useSnackbar();
    const data = useSignup({
        signupApiPath: '/api/signup-invite',
        redirectPath: '/my-profile',
        mode: SignupMode.Invite,
        loginOnSuccess: true,
    });

    const send = () => {
        if(data.credentials.password !== data.credentials.passwordRepeat) {
            enqueueSnackbar(
                'Passwords do not match', 
                { variant: 'error', autoHideDuration: 10000 }
            );
            return;
        }
        data.send()
            .catch(e => {
                enqueueSnackbar(
                    'The password should be 8 characters or longer and should contain all of: lowercase and uppercase characters and digits.', 
                    { variant: 'error', autoHideDuration: 10000 }
                );
            })
    };

    return <Form 
        data={{...data, send}} 
        login
        password
        passwordRepeat
        submitLabel='Sign up'
        hint='The password should be 8 characters or longer and should contain all of: lowercase and uppercase characters and digits. This is to make it more secure - not to make things harder for you'
    />
}

export const LoginForm = () => {
    const returnUrl = useReturnUrl();
    const login = useLoginForm('/api/login', returnUrl.fromParam && returnUrl.fromParam !== '/' ? returnUrl.fromParam : '/my-profile');
    const { data: credentials, update, send } = login;

    return <Form 
        data={{credentials, update, send}} 
        submitLabel="Sign in"
        hint={<>In case you forget your password, you may reset it using <Link to="reset-password">this link</Link></>}
        login
        password
    />
}

export const ResetPassword = () => {
    const { email, updateEmail, restore } = useResetPassword("/api/restore-access/request");

    const { enqueueSnackbar } = useSnackbar();

    const send = () => {
        restore()
            .catch(e => {
                enqueueSnackbar(
                    'Email not found', 
                    { variant: 'error', autoHideDuration: 1000 }
                );
            })
            .then(() => {
                enqueueSnackbar(
                    'A password reset link has been sent to you. Please check your email.',
                    { variant: 'success', autoHideDuration: 3000 }
                )
            });
    };

    return <Form 
        data={{credentials: {email}, update: ({email}) => updateEmail(email), send}} 
        submitLabel="Reset password"
        email
    />
}

export const RestorePassword = () => {
    const { data: credentials, update, send } = useRestorePassword("/api/restore-access/reset");
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const sendWrapp = () => {
        const { password, passwordRepeat } = credentials;

        if(password !== passwordRepeat) {
            enqueueSnackbar(
                'Passwords do not match', 
                { variant: 'error', autoHideDuration: 1000 }
            );
            return;
        }

        send()
            .catch(e => {
                enqueueSnackbar(
                    'The password should be 8 characters or longer and should contain all of: lowercase and uppercase characters and digits.', 
                    { variant: 'error', autoHideDuration: 10000 }
                );
            })
            .then(() => {
                enqueueSnackbar(
                    'The password has been reset. Please use it to log in again',
                    { variant: 'success', autoHideDuration: 2000 }
                )
                setTimeout(() => history.replace('/login'));
            });
    };

    return <Form 
        data={{credentials, update, send: sendWrapp}} 
        submitLabel="Restore password"
        login
        password
        passwordRepeat
    />
}

interface Props {
    data: {
        credentials: any;
        update: (changes: any) => void;
        send: () => void;
        isSaving?: boolean;
    }
    login?: boolean;
    email?: boolean
    password?: boolean;
    passwordRepeat?: boolean;
    submitLabel: string;
    hint?: string | JSX.Element
}

const TextFieldView = styled(TextField)`
    margin-bottom: 0.5rem;
`;

interface HomePageTexts {
    caption?: string;
    title?: string;
    dates?: string;
}

const DefaultTexts: HomePageTexts = {

}

const useHomePageTexts = (): HomePageTexts => {
    const { page, isLoading } = usePage('/api/pages', 'home');

    const result = { ...DefaultTexts };

    if(!isLoading && page) {
        result.caption = page.components.find(c => c.subtype_text === "caption")?.content_text || "";
        result.title = page.components.find(c => c.subtype_text === "title")?.content_text || "";
        result.dates = page.components.find(c => c.subtype_text === "dates")?.content_text || "";
    }

    return result;
}

const Form = ({data: {credentials, update: onChangeCredentials, send}, login, email, password, passwordRepeat, submitLabel, hint}: Props) => {
    const [openLogin, setOpenLogin] = useState(false);

    const cols = [email, login, password, passwordRepeat].filter(value => !!value).length

    const texts = useHomePageTexts();

    return <LoginContainer>
        <BodyBg />

        <MainBox>
            <div>
                <LogoImg />
            </div>
            <ExpandContainer open={!openLogin}>
                <SubTitle>{texts.caption}</SubTitle>
                <Title>{texts.title}</Title>
                <SubTitle>{texts.dates}</SubTitle>
                <OpenLoginButton variant='contained' color='primary' onClick={() => setOpenLogin(!openLogin)}>Please login to your account</OpenLoginButton>
            </ExpandContainer>

            <ExpandContainer open={openLogin}>
                {!passwordRepeat && <FormTitle>Please log in to your account</FormTitle>}
                {passwordRepeat && <FormTitle>Please set a password for your account.<br/>In case you have already done this, please use the <Link to="/login">login page</Link>.</FormTitle>}
                <form onSubmit={e => { e.preventDefault(); send(); }}>
                    <MainForm cols={cols}>
                        {login && <TextFieldView
                            label="E-mail*"
                            name='email'
                            variant='filled'
                            value={credentials.login}
                            onChange={e => onChangeCredentials({ ...credentials, login: e.target.value || ''})} />}
                        {email && <TextFieldView
                            label="Email*"
                            name='email'
                            type='email'
                            variant='filled'
                            value={credentials.email}
                            onChange={e => onChangeCredentials({ ...credentials, email: e.target.value || ''})} />}
                        {password && <TextFieldView
                            label="Password*"
                            name='password'
                            type='password'
                            variant='filled'
                            value={credentials.password}
                            onChange={e => onChangeCredentials({ ...credentials, password: e.target.value || ''})} />}
                        {passwordRepeat &&
                            <TextFieldView
                                label="Password repeat*"
                                name='passwordRepeat'
                                type='password'
                                variant='filled'
                                value={credentials.passwordRepeat}
                                onChange={e => onChangeCredentials({ ...credentials, passwordRepeat: e.target.value || ''})} />
                        }
                        <Button type='submit' variant='contained' color='primary'>{submitLabel}</Button>
                    </MainForm>
                    <Secondary>
                        {hint}
                    </Secondary>
                </form>
            </ExpandContainer>
        </MainBox>

        <PartnersCarousel />
    </LoginContainer>
};

