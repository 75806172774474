import styled from '@emotion/styled'
import { BorderBox } from '../BorderBox';
import {media} from "../../frame";

export const ContainerContent = styled.div`
    width: 100%;
    height: 100vh;
    padding-right: 25px;
    padding-bottom: 50px;
    overflow-y: auto;

    ${media.laptop} {
        padding-bottom: 25px;
    }

    ${media.tablet} {
        display: grid;
        grid-template-rows: 1fr max-content;
        gap: 10px;
    }

    ${media.phone} {
        padding: 0px 10px 10px;
        grid-template-rows: 100vh max-content;
    }
`;

export const Content = styled(BorderBox)`
    width: 100%;
    height: 100%;
    padding: 45px 10px 45px 45px;
    overflow-y: hidden;

    ${media.tablet} {
        padding: 15px;
    }
`;
Content.defaultProps = { bottom: true, vertic: true };

export const ContentBody = styled.div`
    padding-top: 10px;
    padding-right: 10px;

    height: 95%;
    overflow-y: auto;

    ${media.tablet} {
        padding: 15px 0;
    }
`;
