import React from "react";
import { School, LibraryBooks, Settings, MoveToInboxOutlined, AssignmentIndOutlined, InfoOutlined, PrintOutlined, HailOutlined } from "@mui/icons-material";
import { MyProfileContainer } from "./components/MyProfile/MyProfileContainer";
import { Roles } from "./components/UserManagement/UserManagementContainer";
import { CoursesListContainer } from "./components/CourseManagement/CoursesListContainer";
import { CourseContainer } from "./components/CourseManagement/CourseContainer";
import { TutorsListContainer } from "./components/TutorsEdit/TutorsListContainer";
import { TutorEditContainer } from "./components/TutorsEdit/TutorEditContainer";
import { CoursesContainer } from "./components/Courses/CoursesContainer";
import { AboutCourseContainer } from "./components/AboutCourse/AboutCourseContainer";
import { MyCourseContainer } from "./components/AboutCourse/MyCourseContainer";
import { ParticipantsContainer } from "./components/Participants/ParticipantsContainer";
import { OneParticipantContainer } from "./components/Participants/OneParticipantContainer";
import { MessagesContainer } from "./components/Messages/MessagesContainer";
import { LectureRoomContainer } from "./components/LectureRoom/LectureRoomContainer";
import MyProfileSrc from './menu-icons/MyProfile.svg';
import CoursesSrc from './menu-icons/Courses.svg';
import ContactOrganizerSrc from './menu-icons/ContactOrganizer.svg';
import LectureRoomSrc from './menu-icons/LectureRoom.svg';
import ParcipantsSrc from './menu-icons/Parcipants.svg';
import { ShowMessaging } from "./features";
import { MenuMessages } from "./components/Messages/presentation/MenuMessages";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { ArchivesManagement } from "./components/Admin/ArchivesManagement";
import { SettingsPage } from './components/settings/SettingsPage';
import { AdminApplications, CommitteeList } from './components/Applications';
import { Documentation } from "./components/Admin/Documentation";
import { OrganizersPage } from "./components/Participants/presentation/OrganizersPage";
import { CMS } from "./CMS";
import { StudentsManagementList } from "./components/Admin/StudentsManagement";

const adminOrOrganizer = (roles: string[]) => roles.includes('admin') || roles.includes('organizer');

export const InnerRoutes = [
    { path: '/participants/:id', title: 'Academy Participants', hidden: true, component: OneParticipantContainer },
    {
        path: '/tutor/:id', hidden: true, component: TutorEditContainer,
        resolveHidden: adminOrOrganizer
    },
    { 
        path: '/public/courses/:id', title: 'About course', component: AboutCourseContainer, hidden: true,
        match: (path: string) => /^\/public\/courses\/..*/.test(path)
    },
    {
        path: '/course/:id', hidden: true, component: CourseContainer,
        resolveHidden: adminOrOrganizer,
        title: "Course",
        match: (path: string) => { console.log("P", path); return path.startsWith("/course/")},
    },
    { path: '/public/message/:id', hidden: true, component: MessagesContainer },
    {
        path: '/committee-list', title: 'Applications', icon: <AssignmentIndOutlined htmlColor="#9B9B9B" />, component: CommitteeList,
        resolveHidden: (roles: string[]) => roles.includes('committee'),
    },
    {
        path: '/admin/applications', title: "Applications", icon: <AssignmentIndOutlined htmlColor="#9B9B9B" />, component: AdminApplications,
        resolveHidden: adminOrOrganizer,
    },
    { path: '/my-profile', title: 'My Profile', icon: <img alt="my-profile" src={MyProfileSrc} />, component: MyProfileContainer },
    { path: '/tutorcourse', title: 'My Course', icon: <img alt="courses" src={CoursesSrc} />, component: MyCourseContainer, resolveHidden: (roles: string[]) => roles.includes('tutor') },
    { path: '/lecture', title: 'Lecture Room', icon: <img alt="lecture" src={LectureRoomSrc} />, component: LectureRoomContainer },
    { path: '/public/courses/', title: 'Courses', icon: <img alt="courses" src={CoursesSrc} />, component: CoursesContainer },
    { path: '/participants', title: 'Participants', icon: <img alt="parcipants" src={ParcipantsSrc} />, component: ParticipantsContainer },
    { path: '/public/messages/', title: "Messages", menuComponent: MenuMessages, component: MessagesContainer, hidden: !ShowMessaging },
    { path: '/organizers', title: 'Organising Committee', icon: <img alt="organizers" src={ContactOrganizerSrc} />, component: OrganizersPage },

    {
        path: '/course', title: 'Course management', icon: <LibraryBooks htmlColor="#9B9B9B" />, component: CoursesListContainer,
        resolveHidden: adminOrOrganizer,
        match: (path: string) => path === "/course",
    },
    {
        path: '/tutors', title: 'Tutors', icon: <School htmlColor="#9B9B9B" />, component: TutorsListContainer,
        resolveHidden: adminOrOrganizer
    },
    {
        path: '/students', title: 'Students', icon: <HailOutlined htmlColor="#9B9B9B" />, component: StudentsManagementList,
        resolveHidden: adminOrOrganizer
    },

    {
        path: '/system',
        title: 'System',
        icon: <Settings htmlColor="#9B9B9B" />,
        component: () => (
            <SystemSubmenu
                users={{ userRoles: Roles, allowInvites: true, allowPaswordChange: true }}
                pages={[{ apiPath: "/api/pages", key: "pages", label: "Pages" }]}
                emails={{ apiPath: "/emails" }}
                queryConsole={{ apiPath: "/api/query-console" }}
                notificationTasks={{ apiPath: "/api/notifications/task", emailsApiPath: "/api/emails", emailsPagePath: "/system/emails" }}
                noCloseButton
                extraItems={[
                    {
                        key: "archives",
                        items: [{
                            component: () => <ArchivesManagement />,
                            key: "archives",
                            label: "",
                        }],
                        label: "Archives",
                        icon: <MoveToInboxOutlined />,
                    },
                    {
                        key: "settings",
                        items: [{ 
                            component: () => <SettingsPage />,
                            key: "settings", 
                            label:  "", 
                        }],
                        icon: <Settings />,
                        label: "Settings",
                    },
                    {
                        key: "documentation",
                        label: "Documentation",
                        icon: <InfoOutlined />,
                        items: [{ key: "documentation", label: "", component: Documentation }]
                    },
                    {
                      key: "cms",
                      label: "CMS",
                      icon: <PrintOutlined />,
                      items: [{ key: "documentation", label: "", component: CMS }]
                    },
                ]}
            />),
        resolveHidden: adminOrOrganizer
    },

    { path: '/', title: 'My Profile', hidden: true, component: MyProfileContainer },
    
];

