import { Badge, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect } from 'react';
import styled from '@emotion/styled'
import { media } from '../../frame';
import MessagingSrc from './Messaging.svg';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { createProvided } from '../../../toolympus/components/primitives/createProvided';
import { IconImgLink } from '../../Header/Header.style';

export const Text = styled.text`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const IconContainer = styled.div`
    position: relative;

    & .Chip {
        display: none;

        ${media.tablet} {
            display: flex;
        }

        ${media.phone} {
            display: none;
        }
    }
`;

export const Chip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 23px;
    height: 23px;

    background: ${props => props.theme.palette.primary.main};
    color: white;

    border-radius: 4px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;

    ${media.tablet} {
        display: none;

        width: 20px;
        height: 20px;
        font-size: 11px;
        position: absolute;
        top: -10px;
        right: -10px;
    }

    ${media.phone} {
        display: flex;

        width: 23px;
        height: 23px;
        font-size: 12px;
        position: static;
    }
`;

const useLoadUnreadMessagesCount = () => {
  const { data, reload } = useLoadedData<{ count: number }>("/api/chat/unread", { count: 0 });
  // const {data: {count}, reload} = useFetch<{count: number }>({count: 0}, {url: '/chat/unread'});

  useEffect(() => {
      const interval = setInterval(reload, 10000);
      return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data.count;
}

export const { useValue: useUnreadMessagesCount, Provider: UnreadMessagesCountProvider } = createProvided(useLoadUnreadMessagesCount, 0)

export const MenuMessages = ({selected, onClick}: {selected: boolean, onClick: () => void}) => {
    const count = useUnreadMessagesCount();

    return <ListItem style={{cursor: 'pointer'}} onClick={onClick} selected={selected}>
        <ListItemIcon>
            <IconContainer>
                <img alt="messaging" src={MessagingSrc} />
            </IconContainer>
        </ListItemIcon>
        <ListItemText>
            <Text>
                Messages
                {count > 0 && <Chip>{count}</Chip>}
            </Text>
        </ListItemText>
    </ListItem>
}

export const UnreadMessagesIconButton = () => {
  const count = useUnreadMessagesCount();

  if(!count) {
    return null;
  }

  return (
    <IconImgLink to="/public/messages/">
      <Badge badgeContent={count} color="primary">
        <img alt="messaging" src={MessagingSrc} />
      </Badge>
    </IconImgLink>
  )
}