import React, { useEffect, useState } from 'react';
import { AttributeConfig } from '../useRecordTypeConfig';
import { useCMSRecordContext } from './CMSRecordContext';
import { useCMSSettingsContext } from '../Configuration/CMSSettingsContext';
import { Autocomplete, TextField } from '@mui/material';

interface Props {
  data: any;
  onChange: (origina: any, changes: any) => void;
  config: AttributeConfig;
  multiple?: boolean;
}


export const TagSelectorMultiple = (props: Props) => {
  const { settings, ensureLoaded, updateSettings } = useCMSSettingsContext();
  const { lang } = useCMSRecordContext();
  const [updatedTags,setUpdatedTags] = useState<string[]>([]);

  useEffect(() => {
    if(props.config.tag_source) {
      ensureLoaded();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config.tag_source]);

  
  const code = props.config.code;
  const optionsKey = lang ? `${props.config.tag_source}_${lang}` : props.config.tag_source;

  useEffect(() => {
    if(updatedTags.length && settings && optionsKey) {
      const optionsExisting = settings[optionsKey] as string[] || [];
      const optionsToAdd: string[] = [];
      updatedTags.forEach(o => {
        if(!optionsExisting.includes(o)) {
          optionsToAdd.push(o);
        }
      });

      if(optionsToAdd.length) {
        updateSettings({ [optionsKey]: [...optionsExisting, ...optionsToAdd ]});
      }
      setUpdatedTags([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedTags]);

  if(!optionsKey) {
    return null;
  }

  const options = settings[optionsKey] || [];
  const data = props.data || {}

  return (
    <Autocomplete
      freeSolo
      multiple
      value={data[code] || []}
      onChange={(e, newValue) => {
        props.onChange(props.data, { [code]: newValue || null });
        setUpdatedTags(newValue || []);
      }}
      onInputChange={(e, newValue) => {
        if(e) {
          // data.update({ source_title: newValue || "" });
      }}}
      autoHighlight
      fullWidth
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          margin="none"
          label={props.config.label}
          />)}
      options={options}
      />
  );
}


export const TagSelectorSingle = (props: Props) => {
  const { settings, ensureLoaded, updateSettings } = useCMSSettingsContext();
  const { lang } = useCMSRecordContext();
  const [updatedTag,setUpdatedTag] = useState<string>("");

  useEffect(() => {
    if(props.config.tag_source) {
      ensureLoaded();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config.tag_source]);

  
  const code = props.config.code;
  const optionsKey = lang ? `${props.config.tag_source}_${lang}` : props.config.tag_source;

  useEffect(() => {
    const updated = updatedTag?.trim();
    if(updated && settings && optionsKey) {
      const optionsExisting = settings[optionsKey] as string[] || [];
      if(!optionsExisting.includes(updated)) {
        updateSettings({ [optionsKey]: [...optionsExisting, updated ]});
      }
      setUpdatedTag("");

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedTag]);

  if(!optionsKey) {
    return null;
  }

  const options = settings[optionsKey] || [];
  const data = props.data || {}

  return (
    <Autocomplete
      freeSolo
      value={data[code] || null}
      onChange={(e, newValue) => {
        props.onChange(props.data, { [code]: newValue || null });
        setUpdatedTag(newValue || "");
      }}
      onInputChange={(e, newValue) => {
        if(e) {
          // data.update({ source_title: newValue || "" });
      }}}
      autoHighlight
      fullWidth
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          margin="none"
          label={props.config.label}
          />)}
      options={options}
      />
  );
}


export const TagSelector = (props: Props) => {
  if(props.config.fieldtype === "tag_multiple") {
    return <TagSelectorMultiple {...props} />
  } else {
    return <TagSelectorSingle {...props} />
  }
}
