import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PartnersData, PartnerWithGroup } from '.';

export interface PartnersCarouselProps {
  data: PartnersData;
  wrapper?: React.ComponentType<any>;
  hideSectionTitles?: boolean;
  columns?: number;
  scrollDelay?: number;
}

const groupPartnersIntoSections = (columns: number, partners: PartnerWithGroup[]): PartnerWithGroup[][] => {
  return partners.reduce<PartnerWithGroup[][]>((r, p) => {
      const [lastGroup] = r;
      if (!lastGroup) {
        return [[p]];
      } else {
        const resultW = lastGroup.reduce((wsum, px) => wsum + px.width || 1, 0) + p.width;
        return resultW > columns
          ? [[p], ...r]
          : [[...lastGroup, p], ...r.slice(1)];
      }
    }, [])
    .reverse();
};

type GroupTitleT = Pick<PartnerWithGroup, 'group_title' | 'width'>;

const getPartnerGroupTitlesForSection = (columns: number, partners: PartnerWithGroup[]): GroupTitleT[] => {
  const groups = partners.reduce<GroupTitleT[]>((r, p) => {
    const [last] = r;
    if (!last) {
      return [{ ...p }];
    } else {
      return last.group_title === p.group_title
        ? [{ ...last, width: last.width + p.width }, ...r.slice(1)]
        : [{ ...p }, ...r];
    }
  }, []);

  const totalWidth = groups.reduce((r, g) => r + g.width, 0);

  if (groups.length && totalWidth < columns) {
    groups[0].width = groups[0].width + columns - totalWidth;
  }

  return groups.reverse();
};

const GroupTitle = styled.span`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 25px;
  padding-bottom: 5px;
  box-sizing: border-box;

  border-bottom: 1px solid rgba(155, 155, 155, 0.4);
`;

const Partner = styled.a`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  & > img {
    max-width: 100%;
    max-height: 100%;
    object-position: center;
  }
`;

const PartnerSection = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  padding: 30px 50px;
  column-gap: 50px;
  row-gap: 20px;
  box-sizing: border-box;
  transition: right ease 0.6s;
`;

export const PartnersCarouselWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 190px;
  box-sizing: border-box;
`;

export const PartnersCarousel = (props: PartnersCarouselProps) => {
  const columns = props.columns || 4;
  const sections = groupPartnersIntoSections(columns, props.data.flat);
  const sectionsN = sections.length;

  const [activeSectionIdx, setActiveSectionIdx] = useState(0);
  useEffect(() => {
    const timerId = setInterval(() => {
      setActiveSectionIdx((current) => current + 1 >= sectionsN ? 0 : current + 1);
    }, props.scrollDelay || 5000);

    return () => clearInterval(timerId);
  }, [props.columns, props.scrollDelay, sectionsN]);

  const Wrapper = props.wrapper || PartnersCarouselWrapper;

  return (
    <Wrapper>
      {sections.map((section, sectionIdx) => (
        <PartnerSection
          className='section'
          style={{
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            right: sectionIdx === activeSectionIdx ? '0%' : '1000%',
            visibility: sectionIdx === activeSectionIdx ? 'visible' : 'hidden',

            position: sectionIdx === activeSectionIdx ? 'relative' : 'absolute',
            padding: sectionIdx === activeSectionIdx ? undefined : 0,
            margin: sectionIdx === activeSectionIdx ? undefined : 0,
          }}
        >
          {!props.hideSectionTitles &&
            getPartnerGroupTitlesForSection(columns, section).map(
              (groupTitle) => (
                <GroupTitle
                  className='group-title'
                  style={{
                    gridColumnStart: `span ${Math.min(groupTitle.width || 1, columns)}`,
                  }}
                >
                  {groupTitle.group_title}
                </GroupTitle>
              )
            )}
          {section.map((partner) => (
            <Partner
              className='partner'
              href={partner.url}
              target='_blank'
              rel='noreferrer noopener nofollow'
              style={{
                gridColumnStart: `span ${Math.min(partner.width || 1, columns)}`,
              }}
            >
              <img src={partner.img_url} alt={partner.title} />
            </Partner>
          ))}
        </PartnerSection>
      ))}
    </Wrapper>
  );
};
