import { Link } from 'react-router-dom';
import styled from '@emotion/styled'
import { media } from '../../../components/frame/media';
import { BorderBox } from '../../common/BorderBox';

const primaryText = (p: any) => p.theme.palette.text.primary;
const primaryColor = (p: any) => p.theme.palette.primary.main;
const secondaryText = (p: any) => p.theme.palette.text.secondary;

const tableToOneColumn = `
   ${media.laptop} {
      grid-column: auto;
      grid-row: auto;
   }
`;

export const CourseContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 270px;
    row-gap: 30px;
    column-gap: 60px;

    padding: 10px 0;
  
    ${media.laptop} {
        grid-template-columns: 1fr !important;
        padding: 0;
    }
`;

export const MainTitle = styled.div`
    grid-column: 1/3;

    ${tableToOneColumn};

    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.24px;

    color: ${primaryText};
`;

export const Kind = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 135.8%;

    letter-spacing: -0.24px;

    color: ${primaryText};
`;

export const Title = styled(BorderBox)`
    font-style: normal;
    font-weight: 500;
    font-size: 21px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    color: ${primaryText};

    padding: 0 0 8px;
    margin-bottom: 10px;
`;
Title.defaultProps = { bottom: true };

export const AboutTutor = styled.div`
    display: grid;
    grid-template-columns: 90px 1fr 35px 35px;
    gap: 20px;

    align-items: center;
    justify-content: space-between;

    padding: 16px 0;
  
    ${media.tablet} {
        grid-template-columns: 90px 1fr;
        gap: 10px;
    }
`;

export const LinkBack = styled(Link)`
    grid-column: 1/2;

    ${tableToOneColumn};

    font-style: normal;
    font-weight: bold;
    font-size: 14px;

    display: flex;
    align-items: center;
    gap: 6px;

    color: ${secondaryText};
    text-decoration: none;

    & .MuiSvgIcon-root {
        color: ${secondaryText};
    }
`;  

export const TutorAvatar = styled.img`
    width: 90px;
    height: 90px;
    object-fit: cover;
    object-position: center top;
    border-radius: 5px;
    filter: grayscale(1);
`;
TutorAvatar.defaultProps = {alt: 'avatar'};


export const TutorInfo = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    display: inline;

    color:${primaryText};
`;

export const TutorName = styled.span`
    font-weight: 500;
`;

export const ScheduleItem = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 281.8%;

    letter-spacing: -0.24px;

    color: ${secondaryText};
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  line-height: 1.1;
  grid-column: 1 / span 2;
  font-weight: 400;

  gap: 6px;

  & .MuiSvgIcon-root {
    font-size: 1.2rem;
    color: ${props => props.theme.palette.primary.main};
  }

  & > span {
    border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};
  }
`;
ExternalLink.defaultProps = { target: "_blank", rel: "noopener noreferrer", className: "external-link" };

export const ModeInformation = styled.div`
    white-space: pre-wrap;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: center;
  
    & .MuiButtonBase-root {
        max-width: 400px;
    }
`;

export const Files = styled.div`
    max-height: 400px;
    overflow-y: auto;
    grid-column: 2/3;

    ${tableToOneColumn};
`;

export const OneFile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
        
    padding: 10px 0;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.24px;
    text-decoration-line: underline;
    
    color: ${primaryColor};
  
    & img {
        height: 20px;
        margin-right: 10px;
    }
`;

export const FileLink = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
