import React, { ComponentType } from 'react';
import { RenderElementProps } from 'slate-react';

export enum BlockTypes {
    h1 = "h1",
    h2 = "h2",
    h3 = "h3",
    h4 = "h4",
    h5 = "h5",
    h6 = "h6",

    ul = "ul",
    ol = "ol",
    li = "li",
    blockquote = "blockquote",
}

export const ParagraphElementType = "paragraph";

export const ElementBackgroundColorField = "bg_color";

export const ElementBackgroundColors: Record<string, string> = {
  red: "#cc000028",
  yellow: "#ffcb0028",
  orange: "#ff7b0028",
  blue: "#006dcc28",
  green: "#00cc3028",
  purple: "#c900cc28",
  grey: "#00000014",
}

export type CustomBlockTypes = Record<string, React.ComponentType<RenderElementProps>>;

export interface BlockElementWithCustomBlocksProps extends RenderElementProps {
    customBlocks?: CustomBlockTypes;
    customRenderElement?: ComponentType<RenderElementProps>;
    editorHighlight?: boolean;
}

export const BlockElement = (props: BlockElementWithCustomBlocksProps) => {
    const { attributes, children, element } = props;
    const style: React.CSSProperties = { textAlign: element.align };
    if((element as any)[ElementBackgroundColorField]) {
      style.backgroundColor = ElementBackgroundColors[(element as any)[ElementBackgroundColorField]];
    }
    
    const CustomBlock = (props.customBlocks || {})[element.type];
    if(CustomBlock) {
        return <CustomBlock {...props} />
    }

    const CustomRenderElement = props.customRenderElement;
    if(CustomRenderElement) {
      return <CustomRenderElement {...props} />
    }

    const className = `${(attributes as any).className || ""} ${props.editorHighlight ? "editor-highlight" : ""}`

    switch(element.type) {
        case ParagraphElementType:
            return <p style={style} {...attributes} className={className}>{children}</p>
        default:
            const Wrapper = element.type as React.ElementType | string;
            if(Wrapper)
                return (
                  <Wrapper
                    style={style}
                    {...attributes}
                    className={className}>
                      {children}
                  </Wrapper>);
            else
                return <></>;
    }

}
