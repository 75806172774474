import React from 'react';
import { useParams } from 'react-router-dom';
import { CMSRecord } from './toolympus/components/CMS';
import { useLoadedData } from './toolympus/hooks/useLoadedData';
import { BodyBg, LoginContainer, MainBox } from './components/Login/LoginForm.style';
import { CmsMediaLibProvider, DocumentationEditorWrapped } from './CMS';
import { LoadingIndicator } from './toolympus/components/primitives';

export const usePublicDocumentationPage = (slug: string) => {
  return useLoadedData<CMSRecord>(`/api/cms/public/public_docs/${slug}`, { title: "", _id: -1 } as CMSRecord);
}

export const PublicDocumentationPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const page = usePublicDocumentationPage(slug);

  return (
    <CmsMediaLibProvider noLoad>
      <LoginContainer>
          <BodyBg />

          <MainBox>
            {page.isLoading
              ? <LoadingIndicator />
              : <DocumentationEditorWrapped
                  update={() => {}}
                  content={page.data.content}
                  viewMode
                  key={page.data._id}
                  />}
          </MainBox>
      </LoginContainer>
    </CmsMediaLibProvider>
  )
  
}