import React from 'react';
import { ViewDay } from "@mui/icons-material";
import { CustomElement } from "../../../../../slate";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { createWithVoidInjector } from "../common";
import { ButtonBlockElement, ButtonBlockElementType } from './ButtonBlockElement';

const withButton = createWithVoidInjector(ButtonBlockElementType);

export const ButtonBlockPlugin: EditorPlugin = {
    key: "button-block",
    inject: withButton,
    commands: [{
        name: "insert-button-block",
        menu: { section: "insert-item", label: "Button", label_id: "powerdoc.plugins.button.title", icon: <ViewDay /> },
        invoke: editor => {
            editor.insertNode({
                type: ButtonBlockElementType,
                children: [{ text: "", }],
            } as CustomElement);
        }
    }],
    customBlocks: { [ButtonBlockElementType]: ButtonBlockElement },
};
