import React from 'react';
import styled from '@emotion/styled';
import {BorderBox} from "../common/BorderBox";
import LogoSrc from './Logo.png';
import {media} from "../frame";
import {Button} from "../common/Button";
import { css, Global } from "@emotion/react";
import { BackgroundCssString } from '../../Background';

export const BodyBg = () => <Global styles={css`
    body, html {
      width: 100%;
      height: 100vh;
      margin: 0;
    }
    body {
      background: ${BackgroundCssString};

    }
`} />;

export const LoginContainer = styled.div`
    max-width: 1200px;
    height: 100vh;
    
    display: grid;
    grid-template-rows: 1fr max-content;
    gap: 30px;
    
    margin: 0 auto;

    ${media.laptop} {
        padding: 0 10px;
    }
`;

export const MainBox = styled(BorderBox)`
    width: 100%;
    padding: 20px 50px;
  
    display: flex;
    flex-flow: column nowrap;
  
    & .MuiFilledInput-input {
        padding: 24px 12px 5px;
    }
  
    & .MuiButton-containedPrimary {
        max-height: 48px;
    }
    
    ${media.laptop} {
        padding: 30px;
    }
    
    ${media.phone} {
        padding: 20px;
    }
`;
MainBox.defaultProps = {className: 'MainBox', vertic: true, bottom: true};

export const LogoImg = styled.img`
    width: auto;
    height: 140px;
    margin-bottom: 30px;

    ${media.laptop} {
        height: 120px;
    }
    
    ${media.phone} {
        height: 80px;
        margin-bottom: 15px;
    }
`;
LogoImg.defaultProps = {src: LogoSrc, alt: 'logo', className: 'LogoImg'};

export const ExpandContainer = styled.div<{open: boolean}>`
    ${media.phone} {
        height: ${({open}) => open ? '100%' : '0'};
        overflow-y: hidden;
        transition: 150ms;
    }
`;


export const OpenLoginButton = styled(Button)`
    display: none !important;
    
    ${media.phone} {
        display: block !important;
    }
`;

export const Title = styled.div`
    width: 100%;

    font-style: normal;
    font-weight: 500;
    font-size: 55px;
    white-space: pre-line;
    
    text-transform: uppercase;
    
    @media screen and (max-width: 1150px) {
        font-size: 40px;
    }
    
    ${media.phone} {
        font-size: 20px;
        line-height: 24px;
    }
`;

export const SubTitle = styled.div`
    width: 100%;

    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    padding: 15px 0;
    white-space: pre-line;

    ${media.laptop} {
        font-size: 25px;
    }

    ${media.phone} {
        font-size: 18px;
        line-height: 20px;
    }
`;

export const FormTitle = styled.div`
    width: 100%;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    
    padding-top: 40px;
    padding-bottom: 10px;
    
    ${media.phone} {
        padding-top: 10px;
        font-size: 16px;
    }
`;

export const MainForm = styled.div`
    width: 100%;

    display: grid;
    grid-template-columns: repeat(${((props: {cols: number}) => props.cols)}, 1fr) 165px;
    column-gap: 25px;
    row-gap: 15px;

    ${media.phone} {
        grid-template-columns: 1fr;
    }
`;

export const Secondary = styled.form`
    padding: 10px 0;
        
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #000000;
    
    ${media.phone} {
        font-size: 10px;
        line-height: 12px;
    }
`;

