import { InputLabel, FilledInput, FormControlLabel, Checkbox, FormHelperText, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "./Autocomplete";
import { CheckboxLabel, FormControl } from "./TextField";

interface Props {
    label?: JSX.Element | string | number;
    noRequired?:boolean;
    hint?: string;
    disabled?: boolean;
}

interface InputProps extends Props {
    rows?: number;
}

interface CheckboxProps extends Props {

}

interface SelectProps extends Props {}

interface AutocompleteProps extends InputProps {

}

interface UseFormProps<T> {
    onChange?: (newState: T, setState: (s: T) => void) => void;
    required? : (keyof T)[];
}

export const useForm = <T extends Object>(initialState: T, {onChange, required}: UseFormProps<T> = {}) => {
    type Field = keyof T;
    const [state, setState] = useState(initialState);
    const [valid, setValid] = useState(false);

    useEffect(() => setState(initialState), [initialState]);
    useEffect(() => {
        setValid((required || []).filter(field => !state[field]).length === 0);
    }, [required, state]);

    const getLabel = (field: Field, props: Props) => {
        return props.label || (field as string)[0].toUpperCase() + (field as string).slice(1) + (props.noRequired? "" : "*")
    }

    const change = (field: Field, value: any) => {
        setState({...state, [field]: value});
        !!onChange && onChange({...state, [field]: value}, setState);
    }

    const input = (field: Field, props: InputProps = {}) => <FormControl variant="filled">
            <InputLabel>{getLabel(field, props)}</InputLabel>
            <FilledInput 
                autoComplete="off"
                multiline={!!props.rows}
                rows={props.rows || 1}
                value={state[field]} 
                onChange={({target: {value}}) => change(field, value)} 
                disabled={props.disabled}
            />
            {props.hint && <FormHelperText>{props.hint}</FormHelperText>}
        </FormControl>

    const checkbox = (field: Field, props: CheckboxProps = {}) => {
        return <FormControlLabel
            control={<Checkbox 
                checked={!!state[field]}
                onChange={(event) => change(field, event.target.checked)}
                color="primary"
            />}
            label={<CheckboxLabel>
                {props.label || (field as string)[0].toUpperCase() + (field as string).slice(1)}
            </CheckboxLabel>} 
        />
    }

    const select = <T extends unknown>(
        field: Field, options: T[], 
        getOption: (v: T) => {value: any, label: string}, 
        props: SelectProps = {}
    ) => {
        return <FormControl variant="filled">
            <InputLabel>{getLabel(field, props)}</InputLabel>
            <Select
                value={state[field]} 
                onChange={({target: {value}}) => change(field, value)} 
                disabled={props.disabled}
            >
                {options
                    .map(getOption)
                    .map(({label, value}) => 
                        <MenuItem key={label} value={value}>{label}</MenuItem>
                    )
                }
            </Select>
            {props.hint && <FormHelperText>{props.hint}</FormHelperText>}
        </FormControl>
    }

    const autocomplete = (field: Field, options: string[], props: AutocompleteProps = {}) => {
        return <Autocomplete 
            value={state[field] as unknown as string}
            onChange={value => change(field, value)}
            label={getLabel(field ,props)}
            suggestions={options}
        />
    }

    return { 
        autocomplete,
        input, 
        checkbox, 
        select,
        valid,

        state, setState
    };
}

export const useFormAttached = <T extends Object>(data: T, update: (c: Partial<T>) => void, { required }: UseFormProps<T> = {}) => {
  type Field = keyof T;
  const [valid, setValid] = useState(false);

  useEffect(() => {
      setValid((required || []).filter(field => !data[field]).length === 0);
  }, [required, data]);

  const getLabel = (field: Field, props: Props) => {
      return props.label || (field as string)[0].toUpperCase() + (field as string).slice(1) + (props.noRequired? "" : "*")
  }

  const change = (field: Field, value: any) => {
    update({ [field]: value } as Partial<T>);
  }

  const input = (field: Field, props: InputProps = {}) => <FormControl variant="filled">
          <InputLabel>{getLabel(field, props)}</InputLabel>
          <FilledInput 
              autoComplete="off"
              multiline={!!props.rows}
              rows={props.rows || 1}
              value={data[field]} 
              onChange={({target: {value}}) => change(field, value)} 
              disabled={props.disabled}
          />
          {props.hint && <FormHelperText>{props.hint}</FormHelperText>}
      </FormControl>

  const checkbox = (field: Field, props: CheckboxProps = {}) => {
      return <FormControlLabel
          control={<Checkbox 
              checked={!!data[field]}
              onChange={(event) => change(field, event.target.checked)}
              color="primary"
          />}
          label={<CheckboxLabel>
              {props.label || (field as string)[0].toUpperCase() + (field as string).slice(1)}
          </CheckboxLabel>} 
      />
  }

  const select = <T extends unknown>(
      field: Field, options: T[], 
      getOption: (v: T) => {value: any, label: string}, 
      props: SelectProps = {}
  ) => {
      return <FormControl variant="filled">
          <InputLabel>{getLabel(field, props)}</InputLabel>
          <Select
              value={data[field]} 
              onChange={({target: {value}}) => change(field, value)} 
              disabled={props.disabled}
          >
              {options
                  .map(getOption)
                  .map(({label, value}) => 
                      <MenuItem key={label} value={value}>{label}</MenuItem>
                  )
              }
          </Select>
          {props.hint && <FormHelperText>{props.hint}</FormHelperText>}
      </FormControl>
  }

  const autocomplete = (field: Field, options: string[], props: AutocompleteProps = {}) => {
      return <Autocomplete 
          value={data[field] as unknown as string}
          onChange={value => change(field, value)}
          label={getLabel(field ,props)}
          suggestions={options}
      />
  }

  return { 
      autocomplete,
      input, 
      checkbox, 
      select,
      valid,
  };
}