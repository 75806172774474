import { apiFetch } from "../../toolympus/api/core";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Tutor } from "../CourseManagement/types";
import { ProfileData } from "../MyProfile/types";
import { useNewTutor } from "./presentation/useNewTutor";
import { useInviteParticipants } from "./useInviteTutors";

export const useTutorsList = () => {
  const data = useLoadedData<Tutor[]>("/api/participant?view=tutors", [])
  const filter = useTextFilter<Tutor>(t => `${t.lastname} ${t.firstname}`);

  const newTutor = useNewTutor();

  const inviteTutors = useInviteParticipants();

  const inviteCommittee = useInviteParticipants();
  const startInviteCommittee = () => {
    apiFetch<ProfileData[]>("/api/participant?view=advisory_commitee", "get")
      .then(committee => inviteCommittee.start(committee));
  }

  return {
      ...data,
      data: filter.filterData(data.data),
      filter,
      newTutor,
      inviteTutors,
      inviteCommittee: {
        ...inviteCommittee,
        start: startInviteCommittee,
      }
  }
}

export type TutorListData = ReturnType<typeof useTutorsList>;
