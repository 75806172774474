import { useMemo } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { ProfileData } from "../../MyProfile/types"
import { FieldType, Schema, createSelectSchema } from "../../../toolympus/hooks/useSchema";
import moment from "moment";



export interface AttendanceSlot {
  start_datetime: string;
}

export interface AttendanceTrack {
  student_id: string;
  slot_id: string;
}


export const useStudentsManagementList = () => {
  const students = useLoadedData<ProfileData[]>("/api/manage/participant?view=students", []);
  const filter = useTextFilter<ProfileData>(p => `${p.title} ${p.firstname} ${p.lastname} ${p._id}`);

  const editStudent = useEditItem2<ProfileData>({
    getApiPath: s => `/api/manage/participant/${s._id}`,
  });

  const attendanceSlots = useLoadedData<AttendanceSlot[]>("/api/attendance/slots", []);
  const attendanceTrack = useLoadedData<AttendanceTrack[]>("/api/attendance/track", []);

  const setAttendanceTrack = (studentId: string, slotId: string, attended: boolean) => {
    const newTrack = { student_id: studentId, slot_id: slotId };
    return apiFetch<{}>("/api/attendance/track", attended ? "POST" : "DELETE", newTrack)
      .then(x => {
        attendanceTrack.setData(d => attended ? [...d, newTrack] : d.filter(item => !(item.student_id === studentId && item.slot_id === slotId)));
        return x;
      })
  }

  const recalcAttendance = useEditItem2<{ mootcourt_slot?: string | null, ignore_slots?: string[] }>({
    save: (item, changes) => {
      return apiFetch<{}>("/api/attendance/calc", "post", item).then(() => { students.reload(); return item; });
    }
  });

  const recalcAttendanceSchema: Schema = useMemo(() => {
    const slotsSelectorSchema = createSelectSchema(attendanceSlots.data.map(s => ({ value: s.start_datetime, label: moment(s.start_datetime).format("DD.MM ddd HH:mm") })));
    return {
      mootcourt_slot: { ...slotsSelectorSchema, label: "Mootcourt" },
      ignore_slots: { ...slotsSelectorSchema, label: "Ignore", type: FieldType.multiselect },
    }
  }, [attendanceSlots.data]);

  return {
    students: {
      ...students,
      data: filter.filterData(students.data),
    },
    editStudent: {
      ...editStudent,
      save: (extraChanges?: Partial<ProfileData>) => {
        return editStudent.save(extraChanges).then(x => { students.reload(); return x; })
      }
    },
    filter,

    attendanceSlots,
    attendanceTrack,
    setAttendanceTrack,
    recalcAttendance,
    recalcAttendanceSchema,
  }
}

export type StudentsManagementListData = ReturnType<typeof useStudentsManagementList>;
