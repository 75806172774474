import { Schema } from "../../../toolympus/hooks/useSchema";

export const TutorSchema: Schema = {
  lastname: { label: 'Lastname', },
  firstname: { label: 'Firstname', },
  email: { label: 'Email', },
  title: { label: "Title" },
  company: { label: "Company" },
  position: { label: "Position" },
}
