import React from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, TextField } from '@mui/material';
import { Add, ArrowDownward, ArrowUpward, AssignmentReturnOutlined, CallMade, DeleteOutlined, ExpandLess, ExpandMore, FileCopyOutlined } from '@mui/icons-material';
import { RenderElementProps, useSelected } from 'slate-react';
import { BlockSelectionCss, BlockTitle, BlockWrapper, Buttons } from '../../elements/Common';
import { Tile as TileT, TilesEditData, useTiles, WithTiles } from './useTiles';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionRow, FormGrid, MoreActionsMenuButton, Tooltip } from '../../../primitives';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import isHotkey from 'is-hotkey';
import { PowerEditorBase } from '../../slate/PowerEditorBase';
import { MediaLibPicker, useMediaLibContext } from '../../../medialib';
import { TilesBlockView } from './TilesBlockView';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';

export const TilesBlockElementType = "tiles_block";


const Wrapper = styled(BlockWrapper)`
    padding: 0.5rem 1rem;
    border-top: 2px solid #eeeeee;
    border-bottom: 2px solid #eeeeee;
    ${props => BlockSelectionCss(props)}
`;

const Tile = styled(FormGrid)`
    padding: 0.5rem 0;

    &:nth-child(2n) {
        background: #cccccc18;
    }

    & > :first-child .placeholder {
        height: 150px;
    }
    & > .medialib-picker:hover {
      background: #00000080;
    }

    & > :last-child > * {
        display: block;
        transform: scale(0.75);
    }
`;
Tile.defaultProps = { columns: "150px 1fr max-content", forceEvenColumns: true, noMargin: true };

interface TileBlockSettings {
  no_title?: string;
  no_image?: string;
  no_class?: string;
  no_content?: string;
  no_link?: string;
}


const TileEditor = (props: { tile: TileT, tiles: TilesEditData, tilesSettings: TileBlockSettings }) => {
  const { tile, tiles } = props;
  const { formatMessage } = useIntl();
  const medialib = useMediaLibContext();
  const settings = usePowerEditorSettings();
  const EditorComponent = settings.EditorComponent || PowerEditorBase;

  return (
    <Tile key={tile.id} columns={props.tilesSettings?.no_image === "true" ? "1fr max-content" : "150px 1fr max-content"}>
        {props.tilesSettings?.no_image !== "true" &&
          <MediaLibPicker
            selectFile={mf => tiles.updateTile(tile.id, { image_url: mf ? medialib.getFilepath(mf) : null })}
            selectedUrl={tile.image_url}
            size="small"
            closeOnSelect
            />}

        <FormGrid columns="1fr" onKeyDown={e => { if(isHotkey("mod+Enter", e)) { tiles.insertTile() }}} noMargin>
            {props.tilesSettings?.no_title !== "true" &&
              <TextField
                key="title"
                value={tile.title || ""}
                onChange={e => tiles.updateTile(tile.id, { title: e.target.value })}
                label=""
                placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.title"})}
                autoFocus={tile.id === tiles.insertedId}
                />}
            <FormGrid columns="2fr 1fr" noMargin gap="dense">
              {props.tilesSettings?.no_link !== "true" &&
                <TextField
                  key="link"
                  value={tile.link || ""}
                  onChange={e => tiles.updateTile(tile.id, { link: e.target.value })}
                  label=""
                  placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.link"})}
                  InputProps={{
                      endAdornment: tile.link && <a href={tile.link} target="_blank" rel="noreferrer noopener"><CallMade fontSize="small" color="action" /></a>,
                  }}
                  />}

              {props.tilesSettings?.no_class !== "true" &&
                <TextField
                  key="_class"
                  value={tile._class || ""}
                  onChange={e => tiles.updateTile(tile.id, { _class: e.target.value })}
                  label=""
                  placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile._class"})}
                  />}

            </FormGrid>

            {props.tilesSettings?.no_content !== "true" &&
              <EditorComponent
                content={tile.content}
                update={v => tiles.updateTile(tile.id, { content: v })}
                placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.content"})}
                />}
        </FormGrid>

        <div>
          <MoreActionsMenuButton
            strippedButton
            size="small"
            actions={[
              tiles.tiles.length > 0 ? ["up", <ArrowUpward color="action" fontSize="inherit" />, () => tiles.moveTileUp(tile.id)] : null,
              [
                "copy",
                <Tooltip text_id="common.copy">
                  <FileCopyOutlined color="action" fontSize="inherit" />
                </Tooltip>,
                () => tiles.copyTileToClipboard(tile),
              ],
              ["remove", <DeleteOutlined color="action" fontSize="inherit" />, () => tiles.removeTile(tile.id)],
              tiles.tiles.length > 0 ? ["down", <ArrowDownward color="action" fontSize="inherit" />, () => tiles.moveTileDown(tile.id)] : null,
            ]}
            />
        </div>
    </Tile>
  )
}

const TileEditorMemo = React.memo(TileEditor);


export const TilesBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    const tiles = useTiles(element);

    const isSelected = useSelected();

    const { formatMessage } = useIntl();
    const { viewMode } = usePowerEditorContext();

    if(viewMode) {
        return <TilesBlockView {...(element as Partial<WithTiles>)} />;
    }

    return (
        <Wrapper
          {...attributes}
          backgroundColor={(props.element as any)?.block_panel_background}
          textColor={(props.element as any)?.block_panel_color}
          className="tiles-block-element"
          isSelected={isSelected}>
            {children}

            <div style={{ width: "100%" }} contentEditable={false}>
              {!viewMode && <BlockTitle element={props.element} />}
              {!viewMode &&
                <ActionRow lastItemMarginRight='0' itemMarginTop='0' firstItemNoMargin style={{ marginBottom: "0.25rem" }}>
                  <TextField
                    value={tiles.title || ""}
                    onChange={e => tiles.update({ title: e.target.value })}
                    label=""
                    placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.title"})}
                    fullWidth
                    />

                  <Button onClick={tiles.toggleColumns} style={{ textTransform: "none", flex: "1 0 auto" }}>
                      <FormattedMessage id="powerdoc.plugins.tiles.columns" values={{ columns: tiles.columns }} />
                  </Button>
                  {!viewMode && <IconButton size="small" onClick={e => tiles.update({ isTilesCollapsed: !tiles.isTilesCollapsed})}>
                    {!tiles.isTilesCollapsed ? <ExpandLess /> : <ExpandMore />}
                </IconButton>}
                </ActionRow>}

              {!tiles.isTilesCollapsed && tiles.tiles.map(tile => (
                <TileEditorMemo key={tile.id} tile={tile} tiles={tiles} tilesSettings={props.element as TileBlockSettings} />
              ))}
            </div>

            {!tiles.isTilesCollapsed && 
              <Buttons>
                <IconButton size="small" onClick={() => tiles.insertTile()}><Add /></IconButton>
                <Tooltip text_id="common.paste">
                  <IconButton size="small" onClick={() => tiles.pasteTileFromClipboard()}><AssignmentReturnOutlined /></IconButton>
                </Tooltip>
              </Buttons>}
        </Wrapper>
    );
}
