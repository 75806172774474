import { Schema } from "../../../toolympus/hooks/useSchema";
import { Application } from "./useApplicationsList";
import ExcelJS from "exceljs";
import { utc } from "../../../toolympus/components/timezone";
import { downloadBuffer } from "../../../toolympus/api/core";

export const exportApplications = (schema: Schema, items: Application[]) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Applications");
    sheet.columns = [
        "created_datetime",
        "email",
        "firstname",
        "lastname",
        "middlename",
        "preferred_name",
        "title",
        "gender",
        "birthdate",
        "nationality",
        "country",
        "timezone",
        "phone",
        "education",
        "experience",
        "wa_participation",
        "wa_participation_year",
        "question1",
        "question2",
        "question3",
    ].map(f => ({ header: schema[f]?.label?.toString() || "", key: f }));
  
    items.forEach(item => {
        const { created_datetime, ...other } = item;
        sheet.addRow({
            created_datetime: utc.toLocal(created_datetime).utcOffset(0, true).toDate(),
            ...other,
        })
    });
  
    workbook.xlsx
      .writeBuffer({ base64: true } as any)
      .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `winter_academy_applications_${new Date().getFullYear()}.xlsx`));
  
  }
