import styled from '@emotion/styled'
import {media} from "../../frame";

export const AcademAppWraper = styled.div`
    & .MuiDrawer-paperAnchorDockedLeft {
        border-right: none;
    }

    & .MuiContainer-root {
        display: grid !important;
        grid-template-columns: 290px 1fr;
    }

    ${media.laptop} {
        & .MuiContainer-root {
            display: grid !important;
            grid-template-columns: 240px 1fr;
        }
    }

    ${media.tablet} {
        & .MuiContainer-root {
            grid-template-columns: 100px 1fr;
        }
    }

    ${media.phone} {
        & .MuiContainer-root {
            grid-template-columns: 100%;
        }
    }
`;
