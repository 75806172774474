import moment from 'moment';
import "moment-timezone";

export const toLocal = (date: moment.MomentInput) => {
    return moment.tz(date, 'Europe/Moscow').local().format('YYYY-MM-DD HH:mm:ss')
}

export const toServer = (date: moment.MomentInput) => {
    return moment(date).tz('Europe/Moscow').format('YYYY-MM-DD HH:mm:ss')
}
