import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { apiFetch } from '../../../toolympus/api/core';
import { ActionWithConfirmation, useActionWithConfirmation } from '../../../toolympus/api/useAction';
import { OpenRegistrationReviewData } from '../../../toolympus/components/Contests/Registrations';
import { DefaultUser, IUserContext, useUser } from '../../../toolympus/userContext/UserContext';
import { useParticipants, Participants } from '../../Participants/usePaticipants';
import { Application, ApplicationsList } from '../Form/useApplicationsList';

export type ApplicationApprovalStatus = "approved" | "rejected" | "review";
export interface ApprovalState {
  comment: string;
  status: string;
  finalStatus: string;
  committeeStatus: string;
  committeeComment: string;
  is_preliminary?: boolean;
};

export interface ApplicationReview extends OpenRegistrationReviewData<Application> { };

export interface ApprovalReview {
    isShowSaveButton: boolean;
    approval: ApprovalState;
    updateApproval: (newChanges: {[x: string]: string}) => void;
    saveApproval: (review: { status: string, id: string, reload: () => void, isApprovalFinal?: boolean }) => void;
    saveCommittee: (settings: {reload: (() => void) | undefined, isPreliminary?: boolean }) => void;
    createApprovedUsers: () => void;
    users: Participants;
    user: IUserContext<DefaultUser>;
    removeApplication: ActionWithConfirmation<void>;
}

const APPLICATION_ADMIN_API_PATH = "/api/application/admin";

export const useApprovalReview = (data: ApplicationReview, listData: ApplicationsList) : ApprovalReview => {
    const { enqueueSnackbar } = useSnackbar();
    const users = useParticipants();
    const user = useUser();
    const [isShowSaveButton, setShowSaveButton] = useState(false);

    const currentCommittee = (Object.keys(data?.data?.approval_committee || []).includes(`${user.user?._id}`)) 
        ? data.data?.approval_committee && data?.data?.approval_committee[`${user.user?._id}`] 
        : null;

    const [approval, setApproval] = useState<ApprovalState>({
        comment: "",
        status: "",
        finalStatus: "",
        committeeStatus: "",
        committeeComment: ""
    });

    useEffect(() => {
        setApproval((prevState) => ({
            ...prevState,
            status: `${data?.data?.approval_rac?.approval_status}`,
            finalStatus: `${data?.data?.approval_final}`,
            comment: `${data?.data?.approval_rac?.comment || ""}`,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data?._id]);

    useEffect(() => {
        setApproval((prevState) => ({
            ...prevState,
            committeeStatus: `${currentCommittee?.approval_status}`,
            committeeComment: currentCommittee?.comment || "",
            is_preliminary: currentCommittee?.is_preliminary,
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ data.data ]);

    const removeApplication = useActionWithConfirmation(() => {
        return apiFetch(`${APPLICATION_ADMIN_API_PATH}/${data.data?._id}`, "delete")
            .then(() => {
                listData.reload();
                data.close();
            });
    }, {
        canRun: !!data?.data?._id,
        title: "Delete application",
        confirmationHint: `You're about to delete the application by ${data?.data?.firstname} ${data?.data?.lastname}. Be careful, this action is irrevocable.`,
    })

    const updateApproval = (newChanges: {[x: string]: string}) => {
        !isShowSaveButton && setShowSaveButton(true);
        setApproval((prevState) => ({
            ...prevState,
            ...newChanges
        }));
    }

    const saveApproval = (review: { status: string, id: string, reload: () => void, isApprovalFinal?: boolean }) => {
        apiFetch<Application>(`${APPLICATION_ADMIN_API_PATH}/${review.id}`, "put", review.isApprovalFinal
            ? { approval_final: review.status }
            : { approval_rac: { approval_status: review.status, comment: approval.comment }}).then(resp => {
                enqueueSnackbar(review.status[0].toUpperCase() + review.status.slice(1), { variant: 'success', autoHideDuration: 2000 });
                setApproval((prevState) => ({
                    ...prevState,
                    status: approval.status,
                    finalStatus: `${resp?.approval_final}`
                }));
                setShowSaveButton(false);
                review.reload();
            });
    }

    const createApprovedUsers = () => {
        apiFetch(`${APPLICATION_ADMIN_API_PATH}/users`, "post", []).then(() => {
            enqueueSnackbar('Users were created', { variant: 'success', autoHideDuration: 2000 });
        });
    }

    const saveCommittee: ApprovalReview["saveCommittee"] = ({ isPreliminary, reload }) => {
        apiFetch(`/api/application/committee/${data?.id}`, "put", {
            comment: approval.committeeComment,
            approval_status: approval.committeeStatus,
            is_preliminary: !!isPreliminary,
        }).then(r => {
            enqueueSnackbar('Sucess', { variant: 'success', autoHideDuration: 2000 });
            setShowSaveButton(false);
            setApproval(x => ({ ...x, is_preliminary: !!isPreliminary }))
            reload && reload();
        });
    }

    return {
        approval,
        isShowSaveButton,
        updateApproval,
        saveApproval,
        saveCommittee,
        createApprovedUsers,
        removeApplication,
        users,
        user,
    }
}