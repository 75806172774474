import moment, { Moment } from 'moment';
import React from 'react';
import { Hidden, Show } from '../../common/Hidden';
import { downloadCalendar } from './AddToCalendar';
import {
  ButtonLink,
  DaySchedule,
  IconLinkAction,
  Info,
  NoSchedules,
  ScheduleRow,
  ShortTitle,
  Time,
  Title,
  TitleSchedule,
  Tutor,
} from './CoursesPage.style';
import InfoIcon from './Info.png';
import CalendarIcon from './Calendar.png';
import { Button, Link } from '../../common/Button';
import { Schedule } from '../../CourseManagement/types';
import { Timetable } from './useTimetableNew';
import { participantName } from '../../Participants/presentation/display';
import { ExternalLink } from '../../AboutCourse/presentation/AboutCoursePage.style';
import { ArrowRightAlt } from '@mui/icons-material';

const ScheduleComponent = ({ schedule }: { schedule: Schedule }) => {
  const {
    start_datetime,
    end_datetime,
    title,
    tutors,
    course,
  } = schedule;
  const shortTitle = <ShortTitle>{course?.shorttitle}</ShortTitle>;
  return (
    <ScheduleRow>
      <Time>
        {moment(start_datetime).format('H:mm')}-
        {moment(end_datetime).format('H:mm')}
      </Time>
      <Info className="main-title">
        <TitleSchedule>{course?.title}</TitleSchedule>
        {(tutors || []).map(t => (
          <Tutor key={t._id}>
            {participantName(t)}
          </Tutor>
        ))}
        <Link to={`/public/courses/${course?._id}`}>
          <Hidden hide={['phone']}>{title}</Hidden>
        </Link>

        {schedule.external_link &&
          <ExternalLink href={schedule.external_link}>
            <span>join</span> <ArrowRightAlt />
          </ExternalLink>}
      </Info>
      <Show className='short-title' show={['phone']}>
        {shortTitle}
      </Show>
      <ButtonLink className='info-btn-link' to={`${course?._id}`}>
        <img src={InfoIcon} alt='info' />
      </ButtonLink>

      <div onClick={downloadCalendar([schedule as Schedule])}>
        <Show show={['phone', 'tablet']}>
          <IconLinkAction className='info-btn-link'>
            <img src={CalendarIcon} alt='info' />
          </IconLinkAction>
        </Show>
        <Hidden hide={['phone', 'tablet']}>
          <Button variant='outlined' color='primary'>
            + Add to calendar
          </Button>
        </Hidden>
      </div>
    </ScheduleRow>
  );
};

interface Props {
  day: Moment;
  timetable: Timetable;
}

export const Day = ({ day, timetable }: Props) => {
  const events = timetable.eventsByDay[timetable.dayKey(day)];
  return (
    <DaySchedule>
      <Title></Title>
      {events.length ? (
        events.map((currentEvent: Schedule, index: number) => (
          <ScheduleComponent key={index} schedule={currentEvent} />
        ))
      ) : (
        <NoSchedules>No events</NoSchedules>
      )}
    </DaySchedule>
  );
};
