import styled from '@emotion/styled'
import { media } from "../../frame";

export const ColumnsContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 30px;

    ${media.laptop} {
        grid-template-columns: 150px 1fr;
        gap: 10px;
    }

    ${media.phone} {
        grid-template-columns: 1fr;

        & .avatar {
            padding: 0 30px;
        }
    }

    & .MuiCheckbox-root {
        padding-top: 0;
    }

    & .MuiFormControlLabel-root {
        align-items: flex-start;
        padding-top: 10px;

        &.MuiFormControlLabel-labelPlacementEnd {
          align-items: center;

          & .MuiCheckbox-root {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
    }
`;

export const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    flex-flow: row wrap;
    height: max-content;

    max-width: 100%;
`;

type Mod = (keyof typeof media)

type Props = {
    [key in Mod]?: number | string;
};

const mods = Object.keys(media) as Mod[];

export const Block = styled.div<Props>`
    padding: 10px;
    
    ${(props) => 
        mods.map(mod => props[mod]? media[mod] + `{ width: ${+(props[mod] || 12) * 100/12}%; }` : '')
    }
`;
