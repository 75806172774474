import { useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useEditItem2 } from "../../../toolympus/api/useNewItem"
import { IDefaultUser } from "../../../toolympus/components/userManagement/UserManagementForm";
import { Tutor } from "../../CourseManagement/types"
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";

interface TutorNew extends Tutor {
}

export const useNewTutor = () => {
  const [isUseExistingUser, setIsUseExistingUser] = useState<boolean>(false);

  const users = useLoadedData<IDefaultUser[]>("/api/user", [], isUseExistingUser);

  const data = useEditItem2<Partial<TutorNew>>({
    save: (item) => {
      if(isUseExistingUser) {
        
        const tutorCopy = { ...item } as any;
        delete tutorCopy.email;

        const user = users.data.find(u => u._id === item._id);
        let roles = user?.roles || [];

        if(!roles.includes("participant") || !roles.includes("tutor")) {
          roles = Array.from(new Set([ ...roles, "participant", "tutor"]));
          return apiFetch<IDefaultUser>(`/api/user/${user?._id}`, "put", { roles })
            .then(() => apiFetch<Tutor>("/api/admin/tutor", "post", tutorCopy))
        } else {
          return apiFetch<Tutor>("/api/admin/tutor", "post", tutorCopy);
        }

      } else {
        return apiFetch<IDefaultUser>("/api/user", "post", { email: item.email, roles: ["participant", "tutor"]})
          .then(user => {
            const tutorCopy = { ...item, _id: user._id } as any;
            delete tutorCopy.email;
            return apiFetch<Tutor>("/api/admin/tutor", "post", tutorCopy)
          })
      }
    },
    getApiPath: () => "",
  })

  const startCreate = () => {
    data.startEditing({ firstname: "", lastname: "", title: "", company: "", position: "", email: "" })
  }

  return {
    ...data,
    startCreating: startCreate,
    isUseExistingUser,
    setIsUseExistingUser,
    users: users.data,
  }
}

export type NewTutorData = ReturnType<typeof useNewTutor>;
