import React from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import Logo from './Logo.png';
import LogoMini from './Logo-mini.png';
import { onlyTable } from './util';
import { BorderBox } from '../BorderBox';
import { Close } from '@mui/icons-material';
import { media } from "../../frame";

export const LogoImg = styled.img`
    width: 140px;
    height: auto;

    ${onlyTable} {
        display: none;
    }

    ${media.phone} {
        width: 90px;
    }
`;
LogoImg.defaultProps = { src: Logo, alt: 'RAC' };

export const LogoMiniImg = styled.img`
    width: 35px;
    height: auto;

    display: none;

    ${onlyTable} {
        display: block;
    }
`;
LogoMiniImg.defaultProps = { src: LogoMini, alt: 'RAC' };


export const LogoContainer = styled.div`
    width: 100%;
`;

export const LogoBox = styled(BorderBox)`
    width: 100%;
    height: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;

    ${media.phone} {
        padding: 30px;

        justify-content: space-between;
    }
`;
LogoBox.defaultProps = { vertic: true };

export const BlockNoTable = styled.div`
    width: 100%;
    height: 100%;

    ${media.minWidth.tablet} {
        max-width: 250px;
    }

    ${onlyTable} {
        display: none;
        max-width: 200px;
    }
`;


export const GlobalStyle = () => <Global styles={css`
    ${media.phone} {
        body .drawer-container .MuiIconButton-root {
            position: fixed;
            right: 30px;
            top: 10px;
            z-index: +10;
        }

        body .MuiDrawer-paper {
            width: 100%;
        }
    }
`} />;

export const CloseIcon = styled.div`
    display: none;

    ${media.phone} {
        display: block;
    }
`;

export const Logotip = ({onClose}: {onClose: () => void})  => {

    return (
    <LogoContainer>
        <GlobalStyle />
        <LogoBox>
            <LogoImg />
            <LogoMiniImg />
            <CloseIcon onClick={onClose}><Close /></CloseIcon>
        </LogoBox>
    </LogoContainer>
)};
