import React, { CSSProperties, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator'
import { Button } from '../../common/Button';
import { media } from '../../frame';
import { PeopleList } from './PeopleList';
import { useOrganizers, useOrganizersChat } from '../useOrganizers';
import { usePublicDocumentationPage } from '../../../PublicDocumentationPage';
import { DocumentationEditorWrapped } from '../../../CMS';

export const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.24px;

    color: #000000;

    margin: 30px 0;

    & .MuiButtonBase-root {
        max-width: 290px;
        margin-left: 30px;

        ${media.laptop} {
            max-width: 260px;
            margin-left: 0;
            margin-top: 30px;
        }
    }

    ${media.laptop} {
        flex-flow: column nowrap;
    }
`;

export const ContactOrganizerBlock = (props: PropsWithChildren<{ style?: CSSProperties }>) => {
  const chatData = useOrganizersChat();

  return chatData.isLoading
    ? null
    : <Head style={props.style}>
        {props.children}
        <Button href={chatData.chatLink} variant="contained" color="primary">Contact Organiser</Button>
    </Head>;
}

export const OrganizersPage = () => {
  const data = useOrganizers();
  const page = usePublicDocumentationPage("organising-committee");

    return <div>
      <ContactOrganizerBlock>
        Should you have any questions, please do not hesitate to contact us
      </ContactOrganizerBlock>
      {data.isLoading
        ? <LoadingIndicator />
        : <PeopleList list={data.data} />}
      {page.data.content && <DocumentationEditorWrapped
        viewMode
        content={page.data.content}
        update={() => {}}
        />}
    </div>
}