import React, { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { apiFetch, downloadFile } from '../../../toolympus/api/core';
import { RegistrationsData, RegistrationsTabs, useOpenRegistrationReview, useRegistrationsTabs } from '../../../toolympus/components/Contests/Registrations';
import { useTextFilter } from '../../../toolympus/components/schemed/Filtering/useTextFilter';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { mergeSchema, useSingleSchema } from '../../../toolympus/hooks/useSchema';
import { ApplicationApprovalStatus, ApplicationReview } from '../Common/useApprovalReview';
import { exportApplications } from './applicationsExport';
import { TabDefinition, useTabsState } from '../../../toolympus/components/primitives';
import { DefaultUser, useUser } from '../../../toolympus/userContext/UserContext';
import { Badge } from '@mui/material';
import { Action, useAction, useActionWithConfirmation } from '../../../toolympus/api/useAction';
import { ActionWithConfirmation } from '../../../toolympus/api/useAction';

export interface ApplicationApproval {
  approval_status: ApplicationApprovalStatus;
  comment: string;
  is_preliminary?: boolean;
}

export interface Application {
    approval_committee?: { [x: string]: ApplicationApproval } | null | undefined;
    _id: string;
    birthdate: string;
    email: string;
    timezone: string;
    question2: string;
    lastname: string;
    phone: string;
    nationality: string;
    experience: string;
    wa_participation_year: string;
    pdc: boolean;
    wa_participation: string;
    created_datetime: string;
    question1: string;
    ml_document_id: string;
    preferred_name: string;
    title: string;
    middlename: string;
    firstname: string;
    question3: string;
    approval_final: ApplicationApprovalStatus;
    camera_acknowledged: boolean;
    cv_document_id: string;
    avatar_id?: string | null;
    user_id?: null;
    approval_rac?: {
      comment: string;
      approval_status: ApplicationApprovalStatus;
    };
    education: string;
    gender: string;
    country: string;
  }
  
  export interface ApplicationsList extends RegistrationsData<Application> {
    review: ApplicationReview;
    createApprovedUsers?: Action<void>;
    exportApplications: () => void;
    
    hasPreliminaryApprovals?: boolean;
    finalizeAllApprovals?: ActionWithConfirmation<void>;

    filterData: (applications: Application[]) => Application[];
    downloadAllDocuments: Action<void>;
  }


export const useApplicationsList = (apiQuery: string) : ApplicationsList => {
  const applicationApiPath = `/api/application/${apiQuery}`
  const data = useLoadedData<Application[]>(applicationApiPath, []);
  const { schema: applicationSchema } = useSingleSchema(`${applicationApiPath}/uiconfig`);
  const filter = useTextFilter((t: Application) => `${t.email} ${t.lastname} ${t.firstname}`);

  const approval = {
    isRegistrationApproved: (t: Application) => t.approval_final === "approved",
    update: () => new Promise<Application>(r => r)
  };

  const tabs = useRegistrationsTabs({
    approval,
    queryParam: "view",
    tabsOrder: ["all"],
  });

  const schema = mergeSchema(applicationSchema, {
    approval_final: { label: "Result" },
    approval_committee: { label: "Committee Approval" },
    wa_participation: { label: "Past Participation" },
    wa_participation_year: { label: "Past Participation Year" },
  });

  const review = useOpenRegistrationReview<Application>({
    apiPath: applicationApiPath,
    schema,
    onSaved: () => data.reload(),
  });

  const downloadAllDocuments = useAction(() => {
    return downloadFile(`/api/application/document/export/by-application`);
  });
  
  return {
    ...data,
    approval,

    ...filter,
    schema,
    tabs,
    review,

    exportApplications: () => exportApplications(schema, data.data),
    downloadAllDocuments,
  }
}




export const useApplicationsListAdmin = () : ApplicationsList => {
  const base = useApplicationsList("admin");
  const { enqueueSnackbar } = useSnackbar();

  const approval = {
    isRegistrationApproved: (t: Application) => t.approval_final === "approved",
    update: () => new Promise<Application>(r => r)
  };

  const tabs = useRegistrationsTabs({
    approval,
    queryParam: "view",
    tabsOrder: ["all", "approved", "unapproved"],
  });

  const doCreateApprovedUsers = () => {
    return apiFetch(`/api/application/admin/users`, "post", []).then(response => {
        enqueueSnackbar('Users were created', { variant: 'success', autoHideDuration: 2000 });
    });
  }

  const createApprovedUsers = useAction(doCreateApprovedUsers);

  const filteredData = base.filterData(base.data.filter(t => tabs.activeFilter(t)));

  return {
    ...base,
    data: filteredData,
    approval,

    tabs,

    createApprovedUsers,

    exportApplications: () => exportApplications(base.schema, filteredData),
  }
}




const getMyReview = (a: Application, user: DefaultUser) => user?._id ? (a.approval_committee || {})[user._id] : null;
const isPreliminaryReviewed = (a: ApplicationApproval | null | undefined) => a?.is_preliminary || false;
const isUnreviewed = (a: ApplicationApproval | null | undefined) => !a || (!a.is_preliminary && a.approval_status !== "approved" && a.approval_status !== "rejected");

export const useApplicationsListCommittee = () : ApplicationsList => {
  const base = useApplicationsList("committee");
  const { user } = useUser();
  

  const [preliminaries,unreviewed] = useMemo(() => {
    if(!user) {
      return [[], []];
    }
    return [
      base.data.filter(a => isPreliminaryReviewed(getMyReview(a, user))),
      base.data.filter(a => isUnreviewed(getMyReview(a, user))),
    ];
  }, [base.data, user]);

  const committeeTabsState = useTabsState(
    [
      ["all","All"],
      unreviewed.length > 0 ? ["unreviewed", <Badge color="secondary" badgeContent={unreviewed.length}>Unreviewed</Badge>] : null,
      preliminaries.length > 0 ? ["preliminary", <Badge color="secondary" badgeContent={preliminaries.length}>Preliminary reviewed</Badge>] : null,
    ].filter(x => !!x) as TabDefinition[]);

  const tabs: RegistrationsTabs<Application> = {
    ...committeeTabsState,
    activeFilter: a => {
      if(committeeTabsState.current === "preliminary") {
        return isPreliminaryReviewed(getMyReview(a, user));
      } else if(committeeTabsState.current === "unreviewed") {
        return isUnreviewed(getMyReview(a, user));
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    if(committeeTabsState.current === "preliminary" && preliminaries.length === 0) {
      committeeTabsState.setCurrent("all");
    }
    if(committeeTabsState.current === "unreviewed" && unreviewed.length === 0) {
      committeeTabsState.setCurrent("all");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeTabsState.current, preliminaries, unreviewed]);


  const doFinalizeAllApprovals = () => {
    return Promise.all(preliminaries.map(a => {
      if(user?._id) {
        const approval = (a.approval_committee || {})[user._id];
        if(approval) {
          return apiFetch<Application>(`/api/application/committee/${a._id}`, "put", { ...approval, is_preliminary: false });
        }
      }
      return Promise.resolve(a);
    }))
    .then(() => base.reload());
  }

  const finalizeAllApprovals = useActionWithConfirmation(doFinalizeAllApprovals, {
    canRun: !!user?._id && preliminaries.length > 0,
    title: "Finalize preliminary approvals",
    confirmationHint: "All approvals and rejections, which you saved as preliminary, will be changed to final and will become visible to other committee members and organisers.",
    confirmationLabel: "Finalise",
  });

  const filteredData = base.filterData(base.data.filter(t => tabs.activeFilter(t)));

  return {
    ...base,
    data: filteredData,

    tabs,
    hasPreliminaryApprovals: preliminaries.length > 0,
    finalizeAllApprovals,


    exportApplications: () => exportApplications(base.schema, filteredData),
  }
}
