import React, { useState } from 'react';
import {
    Route,
    Switch,
    useHistory,
} from "react-router-dom";
import { InnerRoutes } from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { AppWrapper, Sidebar, AppContent } from './components/frame';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { BorderBox } from './components/common/BorderBox';
import {
    AcademAppWraper,
    BlockNoTable,
    BottomLinks,
    ContainerContent,
    Content,
    ContentBody,
    FooterBottomContent,
    FooterMenu,
    Logotip,
    MenuGridLogoAndListAndBottomLinks,
    MenuList,
    MenuPanel
} from './AppRouter.style';
import { Header } from './components/Header/Header';
import { IconsLinks } from './components/Header/IconsLinks';
import { BottomAvatar, FooterContent, MenuFooterGap } from './components/common/AppRouter.style';
import { Avatar } from './components/Header/Header.style';
import { PartnersCarouselForMenu } from './components/Login/PartnersCarousel';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { ConfirmationDialogProvider } from './toolympus/components/primitives/ConfirmationDialog';
import { Menu, MenuItem } from './toolympus/components/frame/new';
import { UnreadMessagesCountProvider } from './components/Messages/presentation/MenuMessages';
import { BackgroundCssString } from './Background';

const FooterBoxes = () =>
        <>
            <MenuFooterGap />
            <PartnersCarouselForMenu />
        </>

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();

    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && !!user._id });

    const routesToRender: any[] = InnerRoutes.filter((r: any) => r.resolveHidden === undefined || r.resolveHidden(user?.roles || []));

    const menuItems: MenuItem[] = routesToRender
        .filter(r => !r.hidden && r.title)
        .map(r => { return { title: r.title || "", menuComponent: r.menuComponent, ...r } });

    const title = (
        InnerRoutes
            .filter(({ path, match }) =>
                match ?
                    match(history.location.pathname)
                    :
                    path.replace(/\//g, '').startsWith(history.location.pathname.replace(/\//g, ''))
            )[0] || {}).title || "";

    


    const menu = (setOpen: (v: boolean) => void) => (
        <MenuPanel>
            <MenuGridLogoAndListAndBottomLinks>
                <Logotip onClose={() => setOpen(false)} />
                <MenuList>
                    <Menu menuItems={menuItems} />
                </MenuList>
                <BorderBox vertic bottom>
                    <BottomLinks>
                        <BottomAvatar>
                            <Avatar user={user} />
                        </BottomAvatar>
                        <IconsLinks logout={logout} />
                    </BottomLinks>
                </BorderBox>
            </MenuGridLogoAndListAndBottomLinks>
            <BlockNoTable>
                <FooterMenu>
                    <FooterBoxes />
                    <FooterBottomContent>
                        © 2021 - {new Date().getFullYear()} Winter Academy
                    </FooterBottomContent>
                </FooterMenu>
            </BlockNoTable>
        </MenuPanel>
    );


    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
            <ConfirmationDialogProvider>
              <UnreadMessagesCountProvider>
                <AcademAppWraper>
                    <AppWrapper background={BackgroundCssString}>
                        <Sidebar
                            menuSetOpen={menu}
                            background={`transparent`}
                            noline
                        />
                        <AppContent padding={false} background={`transparent`}>
                            <ContainerContent>
                                <Content>
                                    <Header logout={logout} title={title} />
                                    <ContentBody>
                                        <Switch>
                                            {routesToRender.map(r => (
                                                <Route path={r.path} key={r.path} component={r.component} />
                                            ))}
                                        </Switch>
                                    </ContentBody>
                                </Content>
                                <FooterContent>
                                    <FooterBoxes />
                                    <FooterBottomContent>
                                      © 2021 - {new Date().getFullYear()} Winter Academy
                                    </FooterBottomContent>
                                </FooterContent>
                            </ContainerContent>
                        </AppContent>
                    </AppWrapper>
                </AcademAppWraper>
              </UnreadMessagesCountProvider>
            </ConfirmationDialogProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;
