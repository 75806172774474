import moment, { Moment } from 'moment';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Schedule } from '../../CourseManagement/types';
import { Block, DayColumn, ScheduleWrapper } from './AllSchedules.style';
import 'moment-timezone';
import { Timetable } from './useTimetableNew';
import { EventBlock } from './ScheduleCompontent';
import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';


interface Props {
  timetable: Timetable;
}


const ScheduleDay = ({ day, events }: { day: Moment, idx: number, events: Schedule[] }) => {
  return (
    <DayColumn>
      <Block key={0}>
        <div className='text-black'>
          <b>{moment(day).locale('en').format('MMMM')}</b>
          <br />
          {moment(day).format('DD')}
        </div>
        {moment(day).locale('en').format('dddd')}
      </Block>

      {events.length === 0 &&
        <Block>No events</Block>}
      {events.map(e => <EventBlock key={e._id} event={e} />)}
    </DayColumn>
  )
}


export const AllSchedulesNew = ({ timetable }: Props) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [fullWidth, setFullWidth] = useState<number>(0);

  useLayoutEffect(() => {
    setFullWidth(wrapperRef.current?.parentElement?.getBoundingClientRect().width || 0);
  }, []);

  const scroll = (direction: 1 | -1) => {
    if(wrapperRef.current) {
      wrapperRef.current.scrollBy({ left: direction*6*(fullWidth + 8) / 6 -8, behavior: 'smooth'});
    }
  }

  return (
    <ScheduleWrapper ref={wrapperRef} fullWidth={fullWidth}>
      <IconButton className="scroll-left" onClick={() => scroll(-1)}><ChevronLeft /></IconButton>
      <IconButton className="scroll-right" onClick={() => scroll(1)}><ChevronRight /></IconButton>
      {timetable.allDays.map((day, idx) => (
        <ScheduleDay
          key={day.toString()}
          idx={idx}
          day={day}
          events={timetable.eventsByDayWithBreaks[timetable.dayKey(day)]}
          />
      ))}
    </ScheduleWrapper>
  );
};
