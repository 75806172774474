import styled from '@emotion/styled'

interface Props {
    all?:    boolean;
    left?:   boolean;
    right?:  boolean;
    top?:    boolean;
    bottom?: boolean;
    horiz?:  boolean;
    vertic?: boolean;
    width?: string;
    height?: string;
    marginCenter?: boolean;
}

const borderColor = (mode: string, dect: string) => (props: any) => (props[mode] || props[dect]? props.theme.palette.primary.light : 'none')

export const BorderBox = styled.div<Props>`
    box-sizing: border-box;
    border:        3px solid ${borderColor('all', 'none')};
    border-left:   3px solid ${borderColor('left', 'vertic')};
    border-right:  3px solid ${borderColor('right', 'vertic')};
    border-top:    3px solid ${borderColor('top', 'horiz')};
    border-bottom: 3px solid ${borderColor('bottom', 'horiz')};
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || 'auto'};
    margin: ${({marginCenter}) => marginCenter? '0 auto': 0};
    background-color: #fff;
`;