import React, {  } from 'react'
import styled from '@emotion/styled'
import { useMediaBreakpoint } from '../../toolympus/components/primitives/useMedia';
import { PartnersCarouselContainer, PartnersCarouselWrapper } from '../../toolympus/Partners';


const CarouselWrapper = styled(PartnersCarouselWrapper)`
    background: #ffffff;
    height: 190px;

    & .section {
        height: '100%';
        padding: 30px 70px;
        top: 0vh;
    }

    ${props => props.theme.breakpoints.down("md")} {
        height: 150px;
    }

    ${props => props.theme.breakpoints.down("sm")} {
        height: 150px;

        & .section {
        padding: 15px 20px;
        column-gap: 20px;
        }

        & .group-title {
            font-size: 14px;
            line-height: 18px;
            height: 23px;
        }
    }

    ${props => props.theme.breakpoints.down("xs")} {
        height: 125px;
    }

    border: 3px solid ${props => props.theme.palette.primary.light};
`;

const CarouselWrapperForMenu = styled(CarouselWrapper)`
    height: 125px;
    & .section {
        padding: 15px 20px;
        column-gap: 20px;
    }

    & .group-title {
        font-size: 14px;
        line-height: 18px;
        height: 23px;
    }
`;

export const PartnersCarousel = () => {
    const isLarge = useMediaBreakpoint("up", "lg");
    const isMedium = useMediaBreakpoint("up", "sm");
    return <PartnersCarouselContainer
        pagesApiPath="/api/pages"
        pageSlug="partners"
        wrapper={CarouselWrapper}
        columns={isLarge ? 6 : isMedium ? 4 : 2} />
}

export const PartnersCarouselForMenu = () => {
    return <PartnersCarouselContainer
        pagesApiPath="/api/pages"
        pageSlug="partners"
        wrapper={CarouselWrapperForMenu}
        columns={2} />
}