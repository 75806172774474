import React, { ReactNode } from "react";
import { FormControlLabel, Checkbox as CheckboxMUI, useTheme } from "@mui/material";
import { expandProps, FormControlProps } from "./FormControlProps";

export const Checkbox = (props: FormControlProps) => {
    const { field, row, label, value, controlProps, onChange, error } = expandProps(props);

    const theme = useTheme();

    return <FormControlLabel
                label={label}
                style={error ? { color: theme.palette.error.main } : undefined}
                control={<CheckboxMUI
                            checked={value || false}
                            {...controlProps}
                            onChange={e => !controlProps.readOnly && onChange(row, {[field]: e.target.checked})} />} />;
}

interface BareProps {
  value: boolean | null | undefined;
  update: (v: boolean) => void;
  label: ReactNode;
  isError?: boolean;
  controlProps?: any;
}

export const CheckboxBare = (props: BareProps) => {
  const { value, update, label, isError, controlProps } = props;

  const theme = useTheme();

  return <FormControlLabel
              label={label}
              style={isError ? { color: theme.palette.error.main } : undefined}
              control={<CheckboxMUI
                          checked={value || false}
                          {...controlProps}
                          onChange={e => !controlProps?.readOnly && update(e.target.checked)} />} />;
}
