import { Container } from '@mui/material';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled'

export interface AppWrapperProps {
    children?: ReactNode;
    background?: string;
}

const ContainerView = styled(Container)<{background: string}>`
    min-height: 100vh;
    display: flex !important;
    max-width: 100vw;
    background: ${props => props.background};

    @media(max-width: 1280px){
        max-width: 100vw;
        display: flex;
    }
`

export const AppWrapper: React.FC<AppWrapperProps> = (props: AppWrapperProps) => {
    return (
        <ContainerView background={props.background || ""} disableGutters maxWidth={false}>
            {props.children || ""}
        </ContainerView>
    )
}