import { ReactEditor } from "slate-react";
import { usePowerEditorControlContext } from "./PowerEditorControlContext";
import { useEffect, useState } from "react";
import { Transforms } from "slate";
import { arePathsSame, getTargetForFocusRequest } from "./SlateHelpers";

interface Config {
  editorElementId?: string;
  highlightDuration?: number;
}


export const useEditorExternalControlHandlers = (editor: ReactEditor, cfg?: Config) => {
  const externalControl = usePowerEditorControlContext();
  
  const [highlightNode, setHighlightNode] = useState<any | null>(null);

  useEffect(() => {
    if(highlightNode) {
      const timeout = setTimeout(() => setHighlightNode(null), cfg?.highlightDuration || 3000);
      return () => clearTimeout(timeout);
    }
  }, [highlightNode, cfg?.highlightDuration])

  useEffect(() => {
    if(externalControl.focusChangeRequest) {

      const { node, path } = getTargetForFocusRequest(editor, externalControl.focusChangeRequest);

      if(path) {
        const editorId = cfg?.editorElementId;
        if(editorId) {
          setTimeout(() => {
              document.getElementById(editorId)?.focus();
          }, 100);
        }

        if(editor.selection && arePathsSame(editor.selection.anchor.path, path.anchor.path)) {
          Transforms.select(
            editor,
            { anchor: { path: [0,0], offset: 0 }, focus: { path: [0,0], offset: 0 } },
          );
        }

        setTimeout(() => {
          Transforms.select(
            editor,
            path
          );
        }, 200);

        if(externalControl.focusChangeRequest.highlight && node) {
          setHighlightNode(node);
        }

        externalControl.acknowledgeFocusChange();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalControl.focusChangeRequest, cfg?.editorElementId]);

  return {
    highlightNode,
  }
}
