import styled from '@emotion/styled'
import {Link} from "react-router-dom";
import {media} from "../../frame";

const primaryColor = (p: any) => p.theme.palette.primary.main;
const primaryText = (p: any) => p.theme.palette.text.primary;


const durationToHeight = (duration?: number) => Math.floor((duration || 15) / 15) * 5;

export const Block = styled.div<{light?: boolean, duration?: number }>`
    display: flex;
    justify-content: ${({light}) => light? 'space-between' : 'center'};
    align-items: ${({light}) => light? 'flex-start' : 'center'};
    flex-flow: column nowrap;
    min-height: ${props => durationToHeight(props.duration)}px;
    flex: 0 0 auto;

    background: ${({light}) => light? 'rgba(161, 182, 204, 0.19)' : 'rgba(224, 224, 224, 0.6)'}; 
    padding: 10px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: ${({light}) => light? 'left' : 'center'};

    color: rgba(0, 0, 0, 0.6);

    & .text-black {
        color: #202020;
        font-weight: 500;
    }

    & .external-link {
      margin-top: 0.5rem;
    }

    ${media.laptop} {
        font-size: 12px;
    }

    ${media.tablet} {
        font-size: 10px;
    }
`;

export const Title = styled(Link)`
    font-style: normal;
    font-weight: bold;
    text-decoration: none;

    color: ${primaryColor};
`;

export const Tutor = styled.span`
    font-style: italic;
    font-weight: normal;
    line-height: 1.21;
    display: block;

    color: ${primaryText};
`;

export const ShortTitle = styled.div`
    margin-top: 25px;

    font-style: normal;
    font-weight: bold;

    color: ${primaryText};
`;

export const ScheduleBlock = styled.div<{ duration?: number }>`
    display: grid;
    grid-template-rows: 1fr max-content;
    grid-auto-flow: column;
    gap: 2px;
    min-height: ${props => durationToHeight(props.duration)}px;
    flex: 0 0 auto;
`;

export const AddToCalendarButton = styled.div`
    background: rgba(161, 182, 204, 0.19); 

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 187.6%;

    text-align: center;
    text-transform: uppercase;

    color: ${primaryColor};
    padding: 7px;
    cursor: pointer;
`;




export const DayColumn = styled.div`
  display: flex;
  flex-flow: column;
  
  gap: 8px;
  width: 200px;
`;
DayColumn.defaultProps = { className: "day-column" };

export const ScheduleWrapper = styled.div<{ fullWidth: number }>`
    display: flex;
    
    gap: 8px;
    margin-top: 20px;

    overflow: auto;
    max-width: 100%;

    & > .day-column {
      width: ${props => (props.fullWidth + 8) / 6 - 8}px;
      min-width: ${props => (props.fullWidth + 8) / 6 - 8}px;
    }


    /* position relative is set on parent to place scroll buttons properly */
    /* position: relative; */

    & > .scroll-left, & > .scroll-right {
      position: absolute;
      top: 55%;
      
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;

      &.scroll-left {
        left: 0;
      }
      &.scroll-right {
        right: 8px;
      }
      
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
`;
