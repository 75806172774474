import {ColumnsContainer} from "../../MyProfile/presentation/MyProfilePage.style";
import React from "react";
import {Person} from "../types";
import {useAvatar} from "../../common/Avatar/useAvatar";
import {Avatar} from "../../common/Avatar/Avatar";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LinkSrc from "./Link.png";
import {LoadingIndicator} from "../../../toolympus/components/primitives/LoadingIndicator";
import {Info, LinkBack, Name, Title, Position, SubTitle, Actions, ButtonLinkAnchor} from "./ParticipantPage.style";
import {ButtonLink} from "../../Courses/presentation/CoursesPage.style";
import {Message} from "@mui/icons-material";
import { participantName } from "./display";

interface Props {
    data: {
        data: Person | null,
        error: any,
        loading: boolean,
    },
    userId: string;
}

export const ParticipantPage = ({userId, data: {loading, data}}: Props) => {
    const avatar = useAvatar(userId);

    if (loading) {
        return <LoadingIndicator />
    }

    if (!data) {
        return <></>
    }

    return <ColumnsContainer>
        <LinkBack to="/participants"><KeyboardBackspaceIcon fontSize="small"/> Back</LinkBack>
        <Avatar noChange avatar={avatar}/>

        <Info>
            <Title>
                <div>
                    <Name>{participantName(data)}</Name>

                    <Position>{data.position} {data.company}</Position>
                </div>
                <Actions>
                    <ButtonLink to={'/public/message/' + data._id}><Message color='primary' /></ButtonLink>
                    {data.linkedin_url && <ButtonLinkAnchor href={data.linkedin_url}><img src={LinkSrc} alt={"link"}/></ButtonLinkAnchor>}
                </Actions>
            </Title>
            <SubTitle>Bio</SubTitle>
            {data.bio}
            {data.interests &&<>
                <SubTitle>Interests</SubTitle>
                {data.interests}
            </>}
        </Info>
    </ColumnsContainer>
}
