import React from 'react';
import { Icon } from "./Header.style";
import LogoutIcon from './Logout.svg';
import { UnreadMessagesIconButton } from '../Messages/presentation/MenuMessages';

interface Props {
    logout: () => void;
}

export const IconsLinks = ({logout}: Props) => <>
    <UnreadMessagesIconButton />
    <Icon onClick={logout} img={LogoutIcon} alt="logout" href="/logout" />
</>