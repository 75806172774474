import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { Schedule } from '../../CourseManagement/types';
import { formatDayInternal } from './dates';
import { generateCode } from '../../../toolympus/components/PowerDoc/plugins/common';


type EventsByDay = Record<string, Schedule[]>;


export interface Timetable {
    allDays: Moment[];
    eventsByDay: EventsByDay;
    eventsByDayWithBreaks: EventsByDay;
    dayKey: (date: Moment) => string;
}


const dayKey = (d: Moment) => formatDayInternal(d);

const getFullDateRange = (dates: string[]) => {
  const includedDates = dates.map(d => moment(d).startOf("day"));
  const uniqueDates = Array.from(new Set(includedDates));
  const allDatesSorted = uniqueDates.sort((a,b) => a.isBefore(b) ? -1 : 1);
  
  if(allDatesSorted.length < 2) {
    return allDatesSorted;
  }

  const start = allDatesSorted[0];
  const end = allDatesSorted.slice(-1)[0];

  const result = [start];
  
  for (let i = 1; result.slice(-1)[0].isBefore(end); i++) {
    result.push(start.clone().add({ days: i })) 
  }

  return result;
}


const prepareTimetable = (allEvents: Schedule[]): Timetable => {
  const allDates = getFullDateRange(allEvents.map(e => e.start_datetime));

  const allEventsByDay = allEvents.reduce<Record<string, Schedule[]>>(
    (r,e) => {
      const k = dayKey(moment(e.start_datetime).startOf("day"));
      if(!r[k]) {
        r[k] = [e];
      } else {
        r[k].push(e);
      }
      return r;
    },  
    {});
  
  const allDaysWithSortedEvents = allDates.reduce<EventsByDay>((r,day) => {
    const k = dayKey(day);
    const events = allEventsByDay[k] || [];
    r[k] = events.sort((a,b) => moment(a.start_datetime).isBefore(moment(b.start_datetime)) ? -1 : 1);
    return r;
  }, {});
  
  const allDaysWithSortedEventsAndBreaks = Object.entries(allDaysWithSortedEvents).reduce<EventsByDay>((r,[day,events]) => {
    const withBreaks = events.reduce<Schedule[]>((r,e) => {
      if(r.length === 0) {
        r.push(e);
      } else {
        const prevEnd = r.slice(-1)[0].end_datetime;
        const thisStart = e.start_datetime;
        if(prevEnd && thisStart) {
          const prevEndM = moment(prevEnd);
          const thisStartM = moment(thisStart);
          if(prevEndM.isBefore(thisStartM)) {
            r.push({ _id: generateCode(), title: "Break", isBreak: true, start_datetime: prevEnd, end_datetime: thisStart } as any);
          }
        }
        r.push(e);
      }

      return r;
    }, []);

    r[day] = withBreaks;
    return r;
  }, {});

  return {
    allDays: allDates.filter(d => d.weekday() !== 0 || allDaysWithSortedEvents[dayKey(d)].length > 0),
    eventsByDay: allDaysWithSortedEvents,
    eventsByDayWithBreaks: allDaysWithSortedEventsAndBreaks,
    dayKey,
  }
}

export const useTimetable = (allEvents: Schedule[]): Timetable => {
  return useMemo(() => prepareTimetable(allEvents), [allEvents]);
}
