import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { Button } from '../../common/Button';
import { TextField } from '../../common/TextField';

interface Props {
    onClose: () => void;
    onSave: (title: string) => void;
}

export const NewChatPopap = ({onClose, onSave}: Props) => {
    const [title, setTitle] = useState("");

    return <Dialog fullWidth maxWidth="sm" onClose={onClose} open={true}>
        <DialogTitle>Create chat</DialogTitle>
        <DialogContent>
            <TextField 
                autoFocus
                value={title} 
                onChange={e => setTitle(e.target.value)} 
                label="Chat title" 
                placeholder="Please enter chat title" 
            />
        </DialogContent>
        <DialogActions>
            <Button noFullWidth onClick={onClose}>Cancel</Button>
            <Button 
                noFullWidth
                onClick={() => onSave(title)}
                variant="contained" 
                color="primary"
            >Create</Button>
        </DialogActions>
    </Dialog>
}