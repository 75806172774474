import { useHistory } from "react-router-dom";
import { Course } from "./types";
import { FieldType, Schema } from "../../toolympus/hooks/useSchema";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { useNewItem } from "../../toolympus/api/useNewItem";

export const CourseSchema: Schema = {
  title: { label: "Title*" },
  shorttitle: { label: "Short title" },
  kind: { label: "Kind*" },
  tutors_ids: { label: "Tutors", type: FieldType.dictionarySelectMulti, dictionary: "Tutors" },
  description: { label: "Description", type: FieldType.textlong },

}

export const useCourse = (courseId: string) => {
  const data = useCrudItem<Course>(`/api/course/${courseId}`, {
    defaultValue: {} as Course,
    returnPath: "/course",
  });

  return data;
}

export type CourseEditData = ReturnType<typeof useCourse>;

export const useNewCourse = (): CourseEditData => {
  const data = useNewItem<Course, Course>(
    "/api/course",
    {
      description: "",
      title: "",
      kind: "",
      tutors_ids: [],
      shorttitle: ""
    } as any,
    { autoStartEdit: true });


  const history = useHistory();

  return {
    data: data.item as Course,
    changes: data.item as Partial<Course>,
    hasChanges: true,
    isLoading: data.isLoading,
    reload: () => Promise.resolve(data.item as Course),
    save: () => data.save().then(course => { history.push(`/course/${course._id}`); return course; }),
    remove: () => Promise.resolve(data.item as Course),
    update: data.update,
    setData: data.update,
  }
}
