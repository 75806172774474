import styled from '@emotion/styled';
import { Button, IconButton } from '@mui/material';
import { CloudDownload, DeleteOutlined } from '@mui/icons-material';
import React from "react";
import { RegistrationReviewPopup } from '../../../toolympus/components/Contests/Registrations';
import { FormGrid } from '../../../toolympus/components/primitives';
import { useApprovalReview } from '../Common/useApprovalReview';

import { ApplicationReviewType, downloadDocument } from '../Common/FormComponents';
import { ApprovalReviewCommittee } from '../Common/stages/ApprovalReviewCommittee';
import { ApprovalReviewRACStage } from '../Common/stages/ApprovalReviewRACStage';
import { ApprovalReviewResult } from '../Common/stages/ApprovalReviewResult';
import { Application, ApplicationsList } from './useApplicationsList';
import { FieldDefinition } from '../../../toolympus/components/schemed';
import { ProtectedImg } from '../../../toolympus/components/medialib';

const AvatarWrapper = styled.div`
  & > img {
    width: 100%;
    aspect-ratio: 1;
    border: 3px solid ${props => props.theme.palette.primary.main};
  }
`;

export const ApplicationReview = ({ data, type }: { data: ApplicationsList, type: ApplicationReviewType }) => {
    const review = useApprovalReview(data.review, data);
    const approvalReviewProps = { review, id: data.review.id, reload: data.reload, type  }

    const controls = (fields: FieldDefinition[]) => data.review.controls(
        fields.map(([f, options]) => [f, { ...(options || {}), readOnly: type === "committee"}])
    );

    return <RegistrationReviewPopup
        data={data.review}
        dialogProps={{ maxWidth: "md" }}
        title="Application"
        titleActions={<>
            {type === "admin_applications" && <IconButton size="small" onClick={() => review.removeApplication.run()}><DeleteOutlined /></IconButton>}
        </>}>
        <FormGrid columns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)">
            <ApprovalReviewRACStage {...approvalReviewProps} />
            <ApprovalReviewCommittee {...approvalReviewProps} data = {data} user={review.user} />
            <ApprovalReviewResult {...approvalReviewProps} participants = {review.users} setApproval={review.saveApproval} />
            {controls([
                ["firstname"],
                ["lastname"],
                ["middlename"],
            ])}
        </FormGrid>

        <FormGrid columns="2fr 1fr" forceEvenColumns noMargin>
          <FormGrid columns="1fr 1fr" forceEvenColumns noMargin>
            {controls([
              ["preferred_name"],
              ["title"],
              ["gender"],
              ["birthdate"],
              ["nationality"],
              ["country"],
              ["timezone"],
              ["email", { processChange: v => (v || "").replace(/\s+/g, "")}],
              ["phone"],
            ])}
          </FormGrid>

          <FormGrid columns="1fr" noMargin>
            {data.review.data?.avatar_id && <AvatarWrapper>
              <ProtectedImg src={`/api/application/avatar/${data.review.data?.avatar_id}`} />
            </AvatarWrapper>}
            {[
              { label: "Download Motivation Letter", document_id: `${data.review.data?.ml_document_id}` },
              { label: "Download CV", document_id: `${data.review.data?.cv_document_id}` },
              ].map((button) => (
                <Button
                  color="primary"
                  key={button.label}
                  startIcon={<CloudDownload />}
                  onClick={() => { downloadDocument({ document_id: button.document_id }) }}>
                  {button.label}
                </Button>))}
          </FormGrid>
        </FormGrid>


        <FormGrid columns="1fr">
            {controls([
                ["education", { autoRows: true }],
                ["experience", { autoRows: true }],
                ["how_did_you_learn", { autoRows: true }],
                ["wa_participation"],
                ["wa_participation_year", { visibleIf: (row: Application) => row.wa_participation === "yes", label: "Past participation year" }],
                ["question1", { autoRows: true }],
                ["question2", { autoRows: true }],
                ["question3", { autoRows: true }],
            ])}
        </FormGrid>
    </RegistrationReviewPopup>
}