import { useCrud } from "../common/useCrud"
import { emptySchedule } from "./emptyObjects"
import { Schedule } from "./types"
import React from "react";
import { toLocal, toServer } from "../common/timezone";

const convertToMoscow = (body: Schedule) => {
    const { start_datetime, end_datetime } = body;

    return {
        ...body,
        start_datetime: toServer(start_datetime),
        end_datetime: toServer(end_datetime),
    }
}

export const useSchedules = (courseId: string) => {
    const api = useCrud<Schedule, Schedule[]>({
        url: `/course/${courseId}/schedule`,
        initialState: [],
        run: !!courseId,
        effectOps: [courseId]
    });

    return {
        ...api,
        data: api.data
            .map(({start_datetime, end_datetime, ...data}) => {
                return {
                    start_datetime: toLocal(start_datetime),
                    end_datetime: toLocal(end_datetime),
                    ...data
                };
            }),
        setData: ((list: Schedule[]) => api.setData(list.map(convertToMoscow))) as React.Dispatch<React.SetStateAction<Schedule[]>>,
        save: (body: Schedule) =>  api.save(convertToMoscow(body)),
        add: () => api.save(convertToMoscow(emptySchedule(courseId))),
    }
}

export type SchedulesEditData = ReturnType<typeof useSchedules>;
