import React, { PropsWithChildren } from 'react';
import { DictionariesProvider } from '../../toolympus/hooks/useDictionaries';

export const AcademyDictionariesProvider = (props: PropsWithChildren<{}>) => {
  return (
    <DictionariesProvider apiPath="/api/dictionary">
      {props.children}
    </DictionariesProvider>
  );
}
