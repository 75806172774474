import React, { ReactNode } from 'react';
import { InviteTutorsData } from '../useInviteTutors';
import { Dialog, FormGrid, LoadingIndicator, SimpleDialog } from '../../../toolympus/components/primitives';
import { Button, Typography } from '@mui/material';
import { EmailTemplateSelector } from '../../../toolympus/components/emails';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Checkbox } from '../../../toolympus/components/schemed/Checkbox';
import { ActionStatusBlip } from '../../../toolympus/components/ConfigurableForms/ConfigurableFormSubmissions';


interface Props {
  data: InviteTutorsData;
  label?: ReactNode;
}

export const InviteParticipantsDialog = (props: Props) => {
  const { data } = props;

  return (<>
    <Dialog
      dialogTitle={props.label || "Send invites"}
      noFullscreen
      submitOnModEnter
      isOpen={data.isEditing}
      close={data.cancel}
      actions={!data.send.actionInfo
        ? <>
          <Button onClick={() => data.test.start()} disabled={!data.test.canStart} startIcon={data.test.isLoading ? <LoadingIndicator sizeVariant="font" /> : undefined}>
            test
          </Button>
          <Button onClick={() => data.send.start()} disabled={!data.send.canStart} color="primary">
            send invites
          </Button>
          <Button onClick={data.cancel}>
            <FormattedMessage id="common.cancel" />
          </Button>
        </>
        : <>
          <Button color="primary" onClick={() => data.send.run()} startIcon={data.test.isLoading ? <LoadingIndicator sizeVariant="font" /> : undefined}>
            send
          </Button>
          <Button onClick={() => {
            if(data.send.isFinished) {
              data.cancel();
            }
            data.send.cancel();
          }}>
            <FormattedMessage id="common.cancel" />
          </Button>
        </>}>
        {!!data.item && !data.send.actionInfo &&
          <FormGrid noMargin columns="1fr">
            <EmailTemplateSelector
              field="template"
              row={data.item}
              onChange={(o,c) => data.update(c)}
              schema={{}}
              apiPath="/api/emails"
              errors={data.errors}
              extraProps={{ autoComplete: true }}
              />

            <Typography variant="caption" color="textSecondary">
              {data.selection.selectedItems.length} / {(data.item?.tutors || []).length} selected
              &nbsp;<Typography variant="caption" color="primary" onClick={() => data.selection.selectAll(true)}>select all</Typography>
              &nbsp;or
              &nbsp;<Typography variant="caption" color="primary" onClick={() => data.selection.selectAll(false)}>unselect all</Typography>
            </Typography>

            <FormGrid noMargin columns="1fr 1fr" forceEvenColumns alignItems="center" gap="dense">
              {data.item?.tutors?.map(profile => (
                <React.Fragment key={profile._id}>
                  <Checkbox
                    key={profile._id}
                    field="selected"
                    schema={{ label: `${profile.title || ""} ${profile.firstname} ${profile.lastname}`}}
                    row={{ selected: data.selection.isSelected(profile) }}
                    onChange={(o,c) => data.selection.select(profile, c.selected)}
                    />
                  
                </React.Fragment>
              ))}
            </FormGrid>
            
          </FormGrid>}

        {!!data.item && !!data.send.actionInfo &&
          <FormGrid noMargin columns="1fr">
            <Typography variant="caption" color="textSecondary">
              Please confirm sending invites to the following {data.send.actionInfo.items.length} participants
            </Typography>

            <Typography variant="caption" color="textSecondary">
              (an invite will be sent even if the participant was previously invited)
            </Typography>


            <FormGrid noMargin columns="max-content 1fr">
              {data.send.actionInfo.items.map((profile,idx) => (
                <React.Fragment key={profile._id}>
                  <ActionStatusBlip status={data.send.status[idx]} whenEmpty={<span/>} /> <Typography>{profile.title || ""} {profile.firstname} {profile.lastname}</Typography>
                </React.Fragment>
              ))}
            </FormGrid>
            
          </FormGrid>}
    </Dialog>

    <SimpleDialog
      dialogTitle="Test invites"
      isOpen={data.test.isEditing}
      close={data.test.cancel}
      noFullscreen
      saveLabel="Send"
      save={() => data.test.save()}>

      {!!data.test.item && <FormGrid columns="1fr">
        <Typography variant="caption" color="textSecondary">Please enter an email address below. We will send an invite to the provided email as if it was sent to the selected participant</Typography>

        <FormControlsForFields
          data={data.test.item}
          onChange={(o,c) => data.test.update(c)}
          schema={{ test_email: { label: "Email" }}}
          fields={[
            ["test_email"]
          ]}
          />

        <Typography>Participant: {data.test.item.tutors[0]?.title || ""} {data.test.item.tutors[0]?.firstname || ""} {data.test.item.tutors[0]?.lastname || ""}</Typography>
      </FormGrid>}
    </SimpleDialog>
  </>);
}
