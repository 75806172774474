import React, { useMemo, useState } from "react";
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import { FileEdit } from "../../toolympus/components/files/FileEdit";
import { FormControlsForFields } from "../../toolympus/components/schemed";
import { BodyBg, MainBox } from "../Login/LoginForm.style";
import { Title, Section, FormContainer, Hint, ApplicationContainer, StyledLink, FileUploadLabel, QuestionWrapper, PersonalInfoForm, InfoPopover, InfoPopoverButtons } from "./ApplicationForm.style";
import { useApplication, UseApplicationProps } from "./useApplication";
import { FormGrid } from "../../toolympus/components/primitives/Forms";
import { LoadingIndicator } from "../../toolympus/components/primitives/LoadingIndicator";
import { useItemWithControls } from "../../toolympus/api/useWithControls";
import { createSelectSchema, FieldSchema, mergeSchema } from "../../toolympus/hooks/useSchema";
import { PossiblyProtectedImg } from "../../toolympus/components/medialib";
import styled from '@emotion/styled';
import { Close } from "@mui/icons-material";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { CMSRecord } from "../../toolympus/components/CMS";
import { CustomElement } from "../../slate";
import { Tile } from "../../toolympus/components/PowerDoc/plugins/Tiles/useTiles";
import { CustomFieldsBlockElementType, extractCustomBlockFields } from "../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { PowerEditorBase } from "../../toolympus/components/PowerDoc";
import { generateCode } from "../../toolympus/api/data";
import { messageWithButtonParts } from "../MyProfile/presentation/MyProfilePage";

const AvatarWrapper = styled(FormGrid)<{ isError?: boolean }>`
  text-align: center;

  & .file-editor {
    border: 3px solid ${props => props.isError ? props.theme.palette.error.light : "transparent"};
    border-radius: 4px;
  }

  & > img {
    width: 100%;
    aspect-ratio: 1;
    border: 3px solid ${props => props.theme.palette.primary.main};
  }
`;
AvatarWrapper.defaultProps = { columns: "1fr" };

interface FormProps {
    data: UseApplicationProps;
    questionTexts: Tile[];
    serviceMessages: Record<string, any>;
    config: Record<string, string>;
    contentKey: string;
}

const PDCLabel = (props: { messages: Record<string, string> }) => {
  
  return (<span>
    {messageWithButtonParts(props.messages.pdc || "").map(part => (
      part.type === "button"
        ? <StyledLink key={part.text} href={props.messages.pdc_link} target="_blank" rel="noreferrer noopener">{part.text}</StyledLink>
        : <span key={part.text}>{part.text}</span>
    ))}
    </span>)
};


const Form = (props: FormProps) => {
    const { 
        schema, 
        isSaved,
        data, 
        update, 
        clear,
        send, 
        isSaving,
        errors,
        validate
    } = props.data;
    const { questionTexts, config } = props;

    const valid = Object.keys(validate).reduce((acc, key) => (validate as any)[key].valid && acc, true);

    const schemaFull = useMemo(() => {
      const fieldsSchemasFromConfig = Object.keys(schema || {}).reduce<Record<string, FieldSchema>>((r,f) => {
        if(config[f] || config[`${f}_hint`]) {
          r[f] = {};
          if(config[f]) {
            r[f].label = config[f];
          }
          if(config[`${f}_hint`]) {
            r[f].hint = config[`${f}_hint`];
          }
          // r[f] = { label: config[f], hint: config[`${f}_hint`]};
        }
        return r;
      }, {});
      fieldsSchemasFromConfig.title = createSelectSchema(
        (config.title_options || "").split("\n").map(s => s.trim()).filter(s => !!s).map(o => ({ value: o, label: o })),
        fieldsSchemasFromConfig.title);

      return mergeSchema(
        schema,
        fieldsSchemasFromConfig,
      )
    }, [schema, config]);

    const { controls } = useItemWithControls({ data, update, errors }, { schema: schemaFull });

    const [isInfoPopoverOpen, setIsInfoPopoverOpen] = useState<boolean>(true);

    return <form>
            <FormContainer>

                    {isInfoPopoverOpen && <InfoPopover>
                        <Typography>{props.serviceMessages?.autosave_message || "The form is being auto-saved in your browser (except for the files), just in case.\n\nIf you want to start from scratch, just clear the form."}</Typography>

                        <InfoPopoverButtons>
                            <Button size="small" onClick={() => { clear(); setIsInfoPopoverOpen(false); }}>{props.serviceMessages?.autosave_clear || "clear form"}</Button>
                            <Button size="small" color="primary" variant="contained" onClick={() => setIsInfoPopoverOpen(false)}>{props.serviceMessages?.autosave_ok || "ok"}</Button>
                        </InfoPopoverButtons>
                    </InfoPopover>}

                    <Section>
                        <Title>{config.personal_details_title || "Your Personal Details"}</Title>
                        <PersonalInfoForm key="names">
                            {controls([
                                    ["firstname"],
                                    ["lastname"],
                                    ["middlename"],
                                ])}
                        </PersonalInfoForm>

                        <PersonalInfoForm key="names-preferred" columns="1fr" noMargin>
                            {controls([
                                    ["preferred_name"],
                                ])}
                        </PersonalInfoForm>

                        <PersonalInfoForm key="info" noMargin>
                            {controls([
                                    ["title", { autoComplete: true }],
                                    ["gender"],
                                    ["birthdate"],
                                    ["nationality"],
                                    ["country"],
                                    ["timezone"],
                                ])}
                        </PersonalInfoForm>

                        <PersonalInfoForm key="contact" columns="1fr 1fr">
                            {controls([
                                    ["email"],
                                    ["phone"],
                                ])}
                        </PersonalInfoForm>
                    </Section>

                    <Section>
                      <Title>
                        {config.photo_title || "Please upload your photo"}
                        {!!data.avatar_id && <IconButton size="small" onClick={() => update({ avatar_id: null })}>
                            <Close />
                          </IconButton>}
                      </Title>

                      <FormGrid columns="repeat(3,1fr)" forceEvenColumns>
                          <div />
                          <AvatarWrapper noMargin isError={errors.fieldHasErrors("avatar_id")}>
                            <Typography variant="caption" color="textSecondary">{config.avatar_id_hint}</Typography>
                            {data.avatar_id
                              ? <PossiblyProtectedImg protected src={`/api/application/avatar/${data.avatar_id}`} />
                              : <FileEdit
                                  fileId={data.avatar_id}
                                  updateFileId={v => update({ avatar_id: v })}
                                  config={{
                                    apiPath: "/api/application/avatar",
                                  }}
                                />}
                          </AvatarWrapper>
                        </FormGrid>
                    </Section>


                    <Section>
                        <Title>{config.education_and_experience_title || "Your Education and Experience"}</Title>

                        <FormGrid columns="1fr">
                            {controls([
                              ["education", { autoRows: true }],
                              ["experience", { autoRows: true }],
                              ["how_did_you_learn", { autoRows: true }],
                            ])}

                            <FormControl error={errors.fieldHasErrors("wa_participation")}>
                                <FormLabel>{config.wa_participation}</FormLabel>
                                <RadioGroup row value={data.wa_participation} onChange={(_, value) => update({ wa_participation: value })}>
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>

                            {controls([
                                    ["wa_participation_year", { visibleIf: (row) => row.wa_participation === "yes", label: errors.fieldHasErrors("wa_participation_year")}],
                                ])}
                            
                            <div key="cv_document_id">
                                <FileUploadLabel hasError={errors.fieldHasErrors("cv_document_id")}>{config.cv_document_id}</FileUploadLabel>
                                <FileEdit 
                                    preventDownload
                                    fileId={data.cv_document_id} 
                                    updateFileId={id => update({ cv_document_id: id || "" })} 
                                    config={{ apiPath:"/api/application/document", dontRemoveFile: true }}
                                />
                                <FormHelperText>{config.files_upload_format_note}</FormHelperText>
                            </div>
                        </FormGrid>
                    </Section> 
                    <Section>
                        <Title>{config.motivation_title || "Your Motivation"}</Title>

                        <FormGrid columns="1fr">
                            <div>
                                <FileUploadLabel hasError={errors.fieldHasErrors("ml_document_id")}>{config.ml_document_id}</FileUploadLabel>
                                <FileEdit 
                                    preventDownload
                                    fileId={data.ml_document_id} 
                                    updateFileId={id => update({ ml_document_id: id || "" })} 
                                    config={{ apiPath:"/api/application/document", dontRemoveFile: true }}
                                />
                                <FormHelperText>{config.files_upload_format_note}</FormHelperText>
                            </div>
                        </FormGrid>
                    </Section>

                    <Section key="questions">
                        <Title>{config.adr_knowledge_title || "ADR Knowledge Valuation Test"}</Title>

                        <FormGrid columns="1fr">
                            <QuestionWrapper key="question1">
                                <CmsTextViewer key={props.contentKey} content={questionTexts[0]?.content} />
                                <FormControlsForFields
                                    errors={errors} 
                                    data={data}
                                    schema={schema}
                                    fields={[
                                        ["question1", {
                                            label: " ",
                                            autoRows: true,
                                            hint: <Hint invalid={!validate.question1.valid}>{validate.question1.words} / 500 words</Hint>,
                                        }],
                                    ]}
                                    onChange={(_, changes) => update(changes)}
                                />
                            </QuestionWrapper>

                            <QuestionWrapper key="question2">
                                <CmsTextViewer key={props.contentKey} content={questionTexts[1]?.content} />
                                <FormControlsForFields
                                    errors={errors} 
                                    data={data}
                                    schema={schema}
                                    fields={[
                                        ["question2", {
                                            label: " ",
                                            autoRows: true,
                                            hint: <Hint invalid={!validate.question2.valid}>{validate.question2.words} / 500 words</Hint>,
                                         }],
                                    ]}
                                    onChange={(_, changes) => update(changes)}
                                />
                            </QuestionWrapper>

                            <QuestionWrapper key="question3">
                                <CmsTextViewer key={props.contentKey} content={questionTexts[2]?.content} />
                                <FormControlsForFields
                                    errors={errors} 
                                    data={data}
                                    schema={schema}
                                    fields={[
                                        ["question3", {
                                            label: " ",
                                            autoRows: true,
                                            hint: <Hint invalid={!validate.question3.valid}>{validate.question3.words} / 500 words</Hint>,
                                        }],
                                    ]}
                                    onChange={(_, changes) => update(changes)}
                                />

                            </QuestionWrapper>

                            {controls([
                                    ["camera_acknowledged"],
                                    ["pdc", { label:  <PDCLabel messages={config} /> }]
                                ])}
                        
                            {!isSaved && valid && 
                                <Button 
                                    color="primary" 
                                    variant="contained"
                                    disabled={isSaving}
                                    onClick={() => send()}
                                    style={{ justifySelf: "center", minWidth: "200px" }}
                                >
                                    {isSaving ? <LoadingIndicator sizeVariant="font" /> : config.submit}
                                </Button>
                            }
                        </FormGrid>
                    </Section>

            </FormContainer>
        </form>;
}

const useApplicationFormPage = () => {
  const data = useLoadedData<CMSRecord>(`/api/cms/public/public_docs/application_form`, { _id: "", } as any);

  const [contentNormalBeforeForm, contentNormalAfterForm, contentSuccess, contentWhenDisabled, formConfig, questions, serviceMessages, key] = useMemo(() => {
    const blocks: CustomElement[] = (data.data.content as any)?.blocks || [];

    const contentBlocks = blocks.filter(b => (b as any).is_service !== "true");
    const successBlock = contentBlocks.find(b => (b as any).is_success === "true");
    const whenDisabledBlock = contentBlocks.find(b => (b as any).is_when_disabled === "true");
    const normalBlocks = contentBlocks.filter(b => (b as any).is_success !== "true" && (b as any).is_when_disabled !== "true");

    const [normalBlocksBeforeForm, formConfig, normalBlocksAfterForm] = normalBlocks.reduce<[any,any,any]>(([before,form,after],b) => {
      if(b.type === CustomFieldsBlockElementType) {
        const fields = extractCustomBlockFields(b);
        if(fields.block_type === "application_form") {
         return [before, fields, []]
        }
      }

      if(form) {
        after.push(b);
      } else {
        before.push(b);
      }
      return [before,form,after];
    }, [[],null,[]]);

    const questionsBlock = blocks.find(b => (b as any).block_type === "questions");
    const questions: Tile[] = (questionsBlock as any)?.tiles || [];

    const serviceMessages = blocks.filter(b => b.type === CustomFieldsBlockElementType).map(b => extractCustomBlockFields(b)).find(f => f.block_type === "service_messages");

    return [
      { blocks: normalBlocksBeforeForm },
      { blocks: normalBlocksAfterForm },
      { blocks: successBlock?.content?.blocks },
      { blocks: whenDisabledBlock?.content?.blocks },
      formConfig as Record<string, string>,
      questions,
      serviceMessages,
      generateCode(),
    ]
  }, [data.data]);

  return {
    contentNormalBeforeForm,
    contentNormalAfterForm,
    contentSuccess,
    contentWhenDisabled,
    formConfig,
    questions,
    serviceMessages,
    key,
  }
}

export const CmsTextViewer = (props: { content: any }) => {
  return (
    <PowerEditorBase
      viewMode
      content={props.content}
      update={() => {}}
      placeholder=" "
      />
  )
}

export const ApplicationForm = () => {
    const formData = useApplication();
    const  { 
        schema, 
        formState,
    } = formData;

    const pageX = useApplicationFormPage();

    return <ApplicationContainer>
        <BodyBg />

        <MainBox>
            {schema && (<>
              {formState === "normal" &&
                <CmsTextViewer
                  key={`${pageX.key}_normal1`}
                  content={pageX.contentNormalBeforeForm}
                  />}
              {formState === "normal" &&
                <Form
                  data={formData}
                  contentKey={`${pageX.key}_normal2`}
                  questionTexts={pageX.questions}
                  serviceMessages={pageX.serviceMessages || {}}
                  config={pageX.formConfig || {}}
                  />}

              {formState === "normal" &&
                <CmsTextViewer
                  key={`${pageX.key}_normal3`}
                  content={pageX.contentNormalAfterForm}
                  />}

              {formState === "success" &&
                <CmsTextViewer
                  key={`${pageX.key}_success`}
                  content={pageX.contentSuccess}
                  />}

              {formState === "disabled" &&
                <CmsTextViewer
                  key={`${pageX.key}_disabled`}
                  content={pageX.contentWhenDisabled}
                  />}
            
            </>
            )}
        </MainBox>

    </ApplicationContainer>
}