import React from 'react';
import { useStudentsManagementList } from './useStudentsManagementList';
import { FormControlsForFields, TableForFields } from '../../../toolympus/components/schemed';
import { Schema, createSelectSchema } from '../../../toolympus/hooks/useSchema';
import { ActionRow, ButtonOptionPicker, Buttons, FormGrid, Link, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, SimpleDialog, useTabsState } from '../../../toolympus/components/primitives';
import { Button, IconButton, Typography } from '@mui/material';
import { CallMadeOutlined, Check, GetAppOutlined, QuestionMark, ReceiptLongOutlined } from '@mui/icons-material';
import { useDownloadFile } from '../../../toolympus/hooks/useDownloadFile';
import { StudentsAttendanceTable } from './StudentsAttendanceTable';

interface Props {
  
}

const StudentSchema: Schema = {
  name: { label: "Student" },
  consent_form_id: { label: "Consent form" },
  attendance_pct: { label: "Attendance, %" },
  attendance_status: createSelectSchema([
    { value: "attended", label: "Attended"},
    { value: "with-mootcourt", label: "Attended+MC"},
    { value: "best-oralist", label: "Best oralist"}],

    { label: "Attendance status" }),
  certificate: { label: "Certificate" },
}

export const StudentsManagementList = (props: Props) => {
  const data = useStudentsManagementList();

  const certificates = useDownloadFile("");

  const consentForms = useDownloadFile("/api/consent-form/export", `Winter Academy ${new Date().getFullYear()} consent forms.zip`);

  const tabs = useTabsState([
    ["list","List"],
    ["attendance","Attendance"],
  ]);

  return (
    <>
      <ActionRow>
        <ButtonOptionPicker
          options={tabs.tabs.map(([v,l]) => ([l,v]))}
          selected={tabs.current}
          setSelected={tabs.setCurrent}
          />
        <OccupyFreeSpace />

        <Button
          size="small"
          color="primary"
          onClick={() => data.recalcAttendance.startEditing({ mootcourt_slot: null, ignore_slots: [] })}
          >
          recalc attendance
        </Button>

        <Button
          size="small"
          color="primary"
          startIcon={consentForms.isLoading ? <LoadingIndicator sizeVariant="s" color="inherit" /> : <GetAppOutlined />}
          onClick={() => consentForms.download()}
          >
          Export consent forms
        </Button>
        <SearchField
          {...data.filter}
          noButton
          autoFocus
          />
      </ActionRow>

      {tabs.current === "list" &&
      <TableForFields
        data={data.students.data}
        fields={[
          ["name"],
          ["consent_form_id"],
          ["attendance_pct"],
          ["attendance_status"],
          ["certificate"],
        ]}
        schema={StudentSchema}
        fieldElement={f => {
          switch(f) {
            case "name":
              return (row,s,orig) => <Buttons>
                <PseudoLink2 border onClick={() => data.editStudent.startEditing({ ...row })}>{row.title} {row.firstname} {row.lastname}</PseudoLink2> <Link to={`/system/users?filter=${row._id}`}><IconButton size="small"><CallMadeOutlined /> </IconButton></Link>
                </Buttons>;
            case "attendance_status":
            case "attendance_pct":
              return (row,s,orig) => <PseudoLink2 border onClick={() => data.editStudent.startEditing({ ...row })}>{orig}</PseudoLink2>;
            case "consent_form_id":
              return row => <Buttons>{row.consent_form_id ? <Check color="success" /> : <QuestionMark color="error" />}</Buttons>;
            case "certificate":
              return row => <Buttons>
                <IconButton size="small" color="primary" onClick={() => certificates.download({ apiPath: `/api/certificate/${row._id}`, filename: `Winter Academy ${new Date().getFullYear()} ${row.firstname} ${row.lastname}.pdf` })}>
                  {certificates.isLoading ? <LoadingIndicator sizeVariant="s" color="inherit" /> : <ReceiptLongOutlined color='primary' />}
                </IconButton>
              </Buttons>;
          }
        }}
        />}


      {tabs.current === "attendance" &&
        <StudentsAttendanceTable data={data} />}

      <SimpleDialog
        dialogTitle="Edit student"
        save={() => data.editStudent.save()}
        isOpen={data.editStudent.isEditing}
        close={() => data.editStudent.cancel()}>
          {!!data.editStudent.item &&
            <FormGrid columns="1fr">
              <FormControlsForFields
                data={data.editStudent.item}
                schema={StudentSchema}
                onChange={(o,c) => data.editStudent.update(c)}
                fields={[
                  ["lastname", { readOnly: true }],
                  ["attendance_pct", { readOnly: true }],
                  ["attendance_status"],
                ]}
                />
            </FormGrid>}
      </SimpleDialog>


      <SimpleDialog
        dialogTitle="Recalc attendance"
        save={() => data.recalcAttendance.save()}
        isOpen={data.recalcAttendance.isEditing}
        close={() => data.recalcAttendance.cancel()}
        noFullscreen
        saveLabel="Recalc">
          <Typography>
            Attendance % and attendance status for every student will be recalculated based on the attendance table.
          </Typography>
          {!!data.recalcAttendance.item &&
            <FormGrid columns="1fr">
              <FormControlsForFields
                data={data.recalcAttendance.item}
                onChange={(o,c) => data.recalcAttendance.update(c)}
                schema={data.recalcAttendanceSchema}
                fields={[
                  ["mootcourt_slot"],
                  ["ignore_slots"],
                ]}
                />
            </FormGrid>}
      </SimpleDialog>
    </>
  );
}
