import { apiFetch } from "../../toolympus/api/core";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { ProfileData } from "./types";

const entryProfile = () => ({
    _id: "",
    email: "",

    lastname: "",
    firstname: "",

    company: "",
    position: "",

    bio: "",
    
    consent_share_profile: false,
    consent_email_show: false,
    title: "",
    interests: "",
    linkedin_url: "",
    consent_email_org: false,
});

export const useProfile = () => {
    const x = useCrudItem("/api/profile", {
      defaultValue: entryProfile(),
    })
    

    return {
      ...x,
      save: (extra?: Partial<ProfileData>) => {
        if(x.data._id) {
          return x.save();
        } else {
          const newProfile: Partial<ProfileData> = { ...x.data };
          delete newProfile._id;
          return apiFetch<ProfileData>("/api/profile", "post", newProfile)
            .then(r => {
              x.reload();
              return r;
            })
        }
      }
    };
}
