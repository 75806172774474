import React from 'react';
import { DocumentationRoutes } from '../../toolympus/components/Documentation';
import { MediaLibProvider } from '../../toolympus/components/medialib';

export const Documentation = () => {
    return (
        <MediaLibProvider spaceId="1" apiPath="/api/medialib" getFilepath={mf => `/api/medialib/${mf.space_id}/${mf._id}`} protectedFiles>
            <DocumentationRoutes
                apiPath="/api/documentation"
                rootSlug="root"
                defaultRootPageTitle="Documentation"
                />
        </MediaLibProvider>
    );
}
