import { useEffect, useState } from "react";
import { apiFetch, apiUploadFile, downloadFile } from "../../api/core";
import { FileInfo, toUrlParams } from "./useFileList";

export interface FileEditData {
    file: FileInfo | null;
    upload: (file: File) => Promise<FileInfo>;
    download: () => void;
    remove: () => Promise<void>;
    isLoading: boolean;
    reset: () => void;
}

export interface FileEditConfig {
    apiPath: string;
    uploadParams?: Record<string, any>;
    formField?: string;
    onRemove?: (fileId: string) => void;
    onUpload?: (fileInfo: FileInfo) => void;
    dontControlFile?: boolean;
    dontRemoveFile?: boolean;
}

export const useFileEdit = (fileId: string | null | undefined, cfg: FileEditConfig): FileEditData => {
    const [file, setFile] = useState<FileInfo | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if(fileId && (!file || file._id !== fileId)) {
            setIsLoading(true);
            apiFetch<FileInfo>(`${cfg.apiPath}/${fileId}/info`)
                .then(fi => {
                    setIsLoading(false);
                    setFile(fi);
                })
                .catch(e => {
                    setIsLoading(false);
                    throw e;
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileId]);

    const upload = (f: File) => {
        setIsLoading(true);
        return apiUploadFile(`${cfg.apiPath}?${toUrlParams(cfg?.uploadParams)}`, "post", cfg.formField || "file", f)
            .then(response => {
                const fi = response.data as FileInfo;

                if(!cfg.dontControlFile) {
                    setFile(fi);
                }

                setIsLoading(false);
                if(cfg.onUpload) {
                    cfg.onUpload(fi);
                }
                return fi;
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
    }

    const remove = () => {
        if(!file) {
            throw new Error("cant_remove_empty_file");
        }

        if(cfg.dontRemoveFile) {
            const fileId = file._id;

            if(!cfg.dontControlFile) {
                setFile(null);
            }
            
            if(cfg.onRemove) {
                cfg.onRemove(fileId);
            }
            return Promise.resolve();
        }

        setIsLoading(true);
        return apiFetch(`${cfg.apiPath}/${file._id}`, "delete")
            .then(() => {
                const fileId = file._id;
                
                if(!cfg.dontControlFile) {
                    setFile(null);
                }

                setIsLoading(false);
                if(cfg.onRemove) {
                    cfg.onRemove(fileId);
                }
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
    }

    const download = () => {
        if(file) {
            downloadFile(`${cfg.apiPath}/${file._id}`, file.filename);
        }
    }

    const reset = () => {
      setFile(null);
      setIsLoading(false);
    }

    return {
        file,
        upload,
        remove,
        download,
        isLoading,
        reset,
    }
}
