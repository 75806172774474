import { Person } from "./types";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";

export const useOrganizersChat = () => {
  // const [chatId, setChatId] = useState<string>("");
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   setIsLoading(true);

  //   apiFetch<ChatItem>("/api/chat/org", "get")
  //     .then(chat => {
  //       setChatId(chat._id);
  //       setIsLoading(false);
  //     })
  //     .catch(() => {
  //       setIsLoading(false);
  //     });
      
  // }, []);

  return {
      isLoading: false,
      chatLink: '/public/message/org',
  };
}

export const useOrganizers = () => {
  return useLoadedData<Person[]>("/api/participant?view=organizers", []);
}