import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import React from "react";
import { SectionTitle, Tooltip } from '../../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../../toolympus/components/schemed';
import { FieldType } from '../../../../toolympus/hooks/useSchema';
import { DefaultUser, IUserContext } from '../../../../toolympus/userContext/UserContext';
import { Participants } from '../../../Participants/usePaticipants';
import { ApprovalReview } from '../useApprovalReview';
import { ApplicationApprovalIcons, ApprovalButtonsIcons, ApprovalCommittee } from '../FormComponents';
import { ApplicationsList } from '../../Form/useApplicationsList';
import { InfoOutlined } from '@mui/icons-material';

const CommitteeMembers = styled.div`
  & > :first-child {
    margin-top: 3px;
  }
  `;

const CommitteesInformation = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Committee = styled.div`
    margin: 30px 0;
`;


const Title = styled(SectionTitle) <{ direction: string }>`
    text-align: ${props => props.direction};
`;

const ApprovalReviewInformation = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SaveButtonWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`;

const SaveButton = styled(Button)`
`
SaveButton.defaultProps = { color: "primary", size: "small" };

export type ApprovalReviewStage = {
    review: ApprovalReview,
    id: string | null,
    type: string,
    reload: () => void,
    data?: ApplicationsList,
    user?: IUserContext<DefaultUser>,
    participants?: Participants,
    setApproval?: (review: { status: string; id: string; reload: () => void; isApprovalFinal?: boolean | undefined; }) => void
};

export const ApprovalReviewCommittee = (props: ApprovalReviewStage) => {
    const approvalCommittee = props.data && props.data.review.data?.approval_committee;

    const getMemberProfile = (memberId: string) => props.review.users.data.find(({ _id }) => _id === memberId);

    const committeeMembers = Object.entries(approvalCommittee || {})
        .filter(([memberId, approval]) => memberId !== props.user?.user?._id && !approval.is_preliminary)
        .map(([memberId, committeeApproval]) => {
            const member = getMemberProfile(memberId);
            return {
                firstname: member?.firstname || "",
                lastname: member?.lastname || "",
                comment: committeeApproval.comment,
                approval_status: committeeApproval.approval_status,
            } as ApprovalCommittee;
        })
        .sort((a,b) => a.firstname > b.firstname ? 1 : -1);

    const currentMember = getMemberProfile(props.user?.user?._id || "");
    return <div>
        <Title direction={"center"}>
            Committee Approval
        </Title>

        <CommitteeMembers>
            {
                committeeMembers.length ? committeeMembers.map(committee => {
                    return <Committee>
                        <CommitteesInformation>
                            <Typography>{committee.firstname} {committee.lastname}</Typography>
                            <ApplicationApprovalIcons hasTooltip type='show_only' selectedStatus={committee.approval_status} currentApprovalStatus={committee.approval_status} />
                        </CommitteesInformation>

                        <FormControlsForFields
                            data={{ message: committee.comment }}
                            schema={{
                                message: {
                                    type: FieldType.textlong,
                                    label: "Comments"
                                }
                            }}
                            fields={[
                                ["message", { disabled: true, autoRows: true }],
                            ]}
                            onChange={(original, changes) => null} />
                    </Committee>
                }) : null
            }
        </CommitteeMembers>

        {
            props.type === "committee"
                ? <>
                    <ApprovalReviewInformation>
                        <Typography>{currentMember ? `${currentMember.firstname} ${currentMember.lastname}` : "..."}</Typography>
                        <ApprovalButtonsIcons disable={false} approvalHandler={(text: any) => {
                            props.review.updateApproval({ committeeStatus: text.status })
                        }} approvalStatus={props.review.approval.committeeStatus} direction={"back"} />
                    </ApprovalReviewInformation>

                    <FormControlsForFields
                        data={props.review.approval}
                        schema={{
                            committeeComment: {
                                type: FieldType.textlong,
                                label: "Comments"
                            }
                        }}
                        fields={[
                            ["committeeComment", { autoRows: true }],
                        ]}
                        onChange={(original, changes) => {props.review.updateApproval({ committeeComment: changes.committeeComment })}} />

                    <SaveButtonWrapper>
                        {props.review.isShowSaveButton &&
                          <SaveButton onClick={() => {
                                props.review.saveCommittee({ isPreliminary: true, reload: props.data && props.data.reload });
                            }}>
                                Save preliminary
                          </SaveButton>}

                        {props.review.isShowSaveButton &&
                          <Tooltip text="Preliminary approvals are not shown to other committee members or the organiser. You may change to final later">
                            <InfoOutlined color="primary" />
                          </Tooltip>}

                        {props.review.isShowSaveButton &&
                          <SaveButton variant="contained" onClick={() => {
                                props.review.saveCommittee({ reload: props.data && props.data.reload });
                            }}>
                                Save
                          </SaveButton>}

                        {!props.review.isShowSaveButton && props.review.approval.is_preliminary &&
                          <Tooltip text="Saved preliminary. Preliminary approvals are not shown to other committee members or the organiser.">
                            <InfoOutlined color="primary" />
                          </Tooltip>}

                          {!props.review.isShowSaveButton && props.review.approval.is_preliminary &&
                            <SaveButton variant="contained" onClick={() => {
                                  props.review.saveCommittee({ reload: props.data && props.data.reload });
                              }}>
                                  Turn to final
                            </SaveButton>}
                    </SaveButtonWrapper>
                </>
                : null
        }

    </div>
}
