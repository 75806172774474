import { FetchTypes } from "../../toolympus/api/core"
import {useFetch} from "../../api/useFetch";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Config<A, G> {
    url: string;
    initialState: G;
    notUseIdSufix?: boolean;
    run?: boolean;
    effectOps?: any[];
    onSave?: (data: any) => void;
    onRemove?: () => void;
}

interface SaveConfig {
    duration?: number
}

export const useCrud = <A extends {_id: string}, G = A>({initialState, url, run, notUseIdSufix, effectOps, onSave, onRemove}: Config<A, G>) => {
    const getFetch = useFetch<G>(initialState, {url, method: FetchTypes.GET}, run);

    const actionsFetch = useFetch(null, {depsOfEffect: effectOps}, false);

    const path = (id?: string) => notUseIdSufix? url : `${url}/${id}`;

    const save = (body: A, {duration}: SaveConfig = {}) => {
        const data = {url: `${url}${!!body._id? '/' + body._id : ''}`, body, method: !!body._id? FetchTypes.PUT : FetchTypes.POST}

        return (!duration? actionsFetch.request(data) : actionsFetch.durationRequest(data, duration))
            .then(() => onSave && onSave(actionsFetch.data))
            .then(getFetch.reload);
    }

    const remove = (id?: string) => {
        return actionsFetch
            .request({url: path(id), method: FetchTypes.DELETE})
            .then(() => onRemove && onRemove())
            .then(getFetch.reload);
    }

    return {
        data: getFetch.data,
        setData: getFetch.setData,
        loading: getFetch.loading || actionsFetch.loading,

        getFetch,
        actionsFetch,

        save,
        remove,
    }
}
