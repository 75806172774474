import React, { useState } from 'react'
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator'

import { Button } from '../Button'
import { AvatarContainer, AvatarHint, Image } from './Avatar.style'
import { AvatarApi } from './types'

interface Props {
    avatar: AvatarApi;
    noChange?: boolean
    labels?: Record<string, string>;
}

export const Avatar = (props: Props) => {
    const { avatar: {loading, deletePhoto, upload, userId}, noChange, labels } = props;
    const [version,setVersion] = useState<number>(0);
    const openFileUploader = () => {
        const input = document.createElement('input') as HTMLInputElement;

        input.setAttribute('type', 'file');
        input.onchange = () => { upload((input.files as FileList)[0]); setTimeout(() => { setVersion(x => x+1); }, 2000)};
        input.click();
    }

    return <AvatarContainer className="avatar">
        <Image> {loading? <LoadingIndicator/> : <img src={`/api/avatar/${userId || "default"}${version ? `?v=${version}` : ""}`} alt="avatar"/>}  </Image>
        {noChange || <>
            <Button onClick={openFileUploader} color="primary" variant="outlined">{labels?.upload || "Upload photo"}</Button>
            <Button onClick={deletePhoto} color="secondary" variant="outlined">{labels?.delete || "Delete"}</Button>
            {(!labels || labels.hint) &&
              <AvatarHint>{labels?.hint || "The uploaded image must be 500px wide and 500px long"}</AvatarHint>}
        </>}
    </AvatarContainer>
}