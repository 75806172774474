import { CssBaseline, Drawer, Hidden, IconButton, useTheme } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import React, { ReactNode, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import grey from '@mui/material/colors/grey';

const RootView = styled.div`
    display: flex;
    border-right: 1px solid ${grey[200]};
`

const DrawerView = styled.nav`
    ${props => props.theme.breakpoints.up('sm')} {
        flex-shrink: 0;
    }
`

const DrawerBackGround = styled.div<{color: string}>`
    & .MuiPaper-root {
        background: ${props => props.color};
    }
`;

const DrawerPaperView = styled(Drawer)`
    
    & .MuiDrawer-paper{
        ${props => props.theme.breakpoints.only('xs')} {
            border-left: none;
    }
}
`

const MenuButtomView = styled(IconButton)`
    position: fixed;
    top: 0px;
    right: 0px;
    margin-right: ${props => props.theme.spacing(2)};

    ${props => props.theme.breakpoints.up('sm')} {
        display: none;
    }
`

const BottomView = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`

export interface SidebarProps {
    top?: ReactNode;
    menu?: ReactNode;
    menuSetOpen?: (setOpen: (v: boolean) => void) => JSX.Element;
    bottom?: ReactNode;
    width?: number;
    background?: string;
    noline?: boolean;
    useState?: [boolean, (v: boolean) => void];
}

export const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerToggle = () => {
        setOpen(x => !x);
    };

    const drawer = (
        <div className="drawer-menu-container" style={{height: "100%"}}>
            {props.top || <></>}
            {props.menuSetOpen? props.menuSetOpen(setOpen) : props.menu}
            <BottomView>
                {props.bottom || <></>}
            </BottomView>
        </div>
    )

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <RootView className="drawer-container" style={{borderRight: props.noline? 'none' : ''}}>
                    <CssBaseline />
                    <MenuButtomView
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </MenuButtomView>
                    <DrawerBackGround color={props.background || "#fff"}>
                        <DrawerView>
                            <Hidden smUp implementation="css">
                                <DrawerPaperView
                                    variant="temporary"
                                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                    open={open}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                >
                                    {drawer}
                                </DrawerPaperView>
                            </Hidden>
                            <Hidden smDown implementation="css">
                                <DrawerPaperView
                                    variant="permanent"
                                    open
                                >
                                    {drawer}
                                </DrawerPaperView>
                            </Hidden>
                        </DrawerView>
                    </DrawerBackGround>
                </RootView>
            </ThemeProvider>
        </StylesProvider>
    )
}