import {Actions, Avatar, Email, ManCard, Name, PeopleContainer, Position} from "./PeopleList.style";
import React, {useEffect, useState} from "react";
import {Person} from "../types";
import {Message, People} from "@mui/icons-material";
import {ButtonLink} from "../../Courses/presentation/CoursesPage.style";
import {media} from "../../../components/frame/media";
import { participantName } from "./display";

interface Props {
    list: Person[];
}

export const PeopleList = ({list}: Props) => {
    const [width, setWidth] = useState(0);

    const resize = () =>
        setWidth(Math.max(document.body.scrollWidth, document.body.clientWidth, document.body.offsetWidth));

    useEffect(() => {
        resize();
        window.addEventListener('resize', resize);

        return () => document.removeEventListener('resize', resize);
    }, [list.length]);

    const cols =
        width >= media.px.laptop? 3
            :
        width >= media.px.phone? 2
            :
            1;

    const emptySpace = (list.length % cols) === 0? 0 : cols - (list.length % cols)

    const filledSpace = new Array(emptySpace).fill(null);

    return <PeopleContainer>
        {list.map(person =>
            <ManCard key={person._id}>
                <Avatar><img src={'/api/avatar/' + (person.user_id? person.user_id : person._id)} alt="avatar" /></Avatar>
                <Name>{participantName(person)}</Name>
                <Position>{person.position}{person.company && ","} {person.company}</Position>
                {person.email ?
                    <Email href={'mailto:' + person.email}>{person.email}</Email>
                    :
                    <Actions>
                        <ButtonLink to={'/participants/' + person._id}><People color='primary' /></ButtonLink>
                        <ButtonLink to={'/public/message/' + person._id}><Message color='primary' /></ButtonLink>
                    </Actions>
                }
            </ManCard>
        )}
        {filledSpace.map((_, id) => <ManCard key={id}/>)}
    </PeopleContainer>
}
