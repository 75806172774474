import React from 'react';
import { Person } from "../types";
import {LoadingIndicator} from "../../../toolympus/components/primitives/LoadingIndicator";
import {PeopleList} from "./PeopleList";
import {FilterHeadStyle} from "./FilterHead.style";
import {Tab, Tabs, TextField} from "@mui/material";
import {Search} from "@mui/icons-material";
import { participantName } from './display';
import { useTextFilter } from '../../../toolympus/components/schemed/Filtering/useTextFilter';
import { ContactOrganizerBlock } from './OrganizersPage';
import { ParticipantsData } from '../usePaticipants';


interface Props {
    data: ParticipantsData;
}

export const ParticipantsList = ({data: { data, isLoading, filter, setFilter }}: Props) => {
    const search = useTextFilter<Person>(participantName);

    return <>
        <FilterHeadStyle>
            <Tabs
                classes={{indicator: "indicator"}}
                value={filter}
                onChange={(_, filter) => setFilter(filter)}
            >
                <Tab label='all participants' value='all'/>
                <Tab label='tutors' value='tutors'/>
                <Tab label='students' value='students'/>
                <Tab label='Organisers' value='organizers'/>
            </Tabs>
            <TextField
                label='Search'
                value={search.filter}
                onChange={(e) => search.setFilter(e.target.value)}
                InputProps={{
                    endAdornment: <Search />
                }}
                variant="outlined"
                size='small'
            />
        </FilterHeadStyle>
        {filter === "organizers" && <ContactOrganizerBlock style={{ margin: "0 0 15px" }}>Should you have any questions, please do not hesitate to contact us</ContactOrganizerBlock>}
        {isLoading
          ? <LoadingIndicator />
          : <PeopleList list={search.filterData(data)} />}
    </>
}
