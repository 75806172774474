import React from "react";
import styled from '@emotion/styled'
import { useFetch } from "../../api/useFetch";
import { DefaultUser } from "../../toolympus/userContext/UserContext";
import { BorderBox } from "../common/BorderBox";
import {media} from "../frame";
import { participantName } from "../Participants/presentation/display";
import { Link } from "react-router-dom";

export const Title = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 123.5%;
    letter-spacing: 0.25px;

    color:${(props) => props.theme.palette.text.primary};
    
    ${media.tablet} {
        font-size: 24px;
    }
`;

export const HeaderBox = styled(BorderBox)`
    width: 100%;
    height: 45px;

    padding-bottom: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;
HeaderBox.defaultProps = { bottom: true };

export const HeaderLinks = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-right: -5px;

    & .links {
        justify-content: flex-end;
        align-items: center;
    }
`;

export const IconImg = styled.div`
    width: 17px;
    height: 17px;
    margin: 0 8px;
    cursor: pointer;

    & img {
        width: auto;
        height: 100%;
    }

    &:hover {
        opacity: 0.8;
    }
`;

export const IconImgLink = styled(Link)`
    width: 17px;
    height: 17px;
    margin: 0 8px;
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    & img {
        width: auto;
        height: 100%;
    }

    & .MuiBadge-badge {
      border-radius: 4px;
    }

    &:hover {
        opacity: 0.8;
    }
`;

interface IconProps{ 
    img: string;
    href: string;
    alt: string;
    onClick: () => void;
}

export const Icon = ({img, alt, onClick}: IconProps) => { 
    return <IconImg onClick={onClick}><img src={img} alt={alt} /></IconImg>
}

const AvatarContainer = styled.div`
    height: 35px;
    padding-right: 20px;

    display: flex;
    align-items: center;

    & img {
        height: 35px;
        width: 35px;
        object-fit: cover;
        object-position: center top;
        border-radius: 2px;
        filter: grayscale(1);
    }

    ${media.tablet} {
        padding-right: 5px;
    }

`;

const AvatarName = styled.div`
    margin-left: 10px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 70.1%;
    
    text-transform: capitalize;
`;

interface AvatarProps {
    user: DefaultUser;
}

type UserData = {_id: string, lastname: string, firstname: string} | null

let localSavedUser: UserData = null;

export const Avatar = ({user}: AvatarProps) => {
    const {data} = useFetch<UserData>(localSavedUser, {url: '/participant/' + user?._id}, !localSavedUser)

    if (!!data) {
        localSavedUser = data
    }

    return <AvatarContainer>
        <img src={'/api/avatar/' + user?._id} alt="avatar"/>
        {data && <AvatarName>{participantName(data)}</AvatarName>}
    </AvatarContainer>
}
