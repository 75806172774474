import styled from '@emotion/styled'
import { FilterHeadStyle } from "../../Participants/presentation/FilterHead.style";
import { media } from "../../frame";
import { Tooltip } from "@mui/material";
import React from "react";


export const PageContainer = styled.div<{openChat: boolean}>`
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: max-content 1fr;
    column-gap: 50px;
    
    transition: 150ms;
    
    ${media.laptop} {
        column-gap: 30px;
        grid-template-columns: 250px 1fr;
    }
    
    ${media.tablet} {
        column-gap: 0;
        grid-template-columns: ${(props: {openChat: boolean}) => props.openChat? '0 1fr' : '1fr 0'};
        grid-template-rows: ${(props: {openChat: boolean}) => props.openChat? '1fr' : 'max-content 1fr'};
    }
`;
PageContainer.defaultProps = {className: 'PageContainer'};

export const Header = styled(FilterHeadStyle)`
    grid-column: 1/3;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    ${media.tablet} {
        display: ${(props: {openChat: boolean}) => props.openChat? `none` : 'flex'};
        flex-flow: column nowrap;
        align-items: stretch;
    }

    & .MuiButton-root {
        width: max-content;
        min-width: 110px;

        ${media.tablet} {
            margin-top: 10px;
            width: 100%;
        }
    }

    & .MuiTabs-root {
        margin-bottom: 0;

        & .indicator {
          bottom: 6px;
        }
    }

    & .MuiTab-root {
      min-width: 160px;
    }

    & .MuiTab-root:not(.Mui-selected) {
      color: ${props => props.theme.palette.primary.main};
      opacity: 0.75;
    }
`;

export const MessagesList = styled.div`
    width: 100%;
    overflow: hidden;
    
    display: grid;
    grid-template-rows: max-content 1fr;
    
    ${media.tablet} {
        max-width: 100%;
    }
    
    & .input {
        padding-top: 7px;
        padding-bottom: 40px;
    }
`;

export const AddresseeList = styled.div`
    height: 100%;
    overflow-y: auto;
`;

export const Addressee = styled.div<{active: boolean}>`
    width: 100%;
    padding: 20px 10px;
    
    border-bottom: ${(props) => props.active? '2px solid ' + props.theme.palette.primary.main : '1px solid rgba(155, 155, 155, 0.4)'};
    background-color: ${(props) => props.active ? 'rgba(161, 182, 204, 0.3)' : 'white'};
    transition: 150ms;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }
    
    & .AvatarImg {
        margin-right: 10px;
    }   
`;

export const AvatarImg = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center top;
    border-radius: 4px;
    
    ${media.phone} {
        width: 34px;
        height: 34px;
    }
`;
AvatarImg.defaultProps = {className: 'AvatarImg'};

export const AddresseeName = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    
    display: flex;
    justify-content: space-between;
`;

export const MessageText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    
    color: #202020;
    white-space: pre-line;

`;

export const MessageReplyTo = styled.a`
    display: block;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    
    color: #202020;
    background: #6184AD20;
    border-radius: 8px;
    padding: 4px 8px;
    margin: 4px 0;
    font-style: italic;

    cursor: pointer;
    user-select: none;

    & .reply-icon {
      width: 16px;
      height: 13px;
      display: inline-block;
      margin-right: 4px;
    }
`;
MessageReplyTo.defaultProps = { role: "button" };

export const Secondary = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #A5A5A5;
`;
Secondary.defaultProps = {className: 'Secondary'};

export const ParticipantsCountWrapper = styled.div`
  cursor: pointer;
`;
ParticipantsCountWrapper.defaultProps = { role: "button" };

export const LinkBack = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    display: none;

    cursor: pointer;
    color: ${props => props.theme.palette.text.secondary};
    
    margin-bottom: 10px;

    & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.text.secondary};
        margin-right: 10px;
    }

    ${media.tablet} {
        display: flex;
        align-items: center;
    }
`;
LinkBack.defaultProps = {className: 'LinkBack'};

export const ChatContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    display: grid;
    grid-template-rows: max-content 1fr max-content ;
    
    ${media.tablet} {

        grid-template-rows: max-content max-content 1fr max-content ;
    }
`;
ChatContainer.defaultProps = {className: 'ChatContainer'};

export const ChatTitle = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: 20px;
    align-items: center;
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #000000;

    padding-bottom: 5px;
    
    ${media.phone} {
        & .Secondary {
            display: none;
        }
    }
`;
ChatTitle.defaultProps = {className: 'ChatTitle'};

export const ChatList = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-right: 10px;
    position: relative;
`;
ChatList.defaultProps = {className: 'ChatList'};


export const ChatAvatar = styled.div`
    border-radius: 4px;

    
    height: 35px;
    width: 35px;

    background:  rgba(0, 0, 0, 0.1);;

    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center top;
        border-radius: 2px;
        filter: grayscale(1);
    }
`;
ChatAvatar.defaultProps = {className: 'ChatAvatar'};

export const DayContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    gap: 10px;
    align-items: center;
    padding: 30px 0 15px;
`;
DayContainer.defaultProps = {className: 'DayContainer'};

export const DaySeparate = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(155, 155, 155, 0.4);
`;
DaySeparate.defaultProps = {className: 'DaySeparate'};

export const MsgChatContainer = styled.div<{lastCurrentAtDate?: boolean}>`
    display: flex;
    flex-flow: row nowrap;
    padding: 15px 0;
    position: relative;

    & .pin-button, & .reply-button {
      display: none;
    }

    &:hover .pin-button, &:hover .reply-button {
      display: block;
    }
    
    ${(props) => 
        props.lastCurrentAtDate? '' : 'border-bottom: 1px solid rgba(0, 0, 0, 0.1);'};
`;
MsgChatContainer.defaultProps = {className: 'MsgChatContainer'};

export const PinnedMessageContainer = styled(MsgChatContainer)`
  width: 100%;
  z-index: 200;
  padding-right: 10px;
  padding-left: 10px;

  box-shadow: 0 5px 5px -2px #22222240;
  margin-top: 10px;
  border-top: 1px solid ${props => props.theme.palette.primary.main};
  border-bottom: 1px solid ${props => props.theme.palette.primary.main};
  border-right: 1px solid ${props => props.theme.palette.primary.main};
  border-left: 15px solid ${props => props.theme.palette.primary.main};
`;

const MessageHoverButton = styled.button`
  border: none;
  background: transparent;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const PinButton = styled(MessageHoverButton)`
`;
PinButton.defaultProps = { className: "pin-button" };

export const UnpinButton = styled(PinButton)`
  display: block;
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
`;
UnpinButton.defaultProps = { className: "unpin-button" };


export const ReplyButton = styled(MessageHoverButton)`
`;
ReplyButton.defaultProps = { className: "reply-button" };

export const MessageTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;  
`;

export const MsgData = styled.div`
    width: 100%;
`;
MsgData.defaultProps = {className: 'MsgData'};


export const ChatAddresseeAvatar = styled(ChatAvatar)`
    margin-right: 15px;
`;

export const ChatAddresseeName = styled(AddresseeName)`
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: 1;
`;

export const BottomChatContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    padding-bottom: 0;
`;
BottomChatContainer.defaultProps = {className: 'BottomChatContainer'};

export const MsgInputContainer = styled.form`
    background: rgba(161, 182, 204, 0.1);

    width: 100%;
    
    border: 1px solid ${props => props.theme.palette.primary.main};
    border-radius: 4px;

    padding: 10px;

    display: grid;
    align-items: flex-end;
    grid-template-columns: 1fr repeat(2, max-content);
    gap: 20px;
    
    ${media.phone} {
        height: 30px;
        padding: 3px 10px;
        grid-template-columns: 1fr;
    }
`;
MsgInputContainer.defaultProps = {className: 'MsgInputContainer'};

export const MsgInput = styled.input`
    background: transparent;
    outline: none;
    border: none;
    
    padding: 0;

    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    
    width: 100%;
    height: 100%;

    display: none;

    ${media.phone} {
        display: block;
    }
`;
MsgInput.defaultProps = {className: 'MsgInput'};

export const MsgTextarea = styled.textarea`
    background: transparent;
    outline: none;
    border: none;
    resize: none;
    
    padding: 0;

    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    
    width: 100%;
    height: 100%;

    display: block;

    ${media.phone} {
        display: none;
    }
`;
MsgTextarea.defaultProps = {className: 'MsgTextarea'};


export const ActionIcon = styled.div<{mobile?: boolean}>`
    display: ${(props) => props.mobile? 'none' : 'block'};    
    height: 20px;
    cursor: pointer;
    
    & img {
        width: 20px;
        height: 20px;
    }
    
    ${media.phone} {
        display: ${(props) => props.mobile? 'block' : 'none'};
        margin-right: 10px;
    }
`;
ActionIcon.defaultProps = {className: 'ActionIcon'};

export const SendButton = styled.button`
    width: 50px;
    height: 50px;
    
    background: ${props => props.theme.palette.primary.main};
    border-radius: 4px;
    border: none;
    
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
        
    &:focus {
        outline: none;
    }

    & img {
        width: 23px;
        height: auto;
    }

    ${media.phone} {
        display: none;
    }
`;
SendButton.defaultProps = {className: 'SendButton'};

export const FileStatus = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 5px 5px;
    gap: 0.5rem;
`;
FileStatus.defaultProps = {className: 'FileStatus'};

export const ReplyingTo = styled(FileStatus)`
  & .reply-icon {
    width: 16px;
    height: 13px;
  }

  & .original {
    font-style: italic;
  }
`;
ReplyingTo.defaultProps = { className: "ReplyingTo" };

export const RoleIcon = ({title, src, alt}: {title: string, src: string, alt: string}) => {
    return <Tooltip title={title}>
        <RoleIconImg src={src} alt={alt}  />
    </Tooltip>
}

export const RoleIconImg = styled.img`
    height: 16px;
    width: 16px;
    object-fit: cover;
    object-position: center top;
    border-radius: 2px;

    display: inline;

    margin-left: 6px;

    cursor: pointer;
`;
RoleIconImg.defaultProps = {className: 'RoleIcon'};
