import React, { ReactNode } from 'react';
import styled from '@emotion/styled'

export interface AppContentProps {
    children: ReactNode;
    background?: string;
    padding?: boolean;
}

const MainView = styled.main<{background: string, padding?: boolean}>`
    flex-grow: 1;
    width: 100%;
    min-height: 100vh;
    background-color: ${({background}) => background};
    overflow-x: hidden;
    padding: ${({padding}) => padding? '1rem' : "0"};
`


export const AppContent: React.FC<AppContentProps> = (props: AppContentProps) => {
    return (
        <MainView background={props.background || "#fff"} padding={props.padding} >
            {props.children}
        </MainView>
    )
}