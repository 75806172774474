import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../api/useFetch";
import { apiFetch, FetchTypes } from "../../toolympus/api/core";
import { ChatData, ChatItem, Filter, Message } from "./types";

let intervalSelect: number | any;

export const useMessages = () => {

    const {data, loading, setData, reload} = useFetch<ChatItem[]>([], {url: '/chat'});
    const createFetch = useFetch<ChatData | null>(null, {url: '/chat/group', method: FetchTypes.POST});

    const [filter, setFilter] = useState<Filter>('all');

    useEffect(() => {
        intervalSelect = setInterval(
            () => {
                apiFetch<ChatItem[]>('/api/chat').then(setData);
            }, 5000
        );
        return () => clearInterval(intervalSelect);
    });

    const chat = useChat();

    useEffect(() => {
        if (createFetch.data) {
            chat.chat.setChat(createFetch.data);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createFetch.data]);

    return {
        filter,
        setFilter,
        loading: loading || createFetch.loading,
        data: filter === 'all'? data : data.filter(({kind}) => kind === filter),
        ...chat,
        reload,
        create: (title: string) => 
            createFetch
                .request({body: {title}})
                .then(reload)
                .then(() => setFilter('group'))
    }
}

let intervalChat: number | any;

const useChat = () => {
    const { id: userId = null } = useParams<{id?: string}>();
    const [addLoading, setAddLoading] = useState(false);
    
    const [idselectedChat, setIdSelectedChat] = useState<null | string>(null);
    
    const [replyingToMessage, setReplyingToMessage] = useState<Message | null>(null);

    const url = '/chat' + 
            (!!idselectedChat?
                ('/' + idselectedChat)
                :
                userId === 'org'? 
                    '/org' 
                    : 
                    ('/private/' + userId));

    const chat = useFetch<ChatData | null>(null, {url}, !!idselectedChat || !!userId);

    const sendFetch = useFetch<ChatData | null>(null, {url, method: FetchTypes.POST}, false);

    useEffect(() => {
        if (sendFetch.data) {
            setIdSelectedChat(sendFetch.data._id);
        }
    }, [sendFetch.data]);

    useEffect(() => {
        if (chat.data && !idselectedChat) {
            setIdSelectedChat(chat.data._id);
        }
        if(replyingToMessage && chat.data?.messages && !chat.data.messages.find(m => m._id === replyingToMessage._id)) {
          setReplyingToMessage(null);
        }
    }, [chat.data, idselectedChat, replyingToMessage]);

    useEffect(() => {
        if (chat.error && chat.error.response.status === 404) {
            chat.setData({title: 'New chat', _id: '', kind: 'private', messages: [], participants: [], chat_owner_id: "none"});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chat.error]);

    useEffect(() => {
        if (!!idselectedChat || (!!userId && !chat.error)) {
            intervalChat = setInterval(() => apiFetch<ChatData | null>('/api' + url).then(chat.setData), 5000);
        } else {
            clearInterval(intervalChat);
        }
        
        return () => clearInterval(intervalChat);
    });

    const startReplying = (m: Message) => {
      setReplyingToMessage(m);
    }
    const cancelReplying = () => {
      setReplyingToMessage(null);
    }

    return {
        setIdSelectedChat,
        idselectedChat,
        chat: {
            data: chat.data,
            setChat: chat.setData,
            loading: chat.loading || sendFetch.loading,
            send: (text: string) => {
                const msg: Partial<Message> = { text };
                if(replyingToMessage) {
                  msg.reply_to_id = replyingToMessage._id;
                  setReplyingToMessage(null);
                }
                sendFetch
                    .request({ body: msg })
                    .then(chat.reload);
            },
            add: (_id: string) => {
                setAddLoading(true);
                apiFetch<ChatData | null>(`/api/chat/${chat.data?._id}/participant/${_id}`, FetchTypes.PUT)
                    .then(() => apiFetch<ChatData | null>('/api' + url).then(chat.setData))
                    .finally(() => setAddLoading(false));
            },
            remove: (_id: string) => {
                setAddLoading(true);
                apiFetch<ChatData | null>(`/api/chat/${chat.data?._id}/participant/${_id}`, FetchTypes.DELETE)
                    .then(() => apiFetch<ChatData | null>('/api' + url).then(chat.setData))
                    .finally(() => setAddLoading(false));
            },
            pin: (messageId: string | null) => {
              if(messageId) {
                apiFetch<ChatData | null>(`/api/chat/${chat.data?._id}/pin/${messageId}`, "post")
                  .then(() => apiFetch<ChatData | null>('/api' + url).then(chat.setData))
              } else {
                apiFetch<ChatData | null>(`/api/chat/${chat.data?._id}/pin`, "delete")
                  .then(() => apiFetch<ChatData | null>('/api' + url).then(chat.setData))
              }
            },
            reply: {
              replyingToMessage,
              startReplying,
              cancelReplying,
            },
            addLoading,
            leave: () => {
                clearInterval(intervalChat);
                setIdSelectedChat(null);
                chat.setData(null);
                return apiFetch<void>(`/api/chat/${chat.data?._id}/participant`, FetchTypes.DELETE)
            }
        }
    }
}
