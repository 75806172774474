import styled from '@emotion/styled'
import {media} from "../frame";
import { Link } from 'react-router-dom';

export const PageContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    
    & .MuiButtonBase-root {
        max-width: 330px;
    }
`;
PageContainer.defaultProps = {className: 'PageContainer'};

export const CourseTitle = styled(Link)`
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.24px;

    max-width: 800px;
    margin: 50px 0 25px;
    
    text-decoration: none;
    color: inherit;
    border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
    
    ${media.phone} {
        font-size: 18px;
    }
`;
CourseTitle.defaultProps = {className: 'Title'};


export const Separator = styled.div`
    border: 1px solid rgba(155, 155, 155, 0.4);

    max-width: 850px;
    width: 100%;
    margin: 40px 0;

    ${media.phone} {
        margin: 20px 0;
    }
`;
Separator.defaultProps = {className: 'Separator'};


export const Time = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.24px;

    color: rgba(0, 0, 0, 0.6);
    
    display: flex;
    align-items: center;

    margin-bottom: 30px;
    
    ${media.laptop} {
        margin-bottom: 50px;
    }
    
    ${media.phone} {
        margin-bottom: 30px;
        font-size: 14px;
    }
    
    & .MuiSvgIcon-root {
        margin-right: 15px;
    }
`;
Time.defaultProps = {className: 'Time'};


export const Hint = styled.p<{ offset?: number }>`
    font-style: italic;
    text-align: center;

    &:first-of-type {
        margin-top: ${props => (props.offset || 0) * 3}em;
    }

    & a {
        text-decoration: none;
        color: ${props => props.theme.palette.primary.main};
    }
`;

export const ZoomWindow = styled.div<{open: boolean}>`
    position: relative;
    
    width: 100%;
    margin: 10px 0;

    transition: 300ms;

    max-width: ${({open}) => open? '800px' : '330px'};

    width: 100%;
    height: ${({open}) => open? '480px' : '35px'};

    ${media.tablet} {

    }

    & .buttons {
        position: absolute;
        left: auto;
        right: auto;
        transition: 300ms;
        opacity: ${({open}) => open? '0' : '1'};
        
        max-width: 100%;

        width: 100%;
        height: 100%;

        display: flex;
        flex-flow: column;
        align-items: center;
        gap: 0.25rem;
        
    }
`;
ZoomWindow.defaultProps = {className: 'ZoomWindow'};


export const LoadingPanel = styled.div<{open: boolean}>`
    position: absolute;
    left: auto;
    right: auto;
    z-index: ${({open}) => open? '0' : '-1'};

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;

    transition: 300ms;
    opacity: ${({open}) => open? '1' : '0'};
`;

export const LoadingMessage = styled.div`
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.24px;

    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 15px; 
`;
