import React, { useMemo } from 'react';
import { FormControlProps } from '../schemed/FormControlProps';
import { useApiConfiguration } from '../ApiConfguration';
import { createSelectSchema, FieldSchema } from '../../hooks/useSchema';
import { useLoadedData } from '../../hooks/useLoadedData';
import { EmailItem } from './types';
import { IconButton } from '@mui/material';
import { CallMadeOutlined } from '@mui/icons-material';
import { FormControl } from '../schemed';

export const useEmailTemplates = (apiPath?: string) => {
  const { emailTemplatesApiPath } = useApiConfiguration();
  const data = useLoadedData<EmailItem[]>(apiPath || emailTemplatesApiPath || "", [], !!apiPath || !!emailTemplatesApiPath);

  const selectorSchema: FieldSchema = useMemo(() => {
    return createSelectSchema(
      data.data.map(template => ({ value: template.code, label: template.title })),
      {
        label_id: "emails.fields.code",
      }
    );
  }, [data.data]);

  return {
    ...data,
    selectorSchema,
    isAvailable: !!apiPath || !!emailTemplatesApiPath,
  }
}


interface Props extends FormControlProps {
  renderPlaceholderWhenUnavailable?: boolean;
  apiPath?: string;
}

export const EmailTemplateSelector = (props: Props) => {
  const templates = useEmailTemplates(props.apiPath);
  
  if(!templates.isAvailable) {
    return props.renderPlaceholderWhenUnavailable ? <div /> : null;
  }

  const schema = { ...props.schema, ...templates.selectorSchema };
  const selectedTemplateSlug = (props.row || {})[props.field];

  return (
    <FormControl
      {...props}
      schema={schema}
      extraProps={{
        ...(props.extraProps || {}),
        controlProps: {
          ...(props.extraProps?.controlProps || {}),
          startAdornment: (
            <IconButton
              size="small"
              href={selectedTemplateSlug ? `/system/emails?code=${selectedTemplateSlug}` : `/system/emails`}
              target="_blank"
              rel="noreferrer noopener">
                <CallMadeOutlined />
            </IconButton>)
        }
      }}
      />
  );
}
