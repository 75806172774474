import { Autocomplete as MuiAutocomplete } from '@mui/material';
import React, { ReactNode } from 'react'
import { TextField } from './TextField';


interface Props {
  label: ReactNode;
  value: string;
  onChange: (v: string) => void;
  suggestions: string[];
}


export const Autocomplete = (props: Props) => {
  return (
    <MuiAutocomplete 
      freeSolo
      clearIcon={null}
      options={props.suggestions}
      value={props.value}
      onChange={(_,v) => props.onChange(v || "")}
      onInputChange={(_,v) => props.onChange(v || "")}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={props.label}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}
