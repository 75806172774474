import React from 'react';
import { useParams } from 'react-router-dom';
import { useSchedules } from '../CourseManagement/useSchedules';
import { AboutCoursePage } from './presentation/AboutCoursePage';
import { useAboutCourse } from './useAboutCourse';

export const AboutCourseContainer = () => {
    const { id } = useParams<{id: string}>();

    return <AboutCoursePage data={useAboutCourse(id)} schedules={useSchedules(id)} />;
}

