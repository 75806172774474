import styled from '@emotion/styled'
import { BorderBox } from '../BorderBox';
import { onlyTable } from './util';
import {media} from "../../frame";

export const FooterMenu = styled.div`
    width: 100%;
    height: 100%;

    margin: 0 auto;

    display: flex;
    flex-flow: column nowrap;

    ${media.tablet} {
        display: none;
    }
`;

export const MenuFooterGap = styled.div`
  margin: 10px 0;
`;

export const FooterBox = styled(BorderBox)<{hiddenPhone?: boolean}>`
    height: 125px;
    margin: 20px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    & a {
        height: 100%;
    }

    & img {
        width: auto;
        height: auto;

        max-width: 100%;
        max-height: 100%;
    }

    ${media.tablet} {
        margin: 0;
    }

    ${media.phone} {
        margin: 5px 0;
        display: ${props => props.hiddenPhone? 'none': 'flex'}
    }
`;

FooterBox.defaultProps = { all: true };

export const FooterBottomContent = styled.div`

    height: 30px;
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 187.6%;

    ${onlyTable} {
        height: 20px;
    }

    ${media.phone} {
        height: 15px;
    }
`;

export const FooterContent = styled.div`
    display: none;

    ${media.tablet} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    ${media.phone} {
        grid-template-columns: 1fr;
        gap: 0;
    }
`;
