import { FetchTypes } from "../../../toolympus/api/core";
import { useFetch } from "../../../api/useFetch";
import { AvatarApi } from "./types";

export const useAvatar = (userId: string, apiPrefix: string = '/avatar/'): AvatarApi => {

    const uploadAvatar = useFetch(null, {url: apiPrefix + userId, method: FetchTypes.PUT}, false);
    const deleteAvatar = useFetch(null, {url: apiPrefix + userId, method: FetchTypes.DELETE}, false);

    const upload = (file: File) => {
        uploadAvatar.file(apiPrefix + userId, file);
    }

    const deletePhoto = () => {
        deleteAvatar.request({url: apiPrefix + userId, method: FetchTypes.DELETE});
    }

    return {
        userId,
        loading: uploadAvatar.loading || deleteAvatar.loading,
        upload,
        deletePhoto,
    };
}