import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { Course } from "./types"

export const useCourseList = () => {
  const data = useLoadedData<Course[]>("/api/course", []);
  const filter = useTextFilter<Course>(c => c.title);

  return {
    ...data,
    filter,
    data: filter.filterData(data.data),
  }
}

export type CourseListData = ReturnType<typeof useCourseList>;
