import React from "react";
import {ParticipantPage} from "./presentation/ParticipantPage";
import {useParams} from "react-router-dom";
import {useFetch} from "../../api/useFetch";
import {Person} from "./types";

export const OneParticipantContainer = () => {
    const {id} = useParams<{id: string}>()
    return <ParticipantPage userId={id} data={useFetch<Person | null>(null, {url: '/participant/' + id})} />
}
