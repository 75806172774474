import React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { AssignmentReturnedOutlined, GetAppOutlined } from '@mui/icons-material';
import { ApplicationsListForm } from '../Form/ApplicationsListForm';
import { ApplicationsList, useApplicationsListAdmin } from '../Form/useApplicationsList';
import { LoadingIndicator } from '../../../toolympus/components/primitives';

export const AdminApplicationsRegistrationsListPanel = (props: Pick<ApplicationsList, "createApprovedUsers" | "exportApplications" | "downloadAllDocuments">) => {
    return <>
        <IconButton size="small" onClick={() => props.exportApplications()}>
            <Tooltip title="Export applications to Excel">
                <GetAppOutlined />
            </Tooltip>
        </IconButton>
        <IconButton size="small" onClick={() => props.downloadAllDocuments.run()}>
            <Tooltip title="Export all CVs and letters">
                {props.downloadAllDocuments.isRunning ? <LoadingIndicator sizeVariant="s" /> : <AssignmentReturnedOutlined />}
            </Tooltip>
        </IconButton>

        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => props.createApprovedUsers && props.createApprovedUsers.run()}
          startIcon={props.createApprovedUsers?.isRunning ? <LoadingIndicator sizeVariant="s" color="inherit" /> : undefined}
          style={{
            minWidth: "160px",
            boxSizing: "border-box",
          }}>
            Create Approved Users
        </Button>
    </>
}

export const AdminApplications = () => {
    const data = useApplicationsListAdmin();
    const applicaitonPanel = (
      <AdminApplicationsRegistrationsListPanel
        createApprovedUsers={data.createApprovedUsers}
        exportApplications={data.exportApplications}
        downloadAllDocuments={data.downloadAllDocuments}
        />);
    return <ApplicationsListForm data={data} applicaitonPanel={applicaitonPanel} type={"admin_applications"} />
}
