import styled from '@emotion/styled';
import { IconButton, Tooltip } from '@mui/material'
import { Cancel, CheckCircle, GetAppOutlined, HelpOutline } from '@mui/icons-material'
import React from 'react'
import { downloadFile } from '../../../toolympus/api/core';
import { Participants } from '../../Participants/usePaticipants';
import { ApplicationApprovalStatus, ApplicationReview } from './useApprovalReview';
import { ApplicationApproval, ApplicationsList } from '../Form/useApplicationsList';
import { useUser } from '../../../toolympus/userContext/UserContext';
import { Buttons } from '../../../toolympus/components/primitives';


type ApprovalButtonsIconsProps = {
    approvalHandler: (x: any) => void,
    approvalStatus: string,
    direction?: string,
    disable: boolean
};

interface ApplicationsTableIconExtraProps {
    slug: string;
    document_id?: string;
    downloadDocument?: (x: { document_id: string | undefined }) => void,
    id?: string | undefined,
    approval_committee?: { [x: string]: ApplicationApproval } | null,
    users?: Participants
}

export type ApprovalCommittee = {
  _id: string;
    approval_status: ApplicationApprovalStatus,
    firstname: string,
    lastname: string,
    comment?: string,
    data?: ApplicationsList,
    review?: ApplicationReview,
};

export type ApplicationReviewType = "committee" | "admin_applications";

const getApprovalButtonColor = (isCurrentStatus: string, approvalStatus: ApplicationApprovalStatus, activeColor: string) => isCurrentStatus === approvalStatus ? activeColor : "gray";

export const ApplicationApprovalIcons = ({ type, currentApprovalStatus, hasTooltip, selectedStatus }: { type: "show_only" | "select", hasTooltip?: boolean, currentApprovalStatus: string, selectedStatus: string }) => {
    return <Tooltip title={hasTooltip ? currentApprovalStatus[0].toUpperCase() + currentApprovalStatus.slice(1) : ""}>
        {
            type === "show_only" ? (
                currentApprovalStatus === "approved"
                    ? <CheckCircle style={{ color: "green" }} />
                    : currentApprovalStatus === "rejected"
                        ? <Cancel style={{ color: "red" }} />
                        : <HelpOutline style={{ color: "grey" }} />
            ) : (
                selectedStatus === "approved"
                    ? <CheckCircle style={{ color: getApprovalButtonColor(currentApprovalStatus, "approved", "green") }} />
                    : selectedStatus === "rejected"
                        ? <Cancel style={{ color: getApprovalButtonColor(currentApprovalStatus, "rejected", "red") }} />
                        : <HelpOutline color={getApprovalButtonColor(currentApprovalStatus, "review", "black") !== "gray" ? "primary" : "inherit"} />
            )
        }

    </Tooltip>
}


export const ApplicationTableIcon = ({ applicationTable }: { applicationTable: ApplicationsTableIconExtraProps }) => {
    switch (applicationTable.slug) {
      
      case "approval_committee":
        return <CommitteeApprovalListDisplay applicationTable={applicationTable} />;
      
        case "document_type":
        return (
          <GetAppOutlined
            style={{ cursor: "pointer" }}
            color="primary"
            onClick={() => applicationTable.downloadDocument && applicationTable.downloadDocument({ document_id: applicationTable.document_id })}
            />);

      case "approved":
        return <CheckCircle style={{ color: "green" }} />;
      case "rejected":
        return <Cancel style={{ color: "red" }} />;
      case "":
      case "review":
        return <HelpOutline color="primary" />;
      default:
        return <></>

    }
}

const ApprovalButtons = styled.div<{ direction: string | undefined }>`
    display: flex;
    justify-content: ${props => props.direction === "forward" ? "flex-end" : "flex-start"};
`;

export const ApprovalButtonsIcons = ({ approvalHandler, approvalStatus, disable, direction }: ApprovalButtonsIconsProps) => {
    return <ApprovalButtons direction={direction}>
        {
            [
                { status: "approved", title: "Approve" },
                { status: "rejected", title: "Reject" },
                { status: "review", title: "Keep for review" },
            ].map(approvalButton => <Tooltip title={approvalButton.title}>
                <IconButton
                  size="small"
                  onClick={() => !disable && approvalHandler({ status: approvalButton.status as ApplicationApprovalStatus })}>
                    <ApplicationApprovalIcons type='select' selectedStatus={approvalButton.status} currentApprovalStatus={approvalStatus} />
                </IconButton>
            </Tooltip>)
        }
    </ApprovalButtons>
}

export const downloadDocument = (newChanges: { exportAllDocuments?: boolean, document_id?: string }) => {
    downloadFile(`/api/application/document/${(newChanges?.exportAllDocuments ? "export" : newChanges.document_id)}`);
};

const CommitteeApprovalListDisplay = ({ applicationTable }: { applicationTable: ApplicationsTableIconExtraProps }) => {
  const { user } = useUser();

  const committeeApprovals = (applicationTable.users?.data || [])
    .map(member => {
      const approval = (applicationTable?.approval_committee || {})[member._id];

      return {
        _id: member._id,
        lastname: member.lastname,
        firstname: member.firstname,
        approval_status: approval?.is_preliminary && member._id !== user?._id ? "review" : (approval?.approval_status || "review"),
      };
    });

  return <Buttons>
    {committeeApprovals.map(a => <ApplicationApprovalIcons key={a.lastname} hasTooltip type='show_only' currentApprovalStatus={a.approval_status} selectedStatus={a.approval_status} />)}
  </Buttons>;
}
