import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { LoginForm, ResetPassword, RestorePassword, SignupForm } from "./components/Login/LoginForm";
import { ApplicationForm } from "./components/Application/ApplicationForm";
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import AppRouter from './AppRouter';
import { theme } from './theme';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import "./index.css"
import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { CustomMessages } from './CustomMessages';
import { FormatsProvider } from './toolympus/components/schemed';
import moment from 'moment';
import { PublicDocumentationPage } from './PublicDocumentationPage';

const App: React.FC = () => {
  moment.locale("en");
  return (
    <MuiThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <StyledThemeProvider theme={theme}>
            <LocalizationProvider locale="en" defaultLocale="en" messages={CustomMessages}>
              <FormatsProvider formats={{ date: "DD.MM.YYYY"}}>
                <Router>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                        <ErrorBoundary>
                          <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                                <Switch>
                                    <Route path='/application' component={ApplicationForm} />
                                    <Route path='/signup' component={SignupForm} />
                                    <Route path='/login' component={LoginForm} />
                                    <Route path='/reset-password' component={ResetPassword} />
                                    <Route path='/restore-access' component={RestorePassword} />
                                    <Route path='/doc/:slug' component={PublicDocumentationPage} />
                                    <Route path='/' component={AppRouter} />
                                </Switch>
                          </DatesLocalizationProvider>
                        </ErrorBoundary>
                    </SnackbarProvider>
                </Router>
                </FormatsProvider>
              </LocalizationProvider>
            </StyledThemeProvider>
        </StylesProvider>
    </MuiThemeProvider>
  );
}

export default App;
