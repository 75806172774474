import styled from '@emotion/styled'
import {BorderBox} from "../../common/BorderBox";
import {media} from "../../frame";

export const PeopleContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    
    background: rgba(155, 155, 155, 0.4);
    
    ${media.laptop} {
        grid-template-columns: 1fr 1fr;
    }

    ${media.phone} {
        grid-template-columns: 1fr;
    }
`;

export const ManCard = styled.div`
    width: 100%;
    height: 100%;
    padding: 30px;
    
    background-color: white;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-flow: column nowrap;
`;

export const Avatar = styled(BorderBox)`
    width: 160px;
    height: 160px;
    margin-bottom: 30px;
    overflow: hidden;
    background: rgba(155, 155, 155, 0.4);
    
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
        filter: grayscale(1);
    }
`;
Avatar.defaultProps = { className: 'Avatar', all: true };

export const Name = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.24px;
`;

export const Position = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.24px;

    color: #A0A0A0;
`;

export const Email = styled.a`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.24px;

    color: ${({theme: {palette: {primary: {main}}}}) => main};
`;

export const Actions = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    & > * {
      width: 35px;
    }
    gap: 15px;
    margin-top: 20px;
`;

