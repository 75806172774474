import { useCallback, useRef, useState } from "react";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

export const useEditorFocusSave = (editor: ReactEditor) => {
  const [focused, setFocused] = useState(false);
  const savedSelection = useRef(editor.selection);
  const onFocus = useCallback(() => {
    setFocused(true);
    if (!editor.selection && savedSelection.current) {
      Transforms.select(
        editor,
        savedSelection.current || Editor.end(editor, [])
      );
    }
  }, [editor]);


  const onBlur = useCallback(() => {
    setFocused(false);
    savedSelection.current = editor.selection;
  }, [editor]);

  return { onFocus, onBlur, focused };
}
