import { useFetch } from "../../api/useFetch";
import { Schedule } from "../CourseManagement/types";
import moment from "moment";
import { useEffect, useState } from "react";
import { toLocal } from "../common/timezone";

const getSchedule = (data: Schedule[]) => data
        .map(({start_datetime, end_datetime, ...data}) => {
            return {
                start_datetime: toLocal(start_datetime),
                end_datetime: toLocal(end_datetime),
                ...data
            };
        })
        .sort((a, b) => {
            return moment(a.end_datetime).isBefore(b.end_datetime)? -1 : 1;
        })
        .filter(({end_datetime}) => {
            const thisTime = moment(end_datetime);
            return moment().isBefore(thisTime)
        })
        [0] || null;

export const useLecture = () => {
    const {data, loading} = useFetch<Schedule[]>([], {url: '/schedule'});
    const [timer, setTimer] = useState<number | null | any>(null);
    const [schedule, setSchedule] = useState<Schedule | undefined>();

    useEffect(() => {
        setSchedule(getSchedule(data))
    }, [data]);

    useEffect(() => {
        if (schedule) {
            const value = getSchedule(data);
            const time = new Date(value.end_datetime).valueOf() - Date.now();

            setTimer(setTimeout(() => {
                setSchedule(getSchedule(data));
                console.log(getSchedule(data));
                
            }, time));
        }

        return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedule?._id || null]);

    return {
        schedule,
        loading,
    }
}
